import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { lighten } from 'polished'
import { useColorTheme } from '../../hooks/useColorTheme'

const indeterminate = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`

const LoaderContainer = styled.div`
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  background-color: ${props => props.$isLoading ? props.$lightenColor : '#FFFFFF'};
  margin-top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  opacity: ${props => props.$isHidden ? 0 : 1};

  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);
    height: 10px;
    z-index: 2;
  }
`

const LoaderBar = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => props.$isLoading ? props.$color : '#FFFFFF'};
  border-radius: 2px;
  ${props =>
    !props.$isLoading &&
    css`
      animation: none;
    `}
  will-change: left, right;
  ${props =>
    props.$isLoading &&
    css`
      animation: ${indeterminate} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    `}
`

const IndeterminateBefore = styled.div`
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  ${props =>
    props.$isLoading &&
    css`
      animation: ${indeterminate} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    `}
`

const IndeterminateAfter = styled.div`
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  will-change: left, right;
  ${props =>
    props.$isLoading &&
    css`
      animation-delay: 1.15s;
    `}
`

export default function Loader(props) {
  const { isLoading, isHidden, style } = props
  const { secondaryColor } = useColorTheme()
  const lightenColor = secondaryColor !== '#26a69a' ? lighten(0.3, secondaryColor) : '#acece6'

  return (
    <LoaderContainer style={style} $isLoading={isLoading} $isHidden={isHidden} $lightenColor={lightenColor}>
      <LoaderBar $isLoading={isLoading} $color={secondaryColor} $lightenColor={lightenColor}>
        <IndeterminateBefore $isLoading={isLoading} />
        <IndeterminateAfter $isLoading={isLoading} />
      </LoaderBar>
    </LoaderContainer >
  )
}
