import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

const CheckboxWrapper = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;
  vertical-align: middle;
  ${({ $label }) =>
    $label &&
    css`
    width: 100%;
  `}
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`

const getBorderColor = ({ $isChecked, $backgroundColor, disabled }) => {
  if (disabled) return 'var(--text-color-disabled)'
  return $isChecked ? ($backgroundColor || 'var(--input-focused-color)') : 'var(--input-element-color)'
}

const getBackgroundColor = ({ $isChecked, $backgroundColor, disabled }) => {
  const theme = document.documentElement.getAttribute('data-theme') === 'light' ? 'light' : 'dark'

  if (disabled) {
    return theme === 'dark' ? '#39495e' : '#f5f5f5'
  }

  return $isChecked ? ($backgroundColor || 'var(--input-focused-color)') : 'transparent'
}

const CheckboxBox = styled.div`
  width: ${({ $size }) => ($size || '1.25em')};
  height: ${({ $size }) => ($size || '1.25em')};
  border: 2px solid ${getBorderColor};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;
  transition: background 0.15s, border-color 0.15s;
  background-color: ${getBackgroundColor};
  &:after {
    content: ${({ $isChecked }) => ($isChecked ? "'\\2714'" : "''")};
    color: ${({ disabled }) => (disabled ? '#e0e0e0' : '#FFFFFF')};
    transition: transform 0.15s ease-in;
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`

const CheckboxLabel = styled.span`
  color: ${({ disabled }) => (disabled ? 'var(--text-color-disabled)' : 'var(--input-label-color)')};
  font-size: 16px;
  line-height: 24px;
  text-align: initial;
  white-space: ${({ $noWrap }) => ($noWrap ? 'nowrap' : 'normal')};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export default function Checkbox(props) {
  const { label, id, name, checked, noWrap = true, size, color, onChange, disabled } = props

  const handleChange = useCallback((event) => {
    if (typeof onChange === 'function') {
      onChange(event)
    }
  }, [onChange])

  return (
    <CheckboxWrapper htmlFor={id} disabled={disabled} $label={label}>
      <CheckboxInput
        type='checkbox'
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <CheckboxBox
        $size={size}
        $isChecked={checked}
        $backgroundColor={color}
        disabled={disabled}
      />
      {label &&
        <CheckboxLabel
          disabled={disabled}
          $noWrap={noWrap}
        >
          {label}
        </CheckboxLabel>
      }
    </CheckboxWrapper>
  )
}