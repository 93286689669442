import React, { useState } from 'react'
import Button from '../../Buttons/Button'
import ListFilter from '../ListFilter'
import FilterModal from '../../Modals/FilterModal'
import FilterListIcon from '../../Icons/FilterListIcon'
import useTranslate from '../../../hooks/useTranslate'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const Status = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: radial-gradient(circle at 3px 3px, var(--red-lighten-color), var(--red-color));
  position: absolute;
  top: 7px;
  right: 2px;
  transform: translateY(-50%);
`

export default function FilterButton(props) {
  const { filterQuery, setFilterQuery, pageName } = props
  const [filterModal, setFilterModal] = useState(false)
  const translate = useTranslate()
  const [filterModalContent, setFilterModalContent] = useState(null)
  const isFilterEmpty = Object.values(filterQuery).every(value => !value)

  const closeFilterModal = () => {
    setFilterModal(false)
    setFilterModalContent(null)
  }

  const filterComponent = (
    <ListFilter
      filterQuery={filterQuery}
      setFilterQuery={setFilterQuery}
      pageName={pageName}
      setFilterModal={setFilterModal}
      setFilterModalContent={setFilterModalContent}
    />
  )

  return (
    <Wrapper>
      <Button
        style={{ padding: '0px 13px 0px 13px' }}
        onClick={(e) => {
          e.preventDefault()
          setFilterModalContent(filterComponent)
          if (filterModal) {
            closeFilterModal()
          } else {
            setFilterModal(true)
          }
        }}
        title={translate('filter')}
      >
        <FilterListIcon />
      </Button>
      <FilterModal modal={filterModal} setModal={setFilterModal}>
        {filterModalContent}
      </FilterModal>
      {!isFilterEmpty && <Status title={translate('filterInUse')}/>}
    </Wrapper>
  )
}