import React, { createContext, useContext, useState, useEffect, useMemo } from 'react'

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
  const browserLanguage = navigator.language || navigator.userLanguage
  const defaultLanguage = browserLanguage.startsWith('ru') ? 'ru' : 'en'
  const initialLanguage = localStorage.getItem('language') || defaultLanguage
  const [language, setLanguage] = useState(initialLanguage)

  useEffect(() => {
    localStorage.setItem('language', language)
  }, [language])

  const value = useMemo(() => ({ language, setLanguage }), [language, setLanguage])

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}
