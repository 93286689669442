import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import TextInput from '../../../../components/Inputs/TextInput'
import Select from '../../../../components/Inputs/Select'
import DateInput from '../../../../components/Inputs/DateInput'
import Button from '../../../../components/Buttons/Button'
import FilterReset from '../../../../components/Icons/FilterReset'
import useTranslate from '../../../../hooks/useTranslate'

const FormWrapper = styled.div`
  margin: 23px 10px 0px 10px;
  box-sizing: border-box;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
  justify-content: space-between;

  @media (max-width: 920px) {
    flex-direction: column;
    margin: 30px 0px 10px 0px;
  }
`

const SearchWrapper = styled.div`
  width: ${props => props.$isMobile ? '100%' : '300px'};
`

const RowWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const DateWrapper = styled.div`
  width: ${props => props.width}px;
`

export default function ListFilter(props) {
  const { filterQuery, setFilterQuery, pageName } = props
  const dispatch = useDispatch()
  const translate = useTranslate()
  const [updatedFilterQuery, setUpdatedFilterQuery] = useState(filterQuery)

  const filterQueryHandler = useCallback(
    (e) => {
      setFilterQuery((prevFilterQuery) => {
        const newFilterQuery = {
          ...prevFilterQuery,
          [e.target.name]: e.target.value,
        }
        dispatch({ type: 'SET_FILTER_QUERY', page: pageName, payload: newFilterQuery })
        setUpdatedFilterQuery(newFilterQuery)

        return newFilterQuery
      })
    },
    [setFilterQuery, dispatch, pageName]
  )

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const statusSelectOptions = [
    {
      value: '',
      label: '-',
      defaultValue: true,
    },
    { value: 'active', label: 'Активный' },
    { value: 'markedForDeletion', label: 'На удаление' },
  ]

  const directionSelectOptions = [
    {
      value: '',
      label: '-',
      defaultValue: true,
    },
    { value: 'incoming', label: 'Приход' },
    { value: 'outgoing', label: 'Расход' },
  ]

  return (
    <FormWrapper>
      <Form>
        <RowWrapper>
          {/* ------- SEARCH ------- */}
          <SearchWrapper $isMobile={windowWidth <= 700}>
            <TextInput
              label='Поиск...'
              id='search'
              name='search'
              value={updatedFilterQuery.search}
              borderOption={3}
              debounceDelay={500}
              forceDarkMode={true}
              isClearable={true}
              disabled={false}
              onChange={(e) => {
                filterQueryHandler(e)
              }}
            />
          </SearchWrapper>
        </RowWrapper>
        {/* ------- STATUS ------- */}
        <RowWrapper>
          < Select
            label={'Статус'}
            id='status'
            name='status'
            value={updatedFilterQuery.status}
            options={statusSelectOptions}
            forceDarkMode={true}
            searchable={false}
            disabled={false}
            onChange={(e) => {
              filterQueryHandler(e)
            }}
          />
        </RowWrapper>
        {/* ------- DIRECTION ------- */}
        <RowWrapper>
          < Select
            label={'Приход/Расход'}
            id='direction'
            name='direction'
            value={updatedFilterQuery.direction}
            options={directionSelectOptions}
            forceDarkMode={true}
            searchable={false}
            disabled={false}
            onChange={(e) => {
              filterQueryHandler(e)
            }}
          />
        </RowWrapper>
        <RowWrapper>
          {/* ------- START DATE ------- */}
          <DateWrapper width={150}>
            <DateInput
              label='От:'
              name='startDate'
              id='startDate'
              forceDarkMode={true}
              disabled={false}
              debounceDelay={1000}
              value={updatedFilterQuery.startDate}
              onChange={(e) => {
                filterQueryHandler(e)
              }}
            />
          </DateWrapper>
          {/* ------- END DATE ------- */}
          <DateWrapper width={150}>
            <DateInput
              label='До:'
              name='endDate'
              id='endDate'
              forceDarkMode={true}
              disabled={false}
              debounceDelay={1000}
              value={updatedFilterQuery.endDate}
              onChange={(e) => {
                filterQueryHandler(e)
              }}
            />
          </DateWrapper>
        </RowWrapper>
        {/* ------- CLEAR BUTTON ------- */}
        <RowWrapper>
          <Button
            title={translate('resetFilter')}
            style={{ width: windowWidth <= 920 ? '100%' : '44px', backgroundColor: 'var(--alert-button-bg)', padding: '8px' }}
            disabled={Object.values(updatedFilterQuery).every(value => value === '')}
            onClick={(e) => {
              e.preventDefault()
              const reset = {
                search: '',
                status: '',
                direction: '',
                startDate: '',
                endDate: '',
              }
              setFilterQuery((prevFilterQuery) => ({
                ...prevFilterQuery,
                ...reset
              }))
              setUpdatedFilterQuery((prevFilterQuery) => ({
                ...prevFilterQuery,
                ...reset
              }))
              dispatch({ type: 'SET_FILTER_QUERY', page: pageName, payload: reset })
            }}
          >
            <FilterReset size={22} color={Object.values(updatedFilterQuery).every(value => value === '') ? 'var(--text-color-disabled)' : '#fff'} />
          </Button>
        </RowWrapper>
      </Form>
    </FormWrapper>
  )
}
