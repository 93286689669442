import React, { useContext, useState } from 'react'
import PostService from '../../API/PostService'
import { useMessage } from '../../hooks/useMessage'
import { useNavigate } from 'react-router-dom'
import Button from '../Buttons/Button'
import styled from 'styled-components'
import ModalContext from '../../context/ModalContext'
import { useLanguage } from '../../context/LanguageContext'
import Library from '../../Library'
import useTranslate from '../../hooks/useTranslate'

const Wrapper = styled.div`
  width: 300px;
`

const LabelWrapper = styled.div`
  font-size: 20px;
  color: gray;
`
const Label = styled.span`
  font-size: 20px;
  color: var(--main-color);
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`

export default function ConfirmationWindow({ form, label, path, request, navigateBack = true }) {
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const navigate = useNavigate()
  const { language } = useLanguage()
  const translate = useTranslate()
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

  return (
    <Wrapper>
      <LabelWrapper>{translate('delete')} <Label>{Library.truncate(label, 13)}</Label>?</LabelWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: 'var(--cancel-button-text)',
            backgroundColor: 'var(--cancel-button-bg)',
            border: 'var(--cancel-button-border)',
            minWidth: '140px',
          }}
          onClick={() => closeModal()}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{
            minWidth: '140px',
            backgroundColor: 'var(--alert-button-bg)',
          }}
          disabled={deleteButtonDisabled}
          onClick={async function (e) {
            e.preventDefault()
            setDeleteButtonDisabled(true)
            try {
              const response = await PostService.delete(path, request, { id: form.id }, language)
              const data = response.data
              const status = response.status

              // Handling based on status
              if ([201, 404, 500].includes(status)) {
                message(data.message, Library.getStatusType(status))
              } else {
                message(data.message, 'error')
              }

              // Re-enable the delete button for non-successful cases
              if (status !== 201) {
                setTimeout(() => {
                  setDeleteButtonDisabled(false)
                }, 500)
              } else {
                closeModal()
                if (navigateBack) {
                  navigate(-1)
                }
              }
            } catch (error) {
              const serverMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message

              message(serverMessage, 'error')
              setTimeout(() => {
                setDeleteButtonDisabled(false)
              }, 500)
            }

          }}
        >
          {translate('delete')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}