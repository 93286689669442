import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useTranslate from '../../hooks/useTranslate'
import { useSelector } from 'react-redux'
import ModalContext from '../../context/ModalContext'
import DeleteConfirmation from './components/DeleteConfirmation'
import MarkConfirmation from './components/MarkConfirmation'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.$top - 70}px;
  left: ${props => props.$left}px;
  z-index: 1000000;
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--line-color);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 2px 8px 2px 8px;
  width: auto;
  min-width: 150px;
  max-width: 300px;

  opacity: ${props => props.$isVisible ? 1 : 0};
  transform: translateY(${props => props.$isVisible ? '0px' : '-10px'});
  transition: opacity 0.1s ease, transform 0.1s ease;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
`

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 100%;
  padding: 5px 8px;
  max-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: var(--background-tertiary-color);
  }
`

const Divider = styled.div`
  height: 1px;
  overflow: hidden;
  background-color: var(--line-color);
`

export default function ContextMenu({ listWrapperRef, position, item, onClose, label, path, request }) {
  const { companyId } = useSelector(state => state.data)
  const { showModal, closeModal, setModalContent } = useContext(ModalContext)
  const translate = useTranslate()
  const navigate = useNavigate()
  const menuRef = useRef(null)

  // const handleCopyText = () => {
  //   if (!navigator.clipboard) {
  //     console.error('Clipboard functionality is not available')
  //     return
  //   }

  //   const text = window.getSelection().toString()
  //   if (text) {
  //     navigator.clipboard.writeText(text).then(() => {
  //       console.log('Text copied to clipboard')
  //       onClose() // Optionally close the menu after copying
  //     }).catch(err => {
  //       console.error('Failed to copy text: ', err)
  //     })
  //   } else {
  //     console.log('No text selected to copy')
  //   }
  // }

  const calculateMenuPosition = useCallback(() => {
    if (menuRef.current && listWrapperRef.current) {
      const menuBounds = menuRef.current.getBoundingClientRect()
      const wrapperBounds = listWrapperRef.current.getBoundingClientRect()

      let newX = Math.min(position.x, window.innerWidth - menuBounds.width)
      let newY = Math.min(position.y, window.innerHeight - menuBounds.height)

      // Adjust to keep within listWrapperRef
      if (newX < wrapperBounds.left) {
        newX = wrapperBounds.left
      } else if (newX + menuBounds.width > wrapperBounds.right) {
        newX = wrapperBounds.right - menuBounds.width
      }

      if (newY < wrapperBounds.top) {
        newY = wrapperBounds.top
      } else if (newY + menuBounds.height > wrapperBounds.bottom) {
        newY = wrapperBounds.bottom - menuBounds.height
      }

      return { x: newX, y: newY }
    }
    return position
  }, [listWrapperRef, position])

  const [menuPosition, setMenuPosition] = useState(position)
  useEffect(() => {
    if (menuRef.current) {
      const result = calculateMenuPosition()
      setMenuPosition(result)
    }
  }, [position, calculateMenuPosition])

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [onClose, menuRef])

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Set the menu to be visible after mounting
    setIsVisible(true)
  }, [])

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (!isVisible) {
        // Perform any action after the menu disappears
      }
    }

    const menuElement = menuRef.current
    menuElement.addEventListener('transitionend', handleTransitionEnd)

    return () => {
      menuElement.removeEventListener('transitionend', handleTransitionEnd)
    }
  }, [isVisible])

  return (
    <Wrapper $top={menuPosition.y} $left={menuPosition.x} ref={menuRef} onContextMenu={(e) => { e.preventDefault() }} $isVisible={isVisible}>
      {/* ------- EDIT ------- */}
      <MenuItem
        onContextMenu={(e) => { e.preventDefault() }}
        onClick={() => {
          onClose()
          navigate(`/${request}/edit/${item.id}`)
        }}
      >
        {translate('edit')}
      </MenuItem>
      <Divider />
      {/* ------- MARK/UNMARK FOR DELETION ------- */}
      <MenuItem
        onContextMenu={(e) => { e.preventDefault() }}
        onClick={() => {
          onClose()
          setModalContent(
            <MarkConfirmation
              item={item}
              label={label}
              path={path}
              request={`${companyId}/${request}`}
              closeModal={closeModal}
            />
          )
          showModal()
        }}
      >
        {item.status === 'active' ? translate('markForDelition') : translate('unmarkForDelition')}
      </MenuItem>
      <Divider />
      {/* ------- DELETE ------- */}
      <MenuItem
        onContextMenu={(e) => { e.preventDefault() }}
        onClick={() => {
          onClose()
          setModalContent(
            <DeleteConfirmation
              item={item}
              label={label}
              path={path}
              request={`${companyId}/${request}`}
              closeModal={closeModal}
            />
          )
          showModal()
        }}
      >
        {translate('deleteEntry')}
      </MenuItem>
    </Wrapper >
  )
}