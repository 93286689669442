import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Auth } from './pages/Auth'
import { Error } from './pages/Error'
import { Home } from './pages/Home'
//==============USERS==============//
import { Users } from './pages/Users/Users'
//==============ROUTE REGISTRY==============//
import { Main as RouteRegistry } from './pages/RouteRegistry/Main'
import { Form as RouteRegistryForm } from './pages/RouteRegistry/components/Form'
//==============EMPLOYEES==============//
import { Main as Employees } from './pages/Employees/Main'
import { Form as EmployeesForm } from './pages/Employees/components/Form'
//==============CUSTOMERS==============//
import { Main as Customers } from './pages/Customers/Main'
import { Form as CustomersForm } from './pages/Customers/components/Form'
//==============CARS==============//
import { Main as Cars } from './pages/Cars/Main'
import { Form as CarsForm } from './pages/Cars/components/Form'
//==============HIRED CARS==============//
import { Main as HiredCars } from './pages/HiredCars/Main'
import { Form as HiredCarsForm } from './pages/HiredCars/components/Form'
//==============HIRED DRIVERS==============//
import { Main as HiredDrivers } from './pages/HiredDrivers/Main'
import { Form as HiredDriversForm } from './pages/HiredDrivers/components/Form'
//==============CARRIERS==============//
import { Main as Carriers } from './pages/Carriers/Main'
import { Form as CarriersForm } from './pages/Carriers/components/Form'
//==============SUPPLIERS==============//
import { Main as Suppliers } from './pages/Suppliers/Main'
import { Form as SuppliersForm } from './pages/Suppliers/components/Form'
//==============EXPENSES==============//
import { Main as Expenses } from './pages/Expenses/Main'
import { Form as ExpensesForm } from './pages/Expenses/components/Form'
//==============REFUELING==============//
import { Main as Refueling } from './pages/Refueling/Main'
import { Form as RefuelingForm } from './pages/Refueling/components/Form'
//==============SALARY==============//
import { Main as Salary } from './pages/Salary/Main'
//==============PROFIT==============//
import { Main as Profit } from './pages/Profit/Main'
//==============INVENTORY==============//
import { Main as Inventory } from './pages/Inventory/Main'
import { Form as InventoryForm } from './pages/Inventory/components/Form'
//==============STOCK==============//
import { Main as Stock } from './pages/Stock/Main'
import { Form as StockForm } from './pages/Stock/components/Form'
//==============MAINTENANCE==============//
import { Main as Maintenance } from './pages/Maintenance/Main'
import { Form as MaintenanceForm } from './pages/Maintenance/components/Form'
import { useAuth } from './hooks/useAuth'

export const useRoutes = () => {
  const { companyId } = useSelector(state => state.data)
  const { isAuthenticated, isAdmin, roles } = useAuth()

  function roleCheck(item) {
    return roles.some(role => role.value === 'admin')
      ? true
      : roles.some(role => (role.value === item && (role.companyId ? role.companyId === companyId : false)))
  }

  if (isAuthenticated) {
    return (
      <Routes>
        <Route path='/' element={<Home />} />
        {roleCheck('routes') && (
          <>
            <Route path='/routes' element={<RouteRegistry />} />
            <Route path='/routes/new' element={<RouteRegistryForm />} />
            <Route path='/routes/edit/:id' element={<RouteRegistryForm />} />
          </>
        )}
        {roleCheck('salary') && <Route path='/salary' element={<Salary />} />}
        {roleCheck('profit') && <Route path='/profit' element={<Profit />} />}
        {roleCheck('employees') && (
          <>
            <Route path='/employees' element={<Employees />} />
            <Route path='/employees/new' element={<EmployeesForm />} />
            <Route path='/employees/edit/:id' element={<EmployeesForm />} />
          </>
        )}
        {roleCheck('customers') && (
          <>
            <Route path='/customers' element={<Customers />} />
            <Route path='/customers/new' element={<CustomersForm />} />
            <Route path='/customers/edit/:id' element={<CustomersForm />} />
          </>
        )}
        {roleCheck('cars') && (
          <>
            <Route path='/cars' element={<Cars />} />
            <Route path='/cars/new' element={<CarsForm />} />
            <Route path='/cars/edit/:id' element={<CarsForm />} />
          </>
        )}
        {roleCheck('hired-cars') && (
          <>
            <Route path='/hired-cars' element={<HiredCars />} />
            <Route path='/hired-cars/new' element={<HiredCarsForm />} />
            <Route path='/hired-cars/edit/:id' element={<HiredCarsForm />} />
          </>
        )}
        {roleCheck('hired-drivers') && (
          <>
            <Route path='/hired-drivers' element={<HiredDrivers />} />
            <Route path='/hired-drivers/new' element={<HiredDriversForm />} />
            <Route path='/hired-drivers/edit/:id' element={<HiredDriversForm />} />
          </>
        )}
        {roleCheck('carriers') && (
          <>
            <Route path='/carriers' element={<Carriers />} />
            <Route path='/carriers/new' element={<CarriersForm />} />
            <Route path='/carriers/edit/:id' element={<CarriersForm />} />
          </>
        )}
        {roleCheck('suppliers') && (
          <>
            <Route path='/suppliers' element={<Suppliers />} />
            <Route path='/suppliers/new' element={<SuppliersForm />} />
            <Route path='/suppliers/edit/:id' element={<SuppliersForm />} />
          </>
        )}
        {roleCheck('expenses') && (
          <>
            <Route path='/expenses' element={<Expenses />} />
            <Route path='/expenses/new' element={<ExpensesForm />} />
            <Route path='/expenses/edit/:id' element={<ExpensesForm />} />
          </>
        )}
        {roleCheck('refueling') && (
          <>
            <Route path='/refueling' element={<Refueling />} />
            <Route path='/refueling/new' element={<RefuelingForm />} />
            <Route path='/refueling/edit/:id' element={<RefuelingForm />} />
          </>
        )}
        {roleCheck('inventory') && (
          <>
            <Route path='/inventory' element={<Inventory />} />
            <Route path='/inventory/new' element={<InventoryForm />} />
            <Route path='/inventory/edit/:id' element={<InventoryForm />} />
          </>
        )}
        {roleCheck('stock') && (
          <>
            <Route path='/stock' element={<Stock />} />
            <Route path='/stock/new' element={<StockForm />} />
            <Route path='/stock/edit/:id' element={<StockForm />} />
          </>
        )}
        {roleCheck('maintenance') && (
          <>
            <Route path='/maintenance' element={<Maintenance />} />
            <Route path='/maintenance/new' element={<MaintenanceForm />} />
            <Route path='/maintenance/edit/:id' element={<MaintenanceForm />} />
          </>
        )}
        {isAdmin && (
          <Route path='/users' element={<Users />} />
        )}
        <Route path='*' element={<Error />} />
        {<Route path='/error' element={<Error />} />}
      </Routes>
    )
  }
  return (
    <Routes>
      <Route path='*' element={<Auth />} />
    </Routes>
  )
}
