import React, { useRef, useEffect } from 'react'
import Chart from 'chart.js/auto'
import { useTheme } from '../../context/ThemeContext'

const BarChart = (props) => {
  const { data, xKey, yKey } = props
  const chartRef = useRef()
  const minCanvasWidth = 600
  const { theme } = useTheme()

  useEffect(() => {
    const chartData = {
      labels: data.map((item) => item[xKey]),
      datasets: [
        {
          label: yKey,
          data: data.map((item) => item[yKey]),
          backgroundColor: theme === 'dark' ? '#7177F8' : '#648CA5',
        },
      ],
    }

    const chartConfig = {
      type: 'bar',
      data: chartData,
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            barPercentage: 0.5,
            categoryPercentage: 0.5
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      },
    }

    const chartInstance = new Chart(chartRef.current, chartConfig)

    return () => {
      chartInstance.destroy()
    }
  }, [data, xKey, yKey, theme])

  return (
    <canvas ref={chartRef} style={{ minWidth: `${minCanvasWidth}px`, height: '100%', paddingBottom: '10px' }}></canvas>
  )
}

export default BarChart
