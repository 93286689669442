const getSoundFilesForType = (type, count) => {
  const files = []
  for (let i = 1; i <= count; i++) {
    files.push(`${type}-${i.toString().padStart(2, '0')}.mp3`)
  }
  return files
}

const soundFiles = {
  login: getSoundFilesForType('login', 5),
  logout: getSoundFilesForType('logout', 12),
  tab: getSoundFilesForType('tab', 17),
  button: getSoundFilesForType('button', 61),
  notification: getSoundFilesForType('notification', 21)
}

export default soundFiles