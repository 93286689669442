import axios from 'axios'

export default class PostService {

  //------------POST------------//
  static async post(path, request, data, language) {
    try {
      const response = await axios.post(`/api/${path}/${request}`, data, {
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      })
      return response
    } catch (error) {
      if (error.response) {
        return error.response
      } else if (error.request) {
        throw new Error(`Could not connect to server: ${error.message}`)
      } else {
        throw new Error(`Unexpected error: ${error.message}`)
      }
    }
  }

  //------------PUT------------//
  static async put(path, request, data, language) {
    try {
      const response = await axios.put(`/api/${path}/${request}`, data, {
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      })
      return response
    } catch (error) {
      if (error.response) {
        return error.response
      } else if (error.request) {
        throw new Error(`Could not connect to server: ${error.message}`)
      } else {
        throw new Error(`Unexpected error: ${error.message}`)
      }
    }
  }

  //------------PATCH------------//
  static async patch(path, request, data, language) {
    try {
      const response = await axios.patch(`/api/${path}/${request}`, data, {
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      })
      return response
    } catch (error) {
      if (error.response) {
        return error.response
      } else if (error.request) {
        throw new Error(`Could not connect to server: ${error.message}`)
      } else {
        throw new Error(`Unexpected error: ${error.message}`)
      }
    }
  }

  //------------GET------------//
  static async get(path, request, params = {}, language) {
    const url = `/api/${path}/${request}`
    const headers = {
      'accept-language': language || 'ru'
    }
    try {
      const response = await axios.get(url, {
        params,
        headers,
        withCredentials: true
      })
      return response
    } catch (error) {
      console.error(`Error fetching ${url}`, error)
      throw error
    }
  }

  //------------DELETE------------//
  static async delete(path, request, params = {}, language) {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')
    const url = `/api/${path}/${request}${queryString ? `?${queryString}` : ''}`

    const config = {
      headers: {
        'accept-language': language || 'ru'
      },
      withCredentials: true
    }

    try {
      const response = await axios.delete(url, config)
      return response
    } catch (error) {
      console.error(`Error deleting ${url}`, error)
      throw error
    }
  }

  //------------DOWNLOAD FILE------------//
  static async downloadFile(path, request, params = {}, language) {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')

    const url = `/api/${path}/${request}${queryString ? `?${queryString}` : ''}`

    try {
      const response = await fetch(url, {
        headers: {
          'accept-language': language || 'ru'
        },
        credentials: 'include'
      })

      if (response.status === 200) {
        const blob = await response.blob()
        const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')

        link.download = params['file-name'] || 'file'

        link.href = downloadUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        const errorData = await response.json()
        console.error(`Error: ${response.status}`, errorData)
        throw new Error(errorData.message || 'Server error')
      }
    } catch (error) {
      console.error(`Error downloading from ${url}`, error)
      throw error
    }
  }

  //------------OPEN FILE------------//
  static async openFile(path, request, params = {}, language) {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')
    const url = `/api/${path}/${request}${queryString ? `?${queryString}` : ''}`

    try {
      const response = await fetch(url, {
        headers: {
          'accept-language': language || 'ru'
        },
        credentials: 'include'
      })

      if (response.status === 200) {
        const blob = await response.blob()
        const newWindow = window.open()
        newWindow.document.title = 'File'
        newWindow.document.write(`<iframe src="${URL.createObjectURL(blob)}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
      } else {
        const errorData = await response.json()
        console.error(`Error: ${response.status}`, errorData)
        throw new Error(errorData.message || 'Server error')
      }
    } catch (error) {
      console.error(`Error opening from ${url}`, error)
      throw error
    }
  }


}