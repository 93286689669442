import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import styled from 'styled-components'
import { useTheme } from '../../../context/ThemeContext'

const Wrapper = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  height: 50px;
  gap: 10px;
  align-items: center;
`

const ResultInputWrapper = styled.div`
min-width: 130px;
max-width: 130px;
`

export const RoutesSummary = (props) => {
  const { filteredRoutes, filterQuery, routesTotal, setRoutesTotal, salaryTotal, setSalaryTotal } = props
  const { theme } = useTheme()

  const [weight, setWeight] = useState(0)

  //=== SET VALUES ===//
  useEffect(() => {
    let val_1 = 0
    let val_2 = 0
    let val_3 = 0
    filteredRoutes.forEach((item) => {
      val_1 += parseFloat(item.weight || 0)
      val_2 += parseFloat(item.routeCost || 0)
      val_3 += parseFloat(item.driverSalary || 0)
    })
    setWeight(val_1)
    setRoutesTotal(val_2)
    setSalaryTotal(val_3)
  }, [filterQuery, filteredRoutes, setRoutesTotal, setSalaryTotal])

  return (
    <Wrapper>
      {/* ------- ROUTES COUNT ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'blue' : 'var(--blue-lighten-color)' }}
          label='Кол-во'
          name='routesCount'
          id='routesCount'
          value={filteredRoutes.length ? filteredRoutes.length.toLocaleString() : '0'}
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- WEIGHT ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}
          label='Вес'
          name='weight'
          id='weight'
          value={weight.toLocaleString() + ' кг.'}
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- TOTAL ROUTES COST ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
          label='Стоимость'
          name='totalRoutesCost'
          id='totalRoutesCost'
          value={routesTotal.toLocaleString() + ' р.'}
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- TOTAL SALARY ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
          label='Зарплата'
          name='totalSalary'
          id='totalSalary'
          value={salaryTotal.toLocaleString() + ' р.'}
          readOnly={true}
        />
      </ResultInputWrapper>
    </Wrapper>
  )
}