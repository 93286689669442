import React from 'react'
import styled from 'styled-components'
import soundFiles from './soundFiles'
import Checkbox from '../../../Inputs/Checkbox'
import { useAuth } from '../../../../hooks/useAuth'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 15px 5px 15px;
`

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;  
  @media (max-width: 768px) {
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
  }
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

const CheckboxWrapper = styled.div`
  min-width: 145px;
  max-width: 145px;
  @media (max-width: 768px) {
    min-width: 100px;
    max-width: 100px;
  }
`

const ElementsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: space-between
  }
`

const VolumeInput = styled.input`
  min-width: 145px;
  max-width: 145px;
  margin-top: 12.5px;

  -webkit-appearance: none;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(var(--main-color), var(--main-color));
  background-repeat: no-repeat;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--main-color);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  ::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  /* Disabled styles */
  &:disabled {
    opacity: 0.6;
    cursor: default;
    background-image: linear-gradient(gray, gray);
  }

  &:disabled::-webkit-slider-thumb {
    background: grey;
    cursor: default;
  }
`

const Select = styled.select`
  min-width: 150px;
  color: var(--text-color);
  background: var(--background-tertiary-color);
`

const PlayButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;

  &:disabled {
    cursor: not-allowed;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent ${props => props.disabled ? '#ccc' : 'var(--main-color)'};
    transition: filter 0.2s ease-in-out;
  }

  &:hover::before {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  }
`

export default function SoundTheme(props) {
  const { settings } = useAuth()
  const { form, setForm } = props

  const handleChange = (soundType, key, value) => {
    setForm(prev => ({
      ...prev,
      sound: {
        ...prev.sound,
        soundTheme: {
          ...prev.sound.soundTheme,
          [soundType]: {
            ...prev.sound.soundTheme[soundType],
            [key]: value
          }
        }
      }
    }))
  }

  const playSound = (soundType) => {
    const soundURL = form.sound.soundTheme[soundType].soundFile
    const path = `sounds/${soundURL}`
    const audio = new Audio(path)

    // Set the volume for the audio
    const volume = form.sound.soundTheme[soundType].volume
    audio.volume = volume

    audio.play()
  }

  return (
    <Wrapper>
      {/* ------- GLOBAL TOGGLE ------- */}
      <RowWrapper>
        <Checkbox
          label='Включить звук'
          name='globalToggle'
          id='globalToggle'
          color='#E57373'
          checked={form.sound.globalToggle}
          onChange={(e) => {
            const value = e.target.checked
            setForm(prev => ({
              ...prev,
              sound: {
                ...prev.sound,
                globalToggle: value,
                soundTheme: {
                  ...prev.sound.soundTheme,
                  login: {
                    ...prev.sound.soundTheme.login,
                    enabled: value
                  },
                  logout: {
                    ...prev.sound.soundTheme.logout,
                    enabled: value
                  },
                  tab: {
                    ...prev.sound.soundTheme.tab,
                    enabled: value
                  },
                  button: {
                    ...prev.sound.soundTheme.button,
                    enabled: value
                  },
                  notification: {
                    ...prev.sound.soundTheme.notification,
                    enabled: value
                  }
                }
              }
            }))
          }}
        />
      </RowWrapper>

      {Object.entries(settings.sound.soundTheme).map(([soundType]) => (
        <RowWrapper key={soundType}>
          <ElementsWrapper>
            <CheckboxWrapper>
              <Checkbox
                label={soundType}
                name={soundType}
                id={soundType}
                disabled={!form.sound.globalToggle}
                checked={form.sound.soundTheme[soundType].enabled}
                onChange={(e) => handleChange(soundType, 'enabled', e.target.checked)}
              />
            </CheckboxWrapper>
            <VolumeInput
              type='range'
              min='0'
              max='1'
              step='0.01'
              disabled={!form.sound.soundTheme[soundType].enabled || !form.sound.globalToggle}
              value={form.sound.soundTheme[soundType].volume}
              onChange={(e) => handleChange(soundType, 'volume', parseFloat(e.target.value))}
            />
          </ElementsWrapper>
          <ElementsWrapper>
            <Select
              disabled={!form.sound.soundTheme[soundType].enabled || !form.sound.globalToggle}
              value={form.sound.soundTheme[soundType].soundFile}
              onChange={(e) => handleChange(soundType, 'soundFile', e.target.value)}
            >
              {soundFiles[soundType].map(file => (
                <option key={file} value={`${soundType}/${file}`}>
                  {file}
                </option>
              ))}
            </Select>
            <PlayButton
              disabled={!form.sound.soundTheme[soundType].enabled || !form.sound.globalToggle}
              onClick={() => {
                playSound(soundType)
              }}
            />
          </ElementsWrapper>
        </RowWrapper>
      ))}
    </Wrapper>
  )
}
