import React from 'react'

export default function AccountIcon({ size = 24 }) {

  return (
    <svg viewBox='0 0 600 600' stroke='currentColor' strokeWidth='22' fill='none' width={`${size}px`} height={`${size}px`}>
      <circle cx='300' cy='300' r='265' />
      <circle cx='300' cy='230' r='115' />
      <path d='M106.81863443903,481.4 a205,205 1 0,1 386.36273112194,0' strokeLinecap='butt' />
    </svg>
  )
}