import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { authReducer } from './authReducer'
import { mainReducer } from './mainReducer'
import { pageReducer } from './pageReducer'
import { dataReducer } from './dataReducer'
import loadingReducer from './loadingReducer'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
  auth: authReducer,
  main: mainReducer,
  page: pageReducer,
  data: dataReducer,
  loading: loadingReducer,
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
