import React, { useContext } from 'react'
import styled from 'styled-components'
import ModalContext from '../../context/ModalContext'
import Button from '../Buttons/Button'
import useTranslate from '../../hooks/useTranslate'
import Library from '../../Library'

const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  max-height: 80vh;
  margin: auto;
`

const LabelWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--text-secondary-color);
`

const UsernameWrapper = styled.div`
  color: var(--main-color);
`

const MessageWrapper = styled.div`
  max-height: calc(80vh - 50px);
  min-width: 280px;
  overflow-y: auto;
  border: 1px solid var(--line-color);
  padding: 1rem;
`

const ButtonWrapper = styled.div`
  margin: 15px 0px 0px 10px;
  display: flex;
  column-gap: 10px;
  text-align: left;
  justify-content: flex-end;
`

export default function UserMessageModal({ item }) {
  const { username, message } = item
  const { closeModal } = useContext(ModalContext)
  const translate = useTranslate()

  return (
    <Wrapper>
      <LabelWrapper>
        {username ? (
          <>
            {translate('messageFrom')}:&nbsp;
            <UsernameWrapper>{Library.truncate(username, 25)}</UsernameWrapper>
          </>
        ) : (
          translate('message') + ':'
        )}
      </LabelWrapper>
      <MessageWrapper>
        {message}
      </MessageWrapper>
      {/* ------------(CLOSE BUTTON)------------ */}
      <ButtonWrapper>
        <Button
          style={{ width: '130px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          {translate('close')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
