import { useState, useEffect } from 'react'

const useWindowHeight = (ref, offset = 0) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = `${windowHeight - offset}px`
    }
  }, [windowHeight, ref, offset])

  return windowHeight
}

export default useWindowHeight
