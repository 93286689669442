import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div`
  border: ${props => props.$borderWidth || 4}px solid #f3f3f3;
  border-top: ${props => props.$borderWidth || 4}px solid #3498db;
  border-radius: 50%;
  width: ${props => props.$size || 20}px;
  height: ${props => props.$size || 20}px;
  animation: ${rotate} ${props => props.$speed || 2}s linear infinite;
`

export default function LoadingSpinner({ size, borderWidth, speed }) {
  return <Loader $size={size} $borderWidth={borderWidth} $speed={speed} />
}


