import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import Footer from '../../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ListScrollAllSides from '../../components/ListScrollAllSides'
import SearchInput from '../../components/Inputs/SearchInput'
import Button from '../../components/Buttons/Button'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'
import useTranslate from '../../hooks/useTranslate'

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const translate = useTranslate()
  const router = useNavigate()
  const dispatch = useDispatch()
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 70 },
    { id: 'status', name: 'Статус', type: 'status', width: 60 },
    { id: 'name', name: 'Название', width: 370 },
    { id: 'isTrailer', name: 'Прицеп', type: 'check-mark', width: 130 },
    { id: 'comment', name: 'Комментарий', width: 355 },
    { id: 'lastModified', name: 'Дата изменения', dataType: 'date', type: 'timestamp', width: 140 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const searchQueryHandler = useCallback((e) => {
    if (!filterQuery) {
      return
    }
    const newFilterQuery = {
      ...filterQuery,
      [e.target.name]: e.target.value,
    }
    setFilterQuery(newFilterQuery)
    dispatch({ type: 'SET_FILTER_QUERY', page: pageName, payload: newFilterQuery })
  }, [filterQuery, dispatch, pageName])

  const { hiredCars, loading, error } = useSelector(state => state.data)

  const filteredTableData = useMemo(() => {
    const searchQuery = filterQuery.search.toLocaleLowerCase()

    const filtered = hiredCars.filter((item) =>
      Object.values(item)
        .toString()
        .toLocaleLowerCase()
        .includes(searchQuery)
    )

    pageState.sort.key && Library.sort(filtered, pageState.sort)

    return filtered
  }, [hiredCars, filterQuery.search, pageState.sort])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [hiredCars, mainState])

  //======NEW ENTRY======//
  const newEntry = useCallback(() => {
    router(`/${pageName}/new`)
  }, [router, pageName])

  return (
    <>
      <LinearLoader
        isLoading={loading || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && !loading && !error &&
        <>
          <VirtualizedList
            ref={virtualizedListRefs}
            offsetHeight={140}
            headers={headers}
            pageName={pageName}
            items={filteredTableData}
            filterQuery={filterQuery}
            navigateTo={pageName}
            activeRowStyle='fill'
            zebraStriped={true}
            labelConfig={{ labelKey: 'name', labelFormat: 'string' }}
          />
          <Footer>
            <ListScrollAllSides virtualizedListRefs={virtualizedListRefs} />
            <Button
              onClick={newEntry}
              title={translate('newEntry')}
            >
              {translate('newEntry')}
            </Button>
            <SearchInput
              style={{ margin: '0 0 0 20px' }}
              label='Поиск'
              id='search'
              name='search'
              color='#FFF'
              borderColor='#FFF'
              focusedColor='#ffeb3b'
              placeholderColor='#FFF'
              borderOption={3}
              value={filterQuery.search}
              onChange={searchQueryHandler}
              debounce={500}
            />
          </Footer>
        </>
      }
    </>
  )
}