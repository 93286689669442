import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  background-color: var(--surface-color);
  height: 50px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 5px;
`

export default function Footer({ children }) {
  return <FooterContainer>{children}</FooterContainer>
}
