import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useMessage } from '../../../hooks/useMessage'
import Library from '../../../Library'
import Button from '../../../components/Buttons/Button'
import TextInput from '../../../components/Inputs/TextInput'
import styled from 'styled-components'
import RolesSelect from './RolesSelect'
import ModalContext from '../../../context/ModalContext'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 769px) {
    width: 550px;
    padding: 0 15px 0 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: 300px;
  }
`

const Label = styled.span`
  font-size: 20px;
  color: var(--text-tertiary-color);
  margin: 10px 0px 10px 0px;
`

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 30px;
`

const ButtonWrapper = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`

export const RegistrationForm = ({ roles }) => {
  const { language } = useLanguage()
  const translate = useTranslate()
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: '',
    username: '',
    roles: [],
  })

  const getRolesOptions = useCallback(() => {
    let options = roles.map(item => {
      return {
        value: item.value,
        label: item.label,
        companyId: item.companyId, // Include companyId if it exists
        disabled: (item.value !== 'admin' && form.roles && form.roles.length && form.roles.some(role => role.value === 'admin')) // Disable if non-admin and admin is selected
      }
    })
    return options
  }, [form.roles, roles])

  const [rolesOptions, setRolesOptions] = useState(getRolesOptions())

  const handleOptionsChange = event => {
    // Update the form state with selected roles
    setForm({ ...form, roles: event.target.value })
  }

  useEffect(() => {
    setRolesOptions(getRolesOptions())
  }, [form.roles, getRolesOptions])

  return (
    <Wrapper>
      <Label>{translate('newUser')}:</Label>
      <FormWrapper>
        {/* ------- EMAIL ------- */}
        <TextInput
          label='E-mail'
          id='email'
          name='email'
          type='email'
          autoComplete='off'
          value={form.email}
          isClearable={false}
          debounceDelay={500}
          onChange={(e) => {
            Library.changeHandler(e, form, setForm)
          }}
        />
        {/* ------- PASSWORD ------- */}
        <TextInput
          label={translate('password')}
          id='password'
          name='password'
          type='password'
          autoComplete='new-password'
          value={form.password}
          isClearable={false}
          debounceDelay={500}
          onChange={(e) => {
            Library.changeHandler(e, form, setForm)
          }}
        />
        {/* ------- USERNAME ------- */}
        <TextInput
          label={translate('username')}
          id='username'
          name='username'
          type='text'
          value={form.username}
          isClearable={false}
          debounceDelay={500}
          onChange={(e) => {
            Library.changeHandler(e, form, setForm)
          }}
        />
        {/* ------- ROLES ------- */}
        <RolesSelect
          label={translate('roles')}
          name='roles'
          id='roles'
          options={rolesOptions}
          onChange={handleOptionsChange}
        />
      </FormWrapper>
      {/* ------- SUBMIT ------- */}
      <ButtonWrapper>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            minWidth: '140px',
            maxWidth: '140px',
          }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{
            minWidth: '140px',
            maxWidth: '140px',
          }}
          disabled={buttonDisabled}
          onClick={(e) => {
            e.preventDefault()
            setButtonDisabled(true)
            Library.handler({
              e,
              path: 'auth',
              request: 'register',
              form,
              message: message,
              edit: false,
              onSuccess: () => closeModal(),
              onFail: () => {
                setTimeout(() => {
                  setButtonDisabled(false)
                }, 500)
              },
              language
            })
          }}
        >
          {translate('register')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
