import React from 'react'
import InfoMessage from '../components/InfoMessage/InfoMessage'

export const Home = () => {
  return (
    <>
      <InfoMessage />
    </>
  )
}  
