import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { useSelector } from 'react-redux'
import Library from '../../../Library'
import Textarea from '../../../components/Inputs/Textarea'
import TextInput from '../../../components/Inputs/TextInput'
import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock'
import styled from 'styled-components'
import LinearLoader from '../../../components/Loaders/LinearLoader'
import useWindowHeight from '../../../hooks/useWindowHeight'
import { useLanguage } from '../../../context/LanguageContext'

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 649px) { 
    max-width: 300px;
    margin: 35px auto 85px auto;
  }

  @media (min-width: 650px) {
    margin: 35px 15px 10px 25px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 25px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  @media (max-width: 649px) {  
    row-gap: 30px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 765px;
  flex-wrap: nowrap;
  gap: 10px;
`

const CommentWrapper = styled.div`
  width: 100%;
  @media (max-width: 649px) { 
    margin-bottom: 30px;
  }
`

export const Form = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1).split('/')[0]
  const params = useParams()
  const message = useMessage()
  const { language } = useLanguage()
  const wrapperRef = useRef(null)
  useWindowHeight(wrapperRef, 84)
  const debounceDelay = 500
  const { companyId } = useSelector(state => state.data)
  const [dataReady, setDataReady] = useState(false)
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const initialFormData = useMemo(() => ({
    id: params.id,
    name: '',
    comment: ''
  }), [params.id])
  const [form, setForm] = useState(initialFormData)

  const { hiredDrivers, loading, error } = useSelector(state => state.data)

  useEffect(() => {
    if (isDataLoaded && !loading && !error) {
      if (params.id) {
        const formData = Library.findItemById(hiredDrivers, params.id)
        if (formData) {
          setForm(formData)
        } else {
          setForm(initialFormData)
        }
      }
      setDataReady(true)
    }
  }, [initialFormData, isDataLoaded, loading, error, hiredDrivers, params.id])

  //------- CHANGE HISTORY -------//

  const keyTranslation = useMemo(() => [
    { id: 'id', name: 'id' },
    { id: 'timestamp', name: 'timestamp' },
    { id: 'status', name: 'Статус' },
    { id: 'name', name: 'Название' },
    { id: 'comment', name: 'Комментарий' },
  ], [])

  const getKeyTranslation = keyTranslation.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})

  const formatValue = (key, value) => {
    switch (key) {
      case 'status': {
        return value === 'active' ? 'активный' : 'помечен на удаление'
      }
      default: {
        return value
      }
    }
  }

  const renderChangeDetails = (change) => {
    if (!change.newData) {
      return ''
    }

    const parseJSON = (data) => {
      try {
        return JSON.parse(data.replace(/\n/g, '\\n'))
      } catch (e) {
        console.error('Error parsing JSON: ', e)
        return {}
      }
    }

    const isValueEmpty = (value) => {
      return value === '' || value === null || value === 0
    }

    const isValueChanged = (oldValue, newValue) => {
      return oldValue !== newValue
    }

    const parsedNewData = parseJSON(change.newData.replace(/\n/g, '\\n'))
    const parsedOldData = change.oldData ? parseJSON(change.oldData.replace(/\n/g, '\\n')) : {}

    return Object.keys(parsedNewData).map((key) => {
      let oldData = parsedOldData[key] ? formatValue(key, parsedOldData[key]) : ''
      let newData = parsedNewData[key] ? formatValue(key, parsedNewData[key]) : ''

      if ((isValueEmpty(oldData) && isValueEmpty(newData)) || !isValueChanged(oldData, newData)) {
        return null
      }

      return (
        <div key={key} style={{ marginBottom: '10px' }}>
          <span>{getKeyTranslation[key] || key}:</span>
          {'\u00A0'}
          <span>{oldData ? oldData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
          {'\u00A0\u2192\u00A0'}
          <span>{newData ? newData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
        </div>
      )
    }).filter(Boolean)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && dataReady &&
        <FormWrapper>
          <SectionWrapper $maxWidth={1000}>
            {/* ------- NAME ------- */}
            <RowWrapper>
              <TextInput
                label='Ф.И.О'
                name='name'
                id='name'
                value={form.name}
                isClearable={true}
                debounceDelay={debounceDelay}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            {/* ------- COMMENT ------- */}
            <CommentWrapper>
              <RowWrapper>
                <Textarea
                  label='Комментарий'
                  name='comment'
                  id='comment'
                  value={form.comment}
                  debounceDelay={debounceDelay}
                  onChange={(e) => {
                    Library.changeHandler(e, form, setForm)
                  }}
                />
              </RowWrapper>
            </CommentWrapper>
          </SectionWrapper>
          <ButtonsBlock
            handler={Library.handler}
            form={form}
            edit={params.id ? true : false}
            label={form.name}
            message={message}
            fileFolder={pageName}
            path='data'
            request={pageName}
            changeHistoryTableName={`${companyId}_change_history`}
            changeHistoryId={form.id}
            renderChangeDetails={renderChangeDetails}
          />
        </FormWrapper>
      }
    </Wrapper>
  )
}
