import React, { useContext } from 'react'
import PostService from '../../../API/PostService'
import Button from '../../../components/Buttons/Button'
import { useMessage } from '../../../hooks/useMessage'
import Library from '../../../Library'
import styled from 'styled-components'
import ModalContext from '../../../context/ModalContext'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'

const Wrapper = styled.div`
  position: relative;
  min-width: 280px;
  max-width: 360px;
`
const LabelWrapper = styled.div`
  font-size: 20px;
  color: var(--text-tertiary-color);
`
const Label = styled.span`
  color: var(--main-color);
  font-weight: bold;
`
const ButtonsWrapper = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

export default function ToggleAccess({ currUser }) {
  const { closeModal } = useContext(ModalContext)
  const { language } = useLanguage()
  const translate = useTranslate()
  const message = useMessage()

  return (
    <Wrapper>
      <LabelWrapper>{currUser.accessEnabled ? translate('disableUserAccess') : translate('enableUserAccess')} <Label>{Library.truncate(currUser.email, 25)}</Label>?</LabelWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: 'var(--cancel-button-text)',
            backgroundColor: 'var(--cancel-button-bg)',
            border: 'var(--cancel-button-border)',
            minWidth: '140px',
          }}
          onClick={() => closeModal()}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{ minWidth: '140px' }}
          onClick={async function () {
            try {
              const payload = { id: currUser.id }
              const response = await PostService.patch('auth', 'toggle-access', payload, language)
              const data = response.data

              if (response.status === 200) {
                message(data.message, 'success')
                closeModal()
              } else {
                message(data.message, 'error')
              }
            } catch (error) {
              message(error.message, 'error')
            }
          }}
        >
          {currUser.accessEnabled ? translate('disable') : translate('enable')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}