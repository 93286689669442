import { useState, useEffect, useRef } from 'react'

export default function useSingleAndDoubleClick(
  actionSimpleClick,
  actionDoubleClick,
  delay = 250
) {
  const clicksRef = useRef(0)
  const triggerEffect = useState(0)
  const singleClickRef = useRef(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clicksRef.current === 1 && singleClickRef.current) {
        actionSimpleClick()
      }
      clicksRef.current = 0
      singleClickRef.current = false
    }, delay)

    if (clicksRef.current === 2) {
      actionDoubleClick()
      singleClickRef.current = false
    }

    return () => clearTimeout(timer)
  }, [triggerEffect, actionDoubleClick, actionSimpleClick, delay])

  return (index) => {
    if (index === undefined) {
      return
    }

    const selection = window.getSelection()
    if (clicksRef.current === 0 && selection.toString().length > 1) {
      return
    }

    singleClickRef.current = true
    clicksRef.current += 1
    triggerEffect[1](prev => prev + 1)
    actionSimpleClick(index)
  }
}
