import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

const LabelAfter = styled.span`
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
  }
`

const Label = styled.label`
  position: absolute;
  top: -15px;
  line-height: 1rem;
  font-size: 0.8rem;
  width: 100%;
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  transform-origin: 0% 100%;
  transition: color 0.2s;

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  witdh: 100%;
  height: 2.9rem;
  &::after {
    content: '';
    color: var(--input-text-color);
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
    ${({ disabled }) =>
    disabled &&
    css`
        border-top-color: rgba(0,0,0,0.42);
        cursor: default;
      `}
    ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
  }
  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
    color: var(--text-color-disabled);
    border-bottom: 1px dotted var(--border-color-disabled);
  `}
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0 29px 0 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--input-text-color);

  ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
        `
      : $forceDarkMode
      && css`
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
            cursor: pointer;
          }
        `}

  ${({ $textOverflow }) =>
    $textOverflow &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: ${({ $height }) => `${$height}px`};
  overflow-y: auto;
  background: var(--background-color);
  color: var(--input-text-color);
  border: 1px solid var(--line-color);
  z-index: 9999;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  transition: height 0.3s ease-in-out;
  ${({ $position }) => `
  top: ${$position === 'up' ? '45px' : '5px'};
  transform: ${$position === 'up' ? 'translateY(-100%)' : 'scaleY(1)'};
`};
`

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  padding: 13px 0 13px 15px;
  cursor: pointer;
  &:hover:not(:disabled) {
    background-color: var(--background-tertiary-color);
  }
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--background-tertiary-color)' : 'transparent')};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: var(--background-tertiary-color);
    `}
`

const CheckboxWrapper = styled.label`
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;
  vertical-align: middle;
`

const Checkbox = styled.div`
  position: absolute;
  opacity: 0;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`

const getBorderColor = ({ $isChecked, $backgroundColor, disabled }) => {
  if (disabled) return 'var(--text-color-disabled)'
  return $isChecked ? ($backgroundColor || 'var(--input-focused-color)') : 'var(--input-element-color)'
}

const getBackgroundColor = ({ $isChecked, $backgroundColor, disabled }) => {
  const theme = document.documentElement.getAttribute('data-theme') === 'light' ? 'light' : 'dark'

  if (disabled) {
    return theme === 'dark' ? '#39495e' : '#f5f5f5'
  }

  return $isChecked ? ($backgroundColor || 'var(--input-focused-color)') : 'transparent'
}

const CheckboxBox = styled.div`
  width: ${({ size }) => (size || '1.25em')};
  height: ${({ size }) => (size || '1.25em')};
  border: 2px solid ${getBorderColor};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrink: 0;
  transition: background 0.15s, border-color 0.15s;
  background-color: ${getBackgroundColor};
  &:after {
    content: ${({ $isChecked }) => ($isChecked ? "'\\2714'" : "''")};
    color: ${({ disabled }) => (disabled ? '#e0e0e0' : '#FFFFFF')};
    transition: transform 0.15s ease-in;
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`

const CheckboxLabel = styled.span`
  color: ${({ disabled }) => (disabled ? '#bdbdbd' : 'var(--input-label-color)')};
  font-size: 16px;
  line-height: 24px;
  text-align: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export default function RolesSelect(props) {
  const { label, name, id, style, value = [], options = [], optionsHeight = 200, borderOption = 1, forceDarkMode = false, disabled, onChange, onClick, onBlur, onFocus, ...rest } = props
  const [isFocused, setIsFocused] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState([])
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)
  const [dropdownPosition, setDropdownPosition] = useState('down')

  const checkDropdownPosition = useCallback(() => {
    if (dropdownRef.current && inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect()
      const spaceToBottom = window.innerHeight - inputRect.bottom
      const spaceToTop = inputRect.top

      if (spaceToBottom < optionsHeight && spaceToTop > optionsHeight) {
        setDropdownPosition('up')
      } else {
        setDropdownPosition('down')
      }
    }
  }, [optionsHeight])

  useEffect(() => {
    setSelectedOptions(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleOptionClick = useCallback(
    (event, option) => {
      event.preventDefault()
      if (option.disabled) {
        return
      }
      if (option.value === 'admin') {
        if (selectedOptions.some((o) => o.value === option.value)) {
          setSelectedOptions(previousSelectedOptions)
        } else {
          setPreviousSelectedOptions(selectedOptions)
          setSelectedOptions(options.filter((o) => !o.disabled))
        }
      } else {
        setSelectedOptions((prevSelectedOptions) => {
          const index = prevSelectedOptions.findIndex((o) => o.value === option.value && o.companyId === option.companyId)
          if (index !== -1) {
            return prevSelectedOptions.filter((o) => o.value !== option.value || o.companyId !== option.companyId)
          } else {
            return [...prevSelectedOptions, option]
          }
        })
        if (selectedOptions.some((o) => o.value === 'admin')) {
          setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.filter((o) => o.value !== 'admin')
          )
        }
      }
    },
    [selectedOptions, options, previousSelectedOptions]
  )

  useEffect(() => {
    const fakeEvent = {
      target: {
        name,
        value: selectedOptions,
        type: 'select',
      },
    }

    if (typeof onChange === 'function') {
      onChange(fakeEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, name])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = (e) => {
    if (dropdownRef.current && dropdownRef.current.contains(e.relatedTarget)) {
      return
    }
    setIsFocused(false)
  }

  useEffect(() => {
    if (isFocused) {
      checkDropdownPosition()
    }
  }, [isFocused, checkDropdownPosition])

  return (
    <SelectWrapper>
      <LabelAfter>
        <Label
          disabled={disabled}
          htmlFor={id}
          $isFocused={isFocused}
          $hasValue={selectedOptions.length > 0}
          $forceDarkMode={forceDarkMode}
        >
          {label}
        </Label>
      </LabelAfter>
      <InputWrapper
        disabled={disabled}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
      >
        <Input
          style={style}
          ref={inputRef}
          type='text'
          name={name}
          id={id}
          autoComplete='off'
          disabled={disabled}
          readOnly={true}
          value={selectedOptions.map((option) => option.label).join(', ')}
          onChange={onChange || null}
          onClick={onClick}
          onBlur={handleBlur}
          onFocus={handleFocus}
          $forceDarkMode={forceDarkMode}
          {...rest}
        />
      </InputWrapper>
      {isFocused && (
        <DropdownContainer
          ref={dropdownRef}
          $height={optionsHeight}
          $position={dropdownPosition}
        >
          {options.map((option, i) => {
            const isSelected = selectedOptions.some((selectedOption) => selectedOption.value === option.value)
            return (
              <Option
                key={`${option.value}-${i}`}
                disabled={option.disabled}
                onMouseDown={(event) => handleOptionClick(event, option)}
                $selected={isSelected}
              >
                <CheckboxWrapper htmlFor={`${name}-option-${option.value}`} disabled={disabled}>
                  <Checkbox
                    name={`${name}-option-${option.value}`}
                    id={`${name}-option-${option.value}`}
                    disabled={disabled}
                    $checked={isSelected}
                  />
                  <CheckboxBox
                    disabled={option.disabled}
                    $isChecked={isSelected}
                    $backgroundColor={option.value === 'admin' ? 'var(--alert-button-bg)' : 'var(--main-color)'}
                  />
                  <CheckboxLabel disabled={disabled}>{option.label}</CheckboxLabel>
                </CheckboxWrapper>
              </Option>
            )
          })}
        </DropdownContainer>
      )}
    </SelectWrapper>
  )
}
