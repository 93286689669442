import { useMemo } from 'react'
import { useTheme } from '../context/ThemeContext'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const useMessage = () => {
  const { theme } = useTheme()

  const defaultSettings = useMemo(() => ({
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme,
  }), [theme])

  return (text, type = 'default', toastSettings = {}) => {
    const mergedSettings = { ...defaultSettings, ...toastSettings }
    if (text instanceof Error) {
      toast.error(text.message, mergedSettings)
    } else if (text) {
      switch (type) {
        case 'success': {
          toast.success(text, mergedSettings)
          break
        }
        case 'info': {
          toast.info(text, mergedSettings)
          break
        }
        case 'warn': {
          toast.warn(text, mergedSettings)
          break
        }
        case 'error': {
          toast.error(text, mergedSettings)
          break
        }
        default: {
          toast(text, mergedSettings)
        }
      }
    }
  }
}
