import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import styled from 'styled-components'
import { useTheme } from '../../../context/ThemeContext'

const Wrapper = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  height: 50px;
  gap: 10px;
  align-items: center;
`

const ResultInputWrapper = styled.div`
min-width: 130px;
max-width: 130px;
`

export const RefuelingSummary = (props) => {
  const { filteredRefueling, filterQuery, refuelingTotal, setFuelTotal } = props
  const { theme } = useTheme()

  const [litersOrKilograms, setLitersOrKilograms] = useState(0)

  //=== SET VALUES ===//
  useEffect(() => {
    let val_1 = 0
    let val_2 = 0
    filteredRefueling.forEach((item) => {
      val_1 += parseFloat(item.litersOrKilograms || 0)
      val_2 += parseFloat(item.amount || 0)
    })
    setLitersOrKilograms(val_1)
    setFuelTotal(val_2)
  }, [filterQuery, filteredRefueling, setFuelTotal])

  return (
    <Wrapper>
      {/* ------- ROUTES COUNT ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'blue' : 'var(--blue-lighten-color)' }}
          label='Кол-во'
          name='refuelingCount'
          id='refuelingCount'
          value={filteredRefueling.length ? filteredRefueling.length.toLocaleString() : '0'}
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- LITERS OR KILOGRAMS ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}
          label='Литры/Кг'
          name='litersOrKilograms'
          id='litersOrKilograms'
          value={litersOrKilograms.toLocaleString()}
          color='green'
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- TOTAL FUEL COST ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
          label='Стоимость'
          name='totalFuelCost'
          id='totalFuelCost'
          value={refuelingTotal.toLocaleString() + ' р.'}
          readOnly={true}
        />
      </ResultInputWrapper>
    </Wrapper>
  )
}