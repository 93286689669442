import { useCallback, useRef, useState } from 'react'

export default function useDebounce(callback, delay) {
  const timer = useRef()
  const [isDebouncing, setIsDebouncing] = useState(false)

  const debouncedCallback = useCallback(
    (...args) => {
      setIsDebouncing(true)
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        callback(...args)
        setIsDebouncing(false)
      }, delay)
      return () => clearTimeout(timer.current)
    },
    [callback, delay]
  )

  const cancel = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      setIsDebouncing(false)
    }
  }, [])

  return [debouncedCallback, isDebouncing, cancel]
}
