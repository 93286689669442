import PostService from "../../API/PostService"
import { resetDataState } from "./dataActions"
import { setDataLoaded } from "./loadingActions"

export const setId = (item) => ({
  type: 'SET_USER_ID',
  payload: item,
})

export const setEmail = (item) => ({
  type: 'SET_USER_EMAIL',
  payload: item,
})

export const setUsername = (item) => ({
  type: 'SET_USERNAME',
  payload: item,
})

export const setTelegramId = (item) => ({
  type: 'SET_TELEGRAM_ID',
  payload: item,
})

export const setTelegramNotifications = (item) => ({
  type: 'SET_TELEGRAM_NOTIFICATIONS',
  payload: item,
})

export const setRoles = (item) => ({
  type: 'SET_USER_ROLES',
  payload: item,
})

export const setTwoStepVerification = (item) => ({
  type: 'SET_TWO_STEP_VERIFICATION',
  payload: item,
})

export const setSettings = (item) => ({
  type: 'SET_USER_SETTINGS',
  payload: item,
})

export const setAccessEnabled = (item) => ({
  type: 'SET_USER_ACCESS_ENABLED',
  payload: item,
})

export const logout = () => ({
  type: 'LOGOUT',
})

export const loginUser = (id, email, username, telegramId, telegramNotifications, roles, twoStepVerification, settings, accessEnabled) => dispatch => {
  dispatch(setId(id))
  dispatch(setEmail(email))
  dispatch(setUsername(username))
  dispatch(setTelegramId(telegramId))
  dispatch(setTelegramNotifications(telegramNotifications))
  dispatch(setRoles(roles))
  dispatch(setTwoStepVerification(twoStepVerification))
  dispatch(setSettings(settings))
  dispatch(setAccessEnabled(accessEnabled))
}

export const logoutUser = (navigate, socket) => async dispatch => {
  if (socket) {
    socket.disconnect()
  }

  if (typeof navigate === 'function') {
    navigate('/')
  }

  dispatch(resetDataState())
  dispatch(setDataLoaded(false))
  dispatch({ type: 'RESET_ALL_PAGES_STATE' })
  dispatch(logout())

  try {
    await PostService.post('auth', 'logout')
  } catch (error) {
    console.error('Failed to logout user:', error)
  }
}

export const initializeUserSession = () => async dispatch => {
  try {
    const response = await PostService.get('auth', 'validate-session')

    if (response.status === 200) {
      const freshUserData = response.data.user

      dispatch(setId(freshUserData.id))
      dispatch(setEmail(freshUserData.email))
      dispatch(setUsername(freshUserData.username))
      dispatch(setTelegramId(freshUserData.telegramId))
      dispatch(setTelegramNotifications(freshUserData.telegramNotifications))
      dispatch(setRoles(freshUserData.roles))
      dispatch(setTwoStepVerification(freshUserData.twoStepVerification))
      dispatch(setSettings(freshUserData.settings))
      dispatch(setAccessEnabled(freshUserData.accessEnabled))

    } else {
      dispatch(logoutUser())
      console.error('Error initialize user session:', response.data)
    }
  } catch (error) {
    console.error('Error initialize user session:', error)
    dispatch(logoutUser())
  }
}

export const updateUser = (
  id,
  email,
  username,
  telegramId,
  telegramNotifications,
  roles,
  twoStepVerification,
  settings,
  accessEnabled,
) => async dispatch => {

  try {
    const data = {
      id,
      email,
      username,
      telegramId,
      telegramNotifications,
      roles,
      twoStepVerification,
      settings,
      accessEnabled,
    }

    const response = await PostService.patch('auth', 'update-user', data)

    if (response.data) {
      if (response.data.errors) {
        response.data.errors.forEach((err) => {
          // display error message
        })
      } else {
        dispatch(setId(id))
        dispatch(setEmail(email))
        dispatch(setUsername(username))
        dispatch(setTelegramId(telegramId))
        dispatch(setTelegramNotifications(telegramNotifications))
        dispatch(setRoles(roles))
        dispatch(setTwoStepVerification(twoStepVerification))
        dispatch(setSettings(settings))
        dispatch(setAccessEnabled(accessEnabled))

        // Optionally display success message
      }
    } else {
      // Handle no `data` case
    }
  } catch (error) {
    // Handle API call failure
    console.error(error)
  }
}

export const updateAuthDetails = (updatedData) => dispatch => {
  const {
    id,
    email,
    username,
    telegramId,
    telegramNotifications,
    roles,
    twoStepVerification,
    settings,
    accessEnabled,
  } = updatedData

  dispatch(setId(id))
  dispatch(setEmail(email))
  dispatch(setUsername(username))
  dispatch(setTelegramId(telegramId))
  dispatch(setTelegramNotifications(telegramNotifications))
  dispatch(setRoles(roles))
  dispatch(setTwoStepVerification(twoStepVerification))
  dispatch(setSettings(settings))
  dispatch(setAccessEnabled(accessEnabled))
}

