import React, { createContext, useContext, useState, useEffect } from 'react'

export const ThemeContext = createContext()

export const useTheme = () => {
  return useContext(ThemeContext)
}

export const ThemeProvider = ({ children }) => {
  // Use system theme preference if available, else default to 'light'
  const getInitialTheme = () => {
    const storedTheme = localStorage.getItem('data-theme')
    if (storedTheme) {
      return storedTheme
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    } else {
      return 'light'
    }
  }

  const [theme, setTheme] = useState(getInitialTheme)

  useEffect(() => {
    localStorage.setItem('data-theme', theme)
  }, [theme])

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
