import React from 'react'
import Button from './Buttons/Button'
import styled from 'styled-components'
import useTranslate from '../hooks/useTranslate'

const Wrapper = styled.div`
  display: flex;
  flexDirection: row;
  column-gap: 5px;
  margin-left: ${props => props.$margin}px;
`

export default function ListScrollAllSides(props) {
  const { virtualizedListRefs } = props
  const translate = useTranslate()
  const headerRef = virtualizedListRefs.headerRef
  const listRef = virtualizedListRefs.listRef

  const scrollUpDown = () => {
    if (!listRef.current || !headerRef.current) {
      console.log('Refs not available')
      return
    }

    const headerHeight = parseInt(headerRef.current.style.height) || 0
    const itemHeight = listRef.current.props.itemSize
    const itemCount = listRef.current.props.itemCount
    const currentScrollOffset = listRef.current.state.scrollOffset
    const clientHeight = listRef.current.props.height

    const lastVisibleIndex = Math.floor((currentScrollOffset + clientHeight - headerHeight) / itemHeight) - 1
    const isLastElementVisible = (lastVisibleIndex > itemCount - 1 ? itemCount - 1 : lastVisibleIndex) === itemCount - 1

    if (currentScrollOffset === 0) {
      listRef.current.scrollTo(itemCount * itemHeight)
    } else if (isLastElementVisible) {
      listRef.current.scrollTo(0)
    } else {
      listRef.current.scrollTo(itemCount * itemHeight)
    }
  }

  function scrollLeftRight() {
    if (!listRef.current) {
      return
    }
    const parentElement = listRef.current._outerRef
    const scrollWidth = parentElement.scrollWidth
    const clientWidth = parentElement.clientWidth
    const scrollLeft = parentElement.scrollLeft
    const remainingScroll = scrollWidth - clientWidth - scrollLeft

    if (remainingScroll <= 0) {
      parentElement.scrollTo({ left: 0, behavior: 'smooth' })
    } else if (scrollLeft === 0) {
      parentElement.scrollTo({ left: scrollWidth - clientWidth, behavior: 'smooth' })
    } else {
      parentElement.scrollTo({ left: 0, behavior: 'smooth' })
    }
  }

  return (
    <Wrapper $margin={5}>
      <Button
        style={{ width: '50px', lineHeight: '0px', padding: '0px' }}
        onClick={scrollUpDown}
        title={translate('moveUpDown')}
      >
        &#9650;&#9660;
      </Button>
      <Button
        style={{ width: '50px', lineHeight: '0px', padding: '0px' }}
        onClick={scrollLeftRight}
        title={translate('moveRightLeft')}
      >
        &#9664; &#9654;
      </Button>
    </Wrapper>
  )
}
