import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCarriers, fetchCars, fetchHiredCars, fetchHiredDrivers, fetchCustomers, fetchEmployees, fetchExpenses, fetchRefueling, fetchMaintenance, fetchRoutes, fetchInventory, fetchStock, fetchSuppliers, fetchUsers } from '../store/actions/dataActions'
import { setDataLoaded } from '../store/actions/loadingActions'
import { useAuth } from '../hooks/useAuth'

const DataPreloader = () => {
  const dispatch = useDispatch()
  const { roles } = useAuth()
  const { companyId } = useSelector(state => state.data)

  const roleDataMappings = useMemo(() => ({
    'admin': [fetchUsers, fetchCars, fetchHiredCars, fetchHiredDrivers, fetchCustomers, fetchCarriers, fetchEmployees, fetchSuppliers, fetchExpenses, fetchRefueling, fetchMaintenance, fetchInventory, fetchStock, fetchRoutes],
    'routes': [fetchRoutes, fetchCars, fetchHiredCars, fetchHiredDrivers, fetchEmployees, fetchCustomers, fetchCarriers],
    'expenses': [fetchCars, fetchCustomers, fetchCarriers, fetchSuppliers, fetchExpenses],
    'refueling': [fetchCars, fetchSuppliers, fetchRefueling],
    'salary': [fetchCars, fetchEmployees, fetchCustomers, fetchCarriers, fetchExpenses, fetchRoutes],
    'profit': [fetchCars, fetchEmployees, fetchCustomers, fetchRefueling, fetchExpenses, fetchRoutes],
    'employees': [fetchEmployees],
    'customers': [fetchCustomers],
    'cars': [fetchCars],
    'hired-cars': [fetchHiredCars],
    'hired-drivers': [fetchHiredDrivers],
    'carriers': [fetchCarriers],
    'suppliers': [fetchSuppliers],
    'inventory': [fetchInventory],
    'stock': [fetchCars, fetchStock],
    'maintenance': [fetchCars, fetchSuppliers, fetchMaintenance],
  }), [])

  const preloadData = useCallback(async (roles) => {
    try {

      // Determine if the user is an admin
      const isAdmin = roles.some(role => role.value === 'admin')

      let dataActions = []

      if (isAdmin) {
        // If the user is an admin, load all data
        dataActions = Object.values(roleDataMappings).flat()
      } else {
        // If not admin, load data based on user roles and companyId
        dataActions = roles.reduce((actions, roleObj) => {
          if (roleObj.companyId === companyId) {
            const roleActions = roleDataMappings[roleObj.value]
            if (roleActions) {
              actions.push(...roleActions)
            }
          }
          return actions
        }, [])
      }

      // Dispatch unique data actions
      const uniqueDataActions = Array.from(new Set(dataActions)).map(action => dispatch(action()))
      await Promise.all(uniqueDataActions)

      dispatch(setDataLoaded(true))
    } catch (error) {
      console.error('Data preloading failed:', error)
    }
  }, [dispatch, roleDataMappings, companyId])

  useEffect(() => {
    if (roles && roles.length > 0 && companyId) {
      preloadData(roles)
    }
  }, [roles, preloadData, companyId])

  return null
}

export default DataPreloader
