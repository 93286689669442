import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Button from './Buttons/Button'
import ModalContext from '../context/ModalContext'
import useDebounce from '../hooks/useDebounce'
import CircleLoader from './Loaders/CircleLoader'
import useModalContentSize from '../hooks/useModalContentSize'
import moment from 'moment'

/* global ymaps */

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
    minHeight: props.height + 'px',
    maxHeight: props.height + 'px',
  },
}))`
  position: relative;
  display: flex;
  flex-direction: column;
`

const MapWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  position: relative;
  border: 1px solid var(--line-color);
  overflow: hidden;
  flex: 1;
`

const StyledInput = styled.input`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px;
  z-index: 1;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .15), 0 2px 5px -3px rgba(0, 0, 0, .15);
  display: block;
  width: 200px;
  height: 28px;
  padding: 0px 2px 0px 5px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  line-height: 28px;
  background-clip: padding-box;
  box-sizing: border-box !important;
  border: 0;
  outline: none;
  transition: box-shadow .15s ease-out;
  
  &:focus {
    box-shadow: inset 0 0 0 2px #ffdb4d;
  }
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: flex-end;
  flex-shrink: 0;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 36px;
  max-height: 36px;
  width: 100%;
  span {
    font-size: 12px;
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ResetButtonSvg = styled.svg`
  position: absolute;
  bottom: 30px;
  right: 10px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  & path {
    fill: #646464;
  }

  &:hover path {
    fill: #333333;
  }
`

export const ResetButton = ({ onClick }) => {
  return (
    <ResetButtonSvg onClick={onClick} width="26px" height="26px" viewBox="0 0 20 20">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dribbble-Light-Preview" transform="translate(-300.000000, -7839.000000)" fill="#646464">
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path d="M255,7689 C255,7689.552 254.552,7690 254,7690 C253.448,7690 253,7689.552 253,7689 C253,7688.448 253.448,7688 254,7688 C254.552,7688 255,7688.448 255,7689 M259,7690 L259.91,7690 C259.486,7692.507 257.507,7694.486 255,7694.91 L255,7694 C255,7693.448 254.552,7693 254,7693 C253.448,7693 253,7693.448 253,7694 L253,7694.91 C250.493,7694.486 248.514,7692.507 248.09,7690 L249,7690 C249.552,7690 250,7689.552 250,7689 C250,7688.448 249.552,7688 249,7688 L248.09,7688 C248.514,7685.493 250.493,7683.514 253,7683.09 L253,7684 C253,7684.552 253.448,7685 254,7685 C254.552,7685 255,7684.552 255,7684 L255,7683.09 C257.507,7683.514 259.486,7685.493 259.91,7688 L259,7688 C258.448,7688 258,7688.448 258,7689 C258,7689.552 258.448,7690 259,7690 M263,7688 L261.931,7688 C261.479,7684.383 258.617,7681.521 255,7681.069 L255,7680 C255,7679.448 254.552,7679 254,7679 C253.448,7679 253,7679.448 253,7680 L253,7681.069 C249.383,7681.521 246.521,7684.383 246.069,7688 L245,7688 C244.448,7688 244,7688.448 244,7689 C244,7689.552 244.448,7690 245,7690 L246.069,7690 C246.521,7693.617 249.383,7696.479 253,7696.931 L253,7698 C253,7698.552 253.448,7699 254,7699 C254.552,7699 255,7698.552 255,7698 L255,7696.931 C258.617,7696.479 261.479,7693.617 261.931,7690 L263,7690 C263.552,7690 264,7689.552 264,7689 C264,7688.448 263.552,7688 263,7688" id="target-[#82]">
            </path>
          </g>
        </g>
      </g>
    </ResetButtonSvg>
  )
}

const checkUrl = (url) => {
  const pattern = new RegExp('^(http|https)://', 'i')
  if (!pattern.test(url)) {
    url = `http://${url}`
  }
  return url
}

export const AddressMap = ({ data, addressKey, latitudeKey, longitudeKey, popupInfo, clusterDisplayKey }) => {
  const mainState = useSelector(state => state.main)
  const defaultCenter = mainState.defaultMapCenter
  const defaultZoom = 10
  const { closeModal } = useContext(ModalContext)
  const mapContainer = useRef(null)
  const mapRef = useRef(null)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const [isYmapsReady, setIsYmapsReady] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [resetZoom, setResetZoom] = useState(false)
  const [debounceCallback] = useDebounce(value => {
    setSearchTerm(value)
  }, 500)

  const [minLatitude, setMinLatitude] = useState(Number.MAX_VALUE)
  const [minLongitude, setMinLongitude] = useState(Number.MAX_VALUE)
  const [maxLatitude, setMaxLatitude] = useState(Number.MIN_VALUE)
  const [maxLongitude, setMaxLongitude] = useState(Number.MIN_VALUE)

  const handleSearchChange = event => {
    setInputValue(event.target.value)
    debounceCallback(event.target.value)
  }

  const highlightSearchTerm = (text, searchTerm) => {
    const index = text.toLowerCase().indexOf(searchTerm.toLowerCase())
    if (index >= 0) {
      return (
        text.substring(0, index) +
        "<span style='background-color: #2769AA; color: white;'>" +
        text.substring(index, index + searchTerm.length) +
        "</span>" +
        text.substring(index + searchTerm.length)
      )
    }
    return text
  }

  useEffect(() => {
    setFilteredData(data.filter(item => {

      if (searchTerm === '') {
        return item[addressKey] && item[latitudeKey] && item[longitudeKey]
      }

      if (item[addressKey] && item[latitudeKey] && item[longitudeKey]) {
        const searchTermLowered = typeof searchTerm === 'string' ? searchTerm.toLowerCase() : searchTerm
        return popupInfo.some(info => {
          const itemValue = item[info.id]
          return itemValue && typeof itemValue === 'string' && itemValue.toLowerCase().includes(searchTermLowered)
        })
      }

      return false
    }))
  }, [searchTerm, data, addressKey, latitudeKey, longitudeKey, popupInfo])

  useEffect(() => {
    if (window.ymaps) {
      ymaps.ready(() => {
        setIsYmapsReady(true)
      })
    } else {
      const ymapsCheckInterval = setInterval(() => {
        if (window.ymaps) {
          clearInterval(ymapsCheckInterval)
          ymaps.ready(() => {
            setIsYmapsReady(true)
          })
        }
      }, 500)
      return () => clearInterval(ymapsCheckInterval)
    }
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.container.fitToViewport()
    }
  }, [])

  useEffect(() => {
    try {
      if (!isYmapsReady || !filteredData.length) return

      ymaps.ready(() => {

        if (mapRef.current) {
          mapRef.current.destroy()
          mapRef.current = null
        }

        const map = new ymaps.Map(mapContainer.current, {
          center: defaultCenter,
          zoom: defaultZoom,
          controls: [],
        })

        mapRef.current = map

        const zoomControl = new ymaps.control.ZoomControl({
          options: {
            size: 'small',
            position: {
              top: 10,
              right: 10,
            },
          },
        })

        map.controls.add(zoomControl)

        const clusterer = new ymaps.Clusterer({
          preset: 'islands#violetClusterIcons',
          groupByCoordinates: false,
          clusterDisableClickZoom: true,
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
          clusterOpenBalloonOnClick: true,
          clusterBalloonPanelMaxMapArea: 0,
        })

        const myGeoObjects = new ymaps.GeoObjectCollection({}, {
          preset: 'islands#redIcon',
        })

        let minLat = Number.MAX_VALUE
        let maxLat = Number.MIN_VALUE
        let minLon = Number.MAX_VALUE
        let maxLon = Number.MIN_VALUE

        filteredData.forEach((data) => {
          const latitude = data[latitudeKey]
          const longitude = data[longitudeKey]

          const placemark = new ymaps.Placemark([latitude, longitude], {
            clusterCaption: data[clusterDisplayKey],
          }, {
            preset: 'islands#redIcon',
          })

          let popupContent = ''
          for (let i = 0; i < popupInfo.length; i++) {
            const key = popupInfo[i].id
            if (data[key]) {
              let highlightedData = ''
              if (popupInfo[i].type) {
                switch (popupInfo[i].type) {
                  case 'date': {
                    highlightedData = highlightSearchTerm(moment(data[key]).format('DD.MM.yyyy'), searchTerm)
                    break
                  }
                  case 'timestamp': {
                    highlightedData = highlightSearchTerm(moment(data[key]).format('DD.MM.yyyy HH:mm:ss'), searchTerm)
                    break
                  }
                  case 'url': {
                    let url = checkUrl(data[key])
                    highlightedData = `<a href="${url}" target="_blank">${highlightSearchTerm(data[key], searchTerm)}</a>`
                    break
                  }
                  case 'number': {
                    highlightedData = data[key]
                    break
                  }
                  default: {
                    highlightedData = highlightSearchTerm(data[key], searchTerm)
                  }
                }
              } else {
                highlightedData = highlightSearchTerm(data[key], searchTerm)
              }
              popupContent += `<span style='font-weight: bold;'>${popupInfo[i].name}: </span>${highlightedData}<br>`
            }
          }

          placemark.properties.set({
            balloonContent: popupContent
          })

          placemark.events.add('click', function (e) {
            e.get('target').balloon.open()
          })

          clusterer.add(placemark)
          myGeoObjects.add(placemark)

          minLat = Math.min(minLat, latitude)
          maxLat = Math.max(maxLat, latitude)
          minLon = Math.min(minLon, longitude)
          maxLon = Math.max(maxLon, longitude)
        })

        setMinLatitude(minLat)
        setMaxLatitude(maxLat)
        setMinLongitude(minLon)
        setMaxLongitude(maxLon)

        map.geoObjects.add(clusterer)

        map.events.add('click', function () {
          map.balloon.close()
        })

        if (minLatitude !== Number.MAX_VALUE && maxLatitude !== Number.MIN_VALUE &&
          minLongitude !== Number.MAX_VALUE && maxLongitude !== Number.MIN_VALUE) {
          const bounds = [[minLatitude, minLongitude], [maxLatitude, maxLongitude]]
          map.setBounds(bounds, { checkZoomRange: true, autoFitToViewport: true })
        }
      })
    } catch (error) {
      console.error('Failed to load Yandex Maps API', error)
    }
  }, [isYmapsReady, filteredData, addressKey, latitudeKey, longitudeKey, clusterDisplayKey, defaultCenter, maxLatitude, maxLongitude, minLatitude, minLongitude, popupInfo, searchTerm])

  useEffect(() => {
    if (!isYmapsReady || !mapRef.current || !filteredData.length) return

    ymaps.ready(() => {
      const bounds = [[minLatitude, minLongitude], [maxLatitude, maxLongitude]]
      mapRef.current.setBounds(bounds, { checkZoomRange: true, autoFitToViewport: true })
    })
  }, [isYmapsReady, resetZoom, minLatitude, minLongitude, maxLatitude, maxLongitude, filteredData.length])

  return (
    <Wrapper height={modalWrapperHeight} width={modalWrapperWidth}>
      <MapWrapper
        ref={mapContainer}
        width={modalContentWidth}
      >
        <StyledInput
          type='text'
          value={inputValue}
          onChange={handleSearchChange}
          placeholder={`Поиск...`}
        />
        <ResetButton onClick={() => {
          mapRef.current.balloon.close()
          setResetZoom(!resetZoom)
        }} />
        {!isYmapsReady && (
          <CircleLoader color='var(--main-color)' />
        )}
      </MapWrapper>
      <FooterWrapper width={modalContentWidth}>
        {!isSmallScreen && <InfoWrapper>
          <span>{`-`} </span>
          <span>{`Количество маркеров: ${filteredData.length} из ${data.length}`}</span>
        </InfoWrapper>}
        <Button
          style={{ minWidth: '130px'}}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          Закрыть
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}
