import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SelectLabel = styled.label`
  position: absolute;
  top: -15px;
  line-height: 1rem;
  font-size: 0.8rem;
  width: 100%;
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  transform-origin: 0% 100%;
  transition: color 0.2s;

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0 0 8px 0;
  padding: 0;
  `

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  witdh: 100%;
  height: 2.9rem;
  &::after {
    content: '';
    color: var(--input-text-color);
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
    ${({ disabled }) =>
    disabled &&
    css`
        border-top-color: rgba(0,0,0,0.42);
        cursor: default;
      `}
    ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
  }
  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
    color: var(--text-color-disabled);
    border-bottom: 1px dotted var(--border-color-disabled);
  `}
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0 29px 0 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--input-text-color);

  ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
        `
      : $forceDarkMode
      && css`
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
            cursor: pointer;
          }
        `}

  ${({ $textOverflow }) =>
    $textOverflow &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

const DropdownMenu = styled.ul`
  padding: 0;
  background: var(--background-color);
  color: var(--input-text-color);
  border: 1px solid var(--line-color);
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  ${({ $isOpen, $position }) => `
    top: ${$position === 'up' ? '45px' : '5px'};
    visibility: ${$isOpen ? 'visible' : 'hidden'};
    opacity: ${$isOpen ? '1' : '0'};
    transform: ${$position === 'up' ? ($isOpen ? 'translateY(-100%)' : 'scaleY(1)') : ($isOpen ? 'scaleY(1)' : 'scaleY(1)')};
  `};
`

const SearchWrapper = styled.div`
  position: relative;
  width: auto;
  margin: 20px 10px 15px 10px;
  display: flex;
  justify-content: center;
`

const SearchLabel = styled.label`
  position: absolute;
  left: 0;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  cursor: text;
  transform-origin: 0% 100%;
  transition: color 0.2s, font-size 0.2s, top 0.2s, padding-top 0.2s, transform 0.2s${({ $hasValue }) => ($hasValue ? ', none' : '')};
  top: ${({ $isSearchFocused, $hasValue }) => ($isSearchFocused || $hasValue ? '-26px' : '15px')};
  font-size: ${({ $isSearchFocused, $hasValue }) => ($isSearchFocused || $hasValue ? '0.7rem' : '0.9rem')};
  transform: ${({ $isSearchFocused, $hasValue }) => ($isSearchFocused || $hasValue ? 'translateY(12px)' : 'none')};
  color: ${({ $isSearchFocused }) => ($isSearchFocused ? 'var(--input-focused-color)' : 'var(--input-element-color)')};
`

const Search = styled.input`
  height: 2.9rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: var(--input-text-color);
  outline: none;
  box-sizing: content-box;
  cursor: text;
  white-space: nowrap;
  overflow: ${({ $textOverflow }) => ($textOverflow ? 'hidden' : 'visible')};
  text-overflow: ${({ $textOverflow }) => ($textOverflow ? 'ellipsis' : 'clip')};
  transition: box-shadow 0.3s, border 0.3s;
  ${({ $isSearchFocused, $borderHeight, $focusedBoxShadow }) =>
    $isSearchFocused
      ? css`
        border-bottom: ${$borderHeight} solid var(--input-focused-color);
        ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
        border-bottom: ${$borderHeight} solid var(--input-element-color);
        box-shadow: none;
      `}
`

const OptionsWrapper = styled.div`
  max-height: ${({ $optionsHeight }) => ($optionsHeight)}px;
  overflow-y: auto;
`

const Option = styled.li`
  cursor: pointer;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  &:hover {
    background-color: var(--background-tertiary-color);
  }
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--background-tertiary-color)' : 'transparent')};
`

const OptionSpan = styled.span`
  color: var(--input-text-color);
`

export default function Select(props) {
  const { label, name, id, value = '', searchable = false, searchLabel = 'Поиск...', options = [], optionsHeight = 200, forceDarkMode = false, borderOption = 1, disabled, onChange, onClick, onBlur, onFocus, ...rest } = props
  const [isFocused, setIsFocused] = useState(false)
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(value)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)
  const dropdownRef = useRef(null)
  const dropdownMenuRef = useRef(null)
  const searchWrapperRef = useRef(null)
  const searchLabelRef = useRef(null)
  const searchRef = useRef(null)
  const [dropdownPosition, setDropdownPosition] = useState('down')

  const checkDropdownPosition = useCallback(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect()
      const spaceToBottom = window.innerHeight - rect.bottom
      const spaceToTop = rect.top

      if (spaceToBottom < optionsHeight && spaceToTop > optionsHeight) {
        setDropdownPosition('up')
      } else {
        setDropdownPosition('down')
      }
    }
  }, [optionsHeight])

  const OptionText = ({ option, inputValue }) => {
    // Ensure option is a string
    if (typeof option !== 'string') {
      // Handle the case when option is not a string (e.g., return a default value or null)
      return null; // or any other appropriate fallback
    }
  
    return (
      <React.Fragment>
        {inputValue === ''
          ? <OptionSpan>{option}</OptionSpan>
          : option.split(new RegExp(`(${inputValue})`, 'gi')).map((part, index) =>
              part.toLowerCase() === inputValue.toLowerCase() ? (
                <span key={index} style={{ color: 'var(--input-focused-color)' }}>
                  {part}
                </span>
              ) : (
                <React.Fragment key={index}>{part}</React.Fragment>
              )
            )}
      </React.Fragment>
    )
  }
  
  const generateRefs = (length) => Array.from({ length }, () => React.createRef())
  const [optionRefs, setOptionRefs] = useState(generateRefs(options.length))
  const setSelectedOptionByValue = useCallback((value) => {
    const index = options.findIndex((option) => option.value === value)
    setSelectedOptionIndex(index)
  }, [options])

  useEffect(() => {
    if (value !== undefined) {
      setSelectedOptionByValue(value)
    }
  }, [value, setSelectedOptionByValue])

  useEffect(() => {
    if (options.find(option => option.value === value)) {
      setSelectedOption(value)
    } else {
      setSelectedOption('')
    }
  }, [value, options])

  useEffect(() => {
    setOptionRefs(generateRefs(options.length))
  }, [options])

  const [filteredOptions, setFilteredOptions] = useState(options)

  const filterOptions = useCallback(
    (search) => {
      if (search === '') {
        setFilteredOptions(options)
        return
      }

      const filtered = options.filter((option) => {
        // Check if option.label is not null or undefined before calling toLowerCase
        return option.label && option.label.toLowerCase().includes(search.toLowerCase())
      })
      setFilteredOptions(filtered)
    },
    [options]
  )

  useEffect(() => {
    filterOptions(searchValue)
  }, [searchValue, filterOptions])

  useEffect(() => {
    if (isOpen) {
      setSelectedOptionIndex(0)
    }
  }, [searchValue, isOpen])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  useEffect(() => {
    if (optionRefs && optionRefs[selectedOptionIndex] && selectedOptionIndex !== -1 && optionRefs[selectedOptionIndex].current && isOpen) {
      optionRefs[selectedOptionIndex].current.scrollIntoViewIfNeeded(false)
    }
  }, [selectedOptionIndex, optionRefs, isOpen])

  const toggleDropdown = useCallback(() => {
    checkDropdownPosition()
    setIsOpen(!isOpen)
  }, [isOpen, checkDropdownPosition])

  const handleOptionClick = useCallback((option) => {
    setSelectedOption(option.value)

    const fakeEvent = {
      target: {
        name: props.name,
        value: option.value,
        type: 'select',
      },
    }

    if (typeof onChange === 'function') {
      onChange(fakeEvent)
    }
    if (typeof onChange === 'function') {
      onChange(fakeEvent)
    }
    setIsFocused(false)
    setIsOpen(false)
    setSearchValue('')
    toggleDropdown()
  },
    [toggleDropdown, onChange, props.name]
  )

  const handleArrowKeys = (e) => {
    if (!isOpen) {
      return
    }
    if (e.key === 'Enter' && selectedOptionIndex !== -1) {
      e.preventDefault()
      handleOptionClick(filteredOptions[selectedOptionIndex])
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      e.stopPropagation()
      if (selectedOptionIndex < filteredOptions.length - 1) {
        setSelectedOptionIndex(selectedOptionIndex + 1)
        setSelectedOption(filteredOptions[selectedOptionIndex + 1]?.value)
        if (optionRefs[selectedOptionIndex + 1]?.current) {
          optionRefs[selectedOptionIndex + 1].current.focus()
        }
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      e.stopPropagation()
      if (selectedOptionIndex > 0) {
        setSelectedOptionIndex(selectedOptionIndex - 1)
        setSelectedOption(filteredOptions[selectedOptionIndex - 1]?.value)
        if (optionRefs[selectedOptionIndex - 1]?.current) {
          optionRefs[selectedOptionIndex - 1].current.focus()
        }
      }
    } else if (e.key === 'Delete' && e.target === searchRef.current) {
      setSearchValue('')
    }
  }

  const handleBlur = useCallback(
    (e) => {
      const searchRelated =
        searchable &&
        (searchRef.current.contains(e.relatedTarget) ||
          searchLabelRef.current.contains(e.relatedTarget))

      if ((!e.relatedTarget && searchable) || (isOpen && (dropdownMenuRef.current.contains(e.relatedTarget) || searchRelated))) {
        return
      }
      setIsFocused(false)
      setIsOpen(false)
      if (typeof onBlur === 'function') {
        onBlur(e)
      }
    },
    [onBlur, isOpen, searchable]
  )

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus])

  const handleSearchFocus = useCallback((e) => {
    setIsSearchFocused(searchValue.length > 0 || e.target === searchRef.current)
  }, [searchValue])

  const handleSearchBlur = useCallback((e) => {
    if (e.relatedTarget !== searchRef.current) {
      setIsFocused(false)
      setIsSearchFocused(searchValue.length > 0)
    }
  }, [searchValue])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current &&
        dropdownMenuRef.current &&
        searchRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !dropdownMenuRef.current.contains(e.target) &&
        !(e.target === searchRef.current || e.target === searchRef.current.parentElement)
      ) {
        setIsFocused(false)
        setIsOpen(false)
        setIsOpen(false)
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef, dropdownMenuRef, searchRef, isOpen])

  return (
    <SelectWrapper>
      <SelectLabel
        disabled={disabled}
        title={label}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
      >
        {label}
      </SelectLabel>
      <Dropdown>
        <InputWrapper
          disabled={disabled}
          $borderHeight={borderHeight}
          $focusedBoxShadow={focusedBoxShadow}
          $isFocused={isFocused}
          $forceDarkMode={forceDarkMode}
        >
          <Input
            ref={dropdownRef}
            id={id}
            autoComplete='off'
            value={options.find(option => option.value === selectedOption)?.label || '-'}
            disabled={disabled}
            readOnly={true}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={toggleDropdown}
            onKeyDown={handleArrowKeys}
            {...rest}
            $forceDarkMode={forceDarkMode}
          />
        </InputWrapper>
        <DropdownMenu ref={dropdownMenuRef} $isOpen={isOpen} $position={dropdownPosition}>
          {searchable &&
            <SearchWrapper ref={searchWrapperRef}>
              <SearchLabel
                ref={searchLabelRef}
                onClick={() => searchRef.current.focus()}
                $isSearchFocused={isSearchFocused}
              >
                {searchLabel}
              </SearchLabel>
              <Search
                ref={searchRef}
                type='text'
                autoComplete='off'
                value={searchValue}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                onChange={(e) => setSearchValue(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={handleArrowKeys}
                $isSearchFocused={isSearchFocused}
                $borderHeight={borderHeight}
                $focusedBoxShadow={focusedBoxShadow}
              />
            </SearchWrapper>}
          <OptionsWrapper $optionsHeight={optionsHeight}>
            {filteredOptions &&
              filteredOptions.length > 0 &&
              filteredOptions.map((option, index) => (
                <Option
                  ref={optionRefs[index]}
                  key={index}
                  onMouseDown={() => handleOptionClick(option)}
                  value={option.value}
                  $isSelected={option.value === selectedOption}
                >
                  <OptionText
                    option={option.label}
                    inputValue={searchValue}
                  />
                </Option>
              ))}
          </OptionsWrapper>
        </DropdownMenu>
      </Dropdown>
    </SelectWrapper>
  )
}