import { useSelector } from 'react-redux'
import { useMemo } from 'react'

export function useAuth() {
  const auth = useSelector((state) => state.auth)

  const authData = useMemo(() => {
    const isAuthenticated = !!auth.id
    const isAdmin = isAuthenticated && auth.roles.some(role => role.value === 'admin')
    return { isAuthenticated, isAdmin, ...auth }
  }, [auth])

  return authData
}
