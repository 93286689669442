import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import AdminIcon from '../../Icons/AdminIcon'
import UserIcon from '../../Icons/UserIcon'
import ModalContext from '../../../context/ModalContext'
import SettingsIcon from '../../Icons/SettingsIcon'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../../store/actions/authActions'
import { useAuth } from '../../../hooks/useAuth'
import UserSettings from '../../UserSettings/UserSettings'
import { useSoundTheme } from '../../../hooks/useSoundTheme'
import CompanyPicker from '../../CompanyPicker'
import OrganizationIcon from '../../Icons/OrganizationIcon'
import { useSocketContext } from '../../../context/SocketContext'
import AccountIcon from '../../Icons/AccountIcon'
import useHover from '../../../hooks/useHover'
import ArrowDownIcon from '../../Icons/ArrowDownIcon'
import { useLanguage } from '../../../context/LanguageContext'
import Switch from '../../Inputs/Switch'
import { ThemeContext } from '../../../context/ThemeContext'
import RuFlagIcon from '../../Icons/RuFlagIcon'
import UsFlagIcon from '../../Icons/UsFlagIcon'
import ServerIcon from '../../Icons/ServerIcon'
import ServerInfo from '../../ServerInfo'
import UsersIcon from '../../Icons/UsersIcon'
import TelegramIcon from '../../Icons/TelegramIcon'
import useTranslate from '../../../hooks/useTranslate'
import Library from '../../../Library'
import AdminBroadcast from '../../AdminBroadcast/AdminBroadcast'
import BroadcastIcon from '../../Icons/BroadcastIcon'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: initial;
`

const AccountDropdownToggle = styled.div`
  position: relative;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const AccountIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: #fff;
  }
  &::before {
    content: '';
    position: absolute;
    width: ${props => props.$size - 1}px;
    height: ${props => props.$size - 1}px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  ${({ $isUserHovering }) =>
    $isUserHovering
    && css`
      &::before {
        top: 0.5px;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.55);
        opacity: 1;
      }
        `
  }
`

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    path {
      transition: fill 0.3s ease;
    }
  }
  
  ${({ $isUserHovering }) =>
    $isUserHovering
      ? css`
          svg {
            path {
              fill: #FFF;
            }   
          }
        `
      : css`
          svg {
            path {
              fill: var(--text-secondary-color);
            }   
          }
        `}
`

const Dropdown = styled.div`
  position: absolute;
  z-index: 1000000;
  opacity: 0;
  visibility: hidden;
  right: 5px;
  top: 40px;
  width: 270px;
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--line-color);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 8px 8px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  ${(props) =>
    props.$isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const UserWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
`

const UserInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  height: 55px;
  padding: 5px 8px 5px 5px;
  color: var(--text-color);
`

const InfoLeftBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const InfoRightBox = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 5px;
`

const UserEmail = styled.div`
  color: var(--text-color);
`

const Username = styled.div`
  color: var(--text-secondary-color);
  font-size: 14px;
`

const DropdownItem = styled.div`
  display: grid;
  grid-template-columns: ${({ $svgWidth }) => ($svgWidth || 25)}px auto;
  align-items: center;
  justify-items: start;

  & > svg {
    justify-self: center; 
  }

  gap: 5px;
  padding: 5px 8px 5px 8px;
  max-height: 35px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
  &:hover {
    background-color: var(--background-tertiary-color);
  }
`

const LogoutSvg = styled.svg`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const Divider = styled.div`
  height: 1px;
  overflow: hidden;
  background-color: var(--line-color);
  margin: 10px 0px 10px 0px;
`

const LanguageToggleWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const Tooltip = styled.div`
  display: ${props => (props.$show ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  right: -100%;
  transform: translateX(-40%) translateY(100%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10000;
`

const About = styled.div`
  position: absolute;
  right: 8px;
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  z-index: 10000 !important;

  a {
    transition: color 0.3s ease;
    color: var(--text-secondary-color);
    text-decoration: none;

    &:hover {
      color: var(--text-color);
      html[data-theme='dark'] & {
      color: #c6d0d5;
      }
      text-decoration: underline;
    }
  }
`

export default function User() {
  const { id, isAdmin, email, username } = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const translate = useTranslate()
  const { toggleTheme, theme } = useContext(ThemeContext)
  const socket = useSocketContext()
  const [isOpen, setIsOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)
  const { logoutSound } = useSoundTheme()
  const userRef = useRef(null)
  const isUserHovering = useHover(userRef)

  const logoutHandler = (e) => {
    e.preventDefault()
    logoutSound()
    dispatch(logoutUser(navigate, socket))
  }

  const toggleDropdown = () => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current)
    }
    setIsOpen(!isOpen)
    if (!isOpen) setShowTooltip(false)
  }

  const tooltipTimeout = useRef(null)

  const handleMouseEnter = () => {
    if (!isOpen) {
      tooltipTimeout.current = setTimeout(() => {
        setShowTooltip(true)
      }, 500)
    }
  }

  const handleMouseLeave = () => {
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current)
    }
    setShowTooltip(false)
  }

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout.current) {
        clearTimeout(tooltipTimeout.current)
      }
    }
  }, [])

  const wrapperRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const toggleSettings = (e) => {
    e.preventDefault()
    toggleDropdown()
    setModalContent(<UserSettings />)
    setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
    showModal()
  }

  const handleCompanySelect = (e) => {
    e.preventDefault()
    setModalContent(<CompanyPicker />)
    setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
    showModal()
  }

  const { language, setLanguage } = useLanguage()

  const toggleLanguage = () => {
    const newLanguage = language === 'ru' ? 'en' : 'ru'
    setLanguage(newLanguage)
  }

  return (
    <Wrapper ref={wrapperRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <AccountDropdownToggle onClick={toggleDropdown} ref={userRef}>
        <AccountIconWrapper $isUserHovering={isUserHovering} $size={25}>
          <AccountIcon size={25} />
        </AccountIconWrapper>
        <ArrowIconWrapper $isUserHovering={isUserHovering}>
          <ArrowDownIcon />
        </ArrowIconWrapper>
      </AccountDropdownToggle>
      <Dropdown $isOpen={isOpen}>
        <UserWrapper>
          <UserInfo title={isAdmin ? translate('admin') : translate('user')}>
            <InfoLeftBox>
              {isAdmin ? <AdminIcon color='#E57373' size={31} /> : <UserIcon color='#26A69A' size={31} />}
            </InfoLeftBox>
            <InfoRightBox>
              <UserEmail>
                {Library.truncate(email, 25)}
              </UserEmail>
              <Username>
                {Library.truncate(username, 25)}
              </Username>
            </InfoRightBox>
          </UserInfo>
          <Divider />
          {isAdmin &&
            <>
              <DropdownItem onClick={() => {
                navigate('/users')
                toggleDropdown()
              }}>
                <UsersIcon size={25} />
                {translate('users')}
              </DropdownItem>
              <DropdownItem onClick={() => {
                setModalContent(<AdminBroadcast />)
                setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
                showModal()
                toggleDropdown()
              }}>
                <BroadcastIcon size={20} />
                {translate('alert')}
              </DropdownItem>
              <DropdownItem onClick={() => {
                setModalContent(<ServerInfo />)
                showModal()
                toggleDropdown()
              }}>
                <ServerIcon size={20} />
                {translate('server')}
              </DropdownItem>
            </>}
          <DropdownItem onClick={toggleSettings}>
            <SettingsIcon size={22} />
            {translate('settings')}
          </DropdownItem>
          <DropdownItem
            onClick={(e) => { handleCompanySelect(e); toggleDropdown() }}
          >
            <OrganizationIcon size={20} />
            {translate('selectOrganization')}
          </DropdownItem>
          <DropdownItem onClick={() => { window.open(`https://${process.env.REACT_APP_TELEGRAM_LINK}?start=${id}-${language}`) }}>
            <TelegramIcon size={22} />
            {translate('telegram')}
          </DropdownItem>
        </UserWrapper>
        <Divider />
        <DropdownItem onClick={toggleLanguage}>
          <LanguageToggleWrapper>
            {language === 'ru' ? <RuFlagIcon /> : <UsFlagIcon />}
            <div style={{ color: 'var(--text-secondary-color)' }}>{translate('interfaceLanguage')}</div>
          </LanguageToggleWrapper>
        </DropdownItem>
        <DropdownItem $svgWidth={150}>
          <Switch
            label={translate('darkTheme')}
            name='theme'
            id='theme'
            checked={theme === 'dark' ? true : false}
            onChange={toggleTheme}
          />
        </DropdownItem>
        <Divider />
        <DropdownItem onClick={logoutHandler}>
          <LogoutSvg viewBox='0 0 24 24' fill='none'>
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M15 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8m4-9-4-4m4 4-4 4m4-4H9'
            />
          </LogoutSvg>
          <span style={{ fontWeight: 'bold' }}>{translate('logout')}</span>
        </DropdownItem>
        <About title={translate('developed')}>
          <a href='https://pocketoffice.space/' target='_blank' rel='noopener noreferrer'>
            pocketoffice.space
          </a>
        </About>
      </Dropdown>
      <Tooltip $show={showTooltip}>
        <div>{`${translate('user')}:`}</div>
        <div>{Library.truncate(email, 25)}</div>
      </Tooltip>
    </Wrapper>
  )
}