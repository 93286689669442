import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import ModalContext from '../../../context/ModalContext'
import ResultInput from '../../../components/Inputs/ResultInput'
import styled from 'styled-components'
import BarChart from '../../../components/Charts/BarChart'
import CircleLoader from '../../../components/Loaders/CircleLoader'
import useModalContentSize from '../../../hooks/useModalContentSize'
import Library from '../../../Library'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
    minHeight: props.height + 'px',
    maxHeight: props.height + 'px',
  },
}))`
  position: relative;
  display: flex;
  flex-direction: column;
`

const LabelWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  flex-shrink: 0;
`

const ChartWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px;
  flex: 1;
  border: 1px solid var(--surface-color);
  html[data-theme='dark'] & {
    border: 1px solid var(--list-line-color-variant);
  }
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`

export const RoutesChart = (props) => {
  const { data } = props
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const [dataReady, setDataReady] = useState(false)
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    let currPeriod = ''
    let count = 0
    let arr = []
    data.forEach((item, i) => {
      const period = item.dateOfDeparture ? `${new Date(item.dateOfDeparture).getFullYear()}-${(new Date(item.dateOfDeparture).getMonth() + 1).toString().length < 2 ? '0' + (new Date(item.dateOfDeparture).getMonth() + 1) : new Date(item.dateOfDeparture).getMonth() + 1}` : ''
      if (i === 0) {
        currPeriod = period
      }
      if (currPeriod === period) {
        count++
        if (i === data.length - 1) {
          const date = data[i].dateOfDeparture
          const lastPeriod = date ? `${new Date(date).getFullYear()}-${(new Date(date).getMonth() + 1).toString().length < 2 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}` : ''
          arr.push({ 'Месяц': Library.replacePeriod(lastPeriod), 'Кол-во': count })
        }
      } else {
        arr.push({ 'Месяц': Library.replacePeriod(currPeriod), 'Кол-во': count })
        currPeriod = period
        count = 1
      }
      setChartData(arr)
    })
    setDataReady(true)
  }, [data])

  return (
    <Wrapper height={modalWrapperHeight} width={modalWrapperWidth}>
      <LabelWrapper>
        <h2>Кол-во заказов по месяцам:</h2>
      </LabelWrapper>
      <ChartWrapper
        width={modalContentWidth}
      >
        {dataReady ?
          <BarChart
            title={'Кол-во заказов по месяцам'}
            data={chartData}
            xKey={'Месяц'}
            yKey={'Кол-во'}
          />
          :
          <CircleLoader />}
      </ChartWrapper>
      <FooterWrapper $isSmallScreen={isSmallScreen} width={modalContentWidth}>
        <Button
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          Закрыть
        </Button>
        <div style={{ width: '120px' }}>
          <ResultInput
            value={`${data.length?.toLocaleString() || 0} маршрут`}
            forceDarkMode={true}
            borderOption={3}
          />
        </div>
      </FooterWrapper>
    </Wrapper>
  )
}