import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import DevIcon from '../../Icons/DevIcon'

const BrandWrapper = styled.div`
  position: relative;
  padding: 0 0 0 10px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
`

const CompanyName = styled.div`
  cursor: pointer;
`

const DevWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: #fff;
  }
`

const companyName = {
  'logistics': 'Логистика',
  'leaderSpecTrans': 'ЛидерСпецТранс',
  'ipKarpov': 'ИП Карпов',
}

export default function Brand() {
  const navigate = useNavigate()
  const message = useMessage()
  const isDevelopment = process.env.NODE_ENV === 'development'
  const { companyId } = useSelector(state => state.data)

  return (
    <BrandWrapper>
      <CompanyName onClick={() => navigate('/')}>
        {companyName[companyId]}
      </CompanyName>
      {isDevelopment &&
        <DevWrapper
          title='Разработка в процессе'
          onClick={() => {
            message('Приложение запущено в режиме разработки', 'info')
          }}
        >
          <DevIcon width='28px' height='28px' />
        </DevWrapper>}
    </BrandWrapper>
  )
}