import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ListScrollAllSides from '../../components/ListScrollAllSides'
import Button from '../../components/Buttons/Button'
import moment from 'moment'
import ModalContext from '../../context/ModalContext'
import { StockBalance } from './components/StockBalance'
import ResultInput from '../../components/Inputs/ResultInput'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'
import useTranslate from '../../hooks/useTranslate'
import styled from 'styled-components'
import FilterButton from './components/ListFilter/components/FilterButton'

const ResultInputWrapper = styled.div`
  margin-left: 10px;
  min-width: 115px;
  max-width: 115px;
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const translate = useTranslate()
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)
  const router = useNavigate()
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [totalAmount, setTotalAmount] = useState(0)
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 70 },
    { id: 'status', name: 'Статус', type: 'status', width: 60 },
    { id: 'date', name: 'Дата', dataType: 'date', type: 'date', width: 100 },
    { id: 'direction', name: 'Приход/расход', type: 'direction', width: 60 },
    { id: 'inventory', name: 'Номенклатура', width: 250 },
    { id: 'partNumber', name: 'Номер детали', width: 150 },
    { id: 'manufacturer', name: 'Производитель', width: 150 },
    { id: 'amount', name: 'Кол-во', dataType: 'number', width: 70 },
    { id: 'car', name: 'Автомобиль', width: 150 },
    { id: 'comment', name: 'Комментарий', width: 355 },
    { id: 'lastModified', name: 'Дата изменения', dataType: 'date', type: 'timestamp', width: 140 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const { cars, inventory, stock, loading, error } = useSelector(state => state.data)
  const [stockReplaced, setStockReplaced] = useState([])

  const filteredTableData = useMemo(() => {
    const { search, status, direction, startDate, endDate } = filterQuery

    const matchesSearch = (item) =>
      !search || Object.values(item)
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())

    const matchesStatus = (item) =>
      !status || item.status === status

    const matchesDirection = (item) =>
      !direction || item.direction === direction

    const matchesStartDate = (item) =>
      !startDate || moment(item.date).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (item) =>
      !endDate || moment(item.date).isSameOrBefore(endDate, 'day')

    const filtered = stockReplaced.filter(
      (item) =>
        matchesSearch(item) &&
        matchesStatus(item) &&
        matchesDirection(item) &&
        matchesStartDate(item) &&
        matchesEndDate(item)
    )

    pageState.sort.key && Library.sort(filtered, pageState.sort)

    return filtered
  }, [stockReplaced, filterQuery, pageState.sort])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [stock, mainState])

  //======NEW ENTRY======//
  const newEntry = useCallback(() => {
    router(`/${pageName}/new`)
  }, [router, pageName])

  useEffect(() => {
    if (!isDataLoaded || loading) return
    setDataReady(true)
  }, [isDataLoaded, loading])

  useEffect(() => {
    if (!dataReady || loading || error) {
      return
    }
    let replaced = []
    stock.forEach((originalItem) => {
      let item = { ...originalItem }
      const inventoryItem = Library.findItemById(inventory, item.inventory)
      item['inventory'] = inventoryItem ? inventoryItem.name : ''
      item['partNumber'] = inventoryItem ? inventoryItem.partNumber : ''
      item['manufacturer'] = inventoryItem ? inventoryItem.manufacturer : ''
      item['car'] = Library.findNameByKey(cars, item.car)
      replaced.push(item)
    })
    setStockReplaced(replaced)
  }, [loading, error, dataReady, stock, cars, inventory])

  useEffect(() => {
    let checkIn = 0
    let checkOut = 0
    filteredTableData.forEach((item) => {
      if (item.direction === 'incoming') {
        checkIn += item.amount
      } else {
        checkOut += item.amount
      }
    })
    setTotalAmount(checkIn - checkOut)
  }, [filteredTableData])

  return (
    <>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && dataReady && (
        <VirtualizedList
          ref={virtualizedListRefs}
          offsetHeight={140}
          headers={headers}
          pageName={pageName}
          items={filteredTableData}
          filterQuery={filterQuery}
          separateDateKey={pageState.sort.dataType === 'date' && pageState.sort.key}
          navigateTo={pageName}
        />
      )}
      <Footer>
        <ListScrollAllSides virtualizedListRefs={virtualizedListRefs} />
        <Button
          onClick={newEntry}
          title={translate('newEntry')}
        >
          {translate('newEntry')}
        </Button>
        {/* ------- STOCK BALANCE BUTTON ------- */}
        <Button
          style={{ minWidth: '100px' }}
          onClick={(e) => {
            e.preventDefault()
            setModalContent(<StockBalance tableData={stockReplaced} />)
            setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
            showModal(true)
          }}
          title='Показать остатки'
        >
          Остатки
        </Button>
        {/* ------- FILTER BUTTON ------- */}
        <FilterButton
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          pageName={pageName}
        />
        {/* ------- TOTAL ------- */}
        <ResultInputWrapper>
          <ResultInput
            value={`${totalAmount?.toLocaleString()} Шт.`}
            forceDarkMode={true}
            borderOption={3}
          />
        </ResultInputWrapper>
      </Footer>
    </>
  )
}