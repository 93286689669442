export const loadYandexMap = () => {
  try {
    // Dynamically load the Yandex Maps API
    const script = document.createElement('script')
    script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_MAP_API_KEY}&lang=ru_RU`
    script.type = 'text/javascript'
    script.async = true
    document.head.appendChild(script)
  } catch (error) {
    console.error('Failed to load Yandex Maps API', error)
  }
}
