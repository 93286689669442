import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import ModalContext from '../../../context/ModalContext'
import ResultInput from '../../../components/Inputs/ResultInput'
import styled, { css } from 'styled-components'
import useModalContentSize from '../../../hooks/useModalContentSize'
import TableComponent from '../../../components/Tables/TableComponent'
import moment from 'moment'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`

export const Debt = (props) => {
  const { data } = props
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)
  const [total, setTotal] = useState(0)
  const [overdueTotal, setOverdueTotal] = useState(0)

  useEffect(() => {
    let totalSum = 0
    let overdueSum = 0
    let obj = {}
    const today = moment().format('YYYY-MM-DD') // Get today's date formatted

    data.forEach((item) => {
      if (item.paymentStatus !== 'Оплачен') {
        totalSum += item.routeCost
        const dueDate = item.dueDate ? moment(item.dueDate).format('YYYY-MM-DD') : null

        // Calculate overdue amount
        if (dueDate && moment(dueDate).isBefore(today)) {
          overdueSum += item.routeCost
        }

        if (Object.keys(obj).includes(item.customer)) {
          obj[item.customer].amount += item.routeCost
          obj[item.customer].count += 1

          // Update overdue amount for the customer
          if (dueDate && moment(dueDate).isBefore(today)) {
            obj[item.customer].overdueAmount = (obj[item.customer].overdueAmount || 0) + item.routeCost
          } else if (!obj[item.customer].overdueAmount) {
            obj[item.customer].overdueAmount = 0
          }
        } else {
          obj[item.customer] = {
            amount: item.routeCost,
            count: 1,
            overdueAmount: dueDate && moment(dueDate).isBefore(today) ? item.routeCost : 0
          }
        }
      }
    })

    const tableDataArray = Object.keys(obj).map(customer => ({ customer, ...obj[customer] }))

    tableDataArray.sort((a, b) => {
      const customerA = a.customer.toUpperCase()
      const customerB = b.customer.toUpperCase()

      if (customerA < customerB) {
        return -1
      }
      if (customerA > customerB) {
        return 1
      }
      return 0
    })

    setTotal(totalSum)
    setOverdueTotal(overdueSum)
    setTableData(tableDataArray)
    setTableDataReady(true)
  }, [data])

  return (
    <Wrapper $isLoading={!tableDataReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <TableComponent columnWidths={['40%', '20%', '20%', '20%']} tableWidth={modalContentWidth} isLoading={!tableDataReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key='name'>Заказчик</th>
            <th key='amount'>Сумма долга</th>
            <th key='overdue'>Просрочено</th>
            <th key='count'>Кол-во</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {tableData && tableData
            .filter(item => item.amount)
            .map((item) => {
              const { customer, amount, overdueAmount, count } = item
              return (
                <tr key={customer}>
                  <td>{['', 'null', null, undefined].includes(customer) ? '-' : customer}</td>
                  <td>{amount ? `${parseFloat(amount.toFixed()).toLocaleString()} р.` : '-'}</td>
                  <td>{overdueAmount ? <span style={{ color: 'red' }}>{`${parseFloat(overdueAmount.toFixed()).toLocaleString()} р.`}</span> : '-'}</td>
                  <td>{count ? count.toLocaleString() : '-'}</td>
                </tr>
              )
            })}
        </tbody>
      </TableComponent>
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <Button
          style={{ minWidth: '100px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          Закрыть
        </Button>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ minWidth: '220px', maxWidth: '220px' }} title='Общая сумма / Просроченная сумма'>
            <ResultInput
              value={
                `${total?.toLocaleString()} р. / ${overdueTotal?.toLocaleString()} р. ` +
                `(${(overdueTotal && total) ? ((overdueTotal / total) * 100).toFixed(2) : 0}%)`
              }
            />
          </div>
        </div>
      </FooterWrapper>
    </Wrapper>
  )
}