import React, { useContext, useRef } from 'react'
import { UsersTable } from './components/UsersTable'
import { RegistrationForm } from './components/RegistrationForm'
import LinearLoader from '../../components/Loaders/LinearLoader'
import styled from 'styled-components'
import Button from '../../components/Buttons/Button'
import ModalContext from '../../context/ModalContext'
import { useSelector } from 'react-redux'
import useWindowHeight from '../../hooks/useWindowHeight'
import useTranslate from '../../hooks/useTranslate'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
`

const AdminWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 65px 1fr;
  margin: 30px auto;
  width: 100%;
`

const ActionsWrapper = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`

const UsersTableWrapper = styled.div`
  grid-column: 2;
  grid-row: 2;
  margin-left: 20px;
  margin-right: 20px;
`

export const Users = () => {
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const translate = useTranslate()
  const { showModal, setModalContent } = useContext(ModalContext)
  const wrapperRef = useRef(null)
  useWindowHeight(wrapperRef, 84)
  const roles = useSelector(state => state.main.roles)

  return (
    <Wrapper ref={wrapperRef}>
      <LinearLoader
        isLoading={!isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded &&
        <AdminWrapper>
          <ActionsWrapper >
            {/* ------- ADD USER BTN ------- */}
            <Button
              onClick={() => {
                setModalContent(
                  <RegistrationForm
                    roles={roles}
                  />
                )
                showModal()
              }}
            >
              {translate('addUser')}
            </Button>
          </ActionsWrapper>
          {/* ------- USERS TABLE ------- */}
          {isDataLoaded &&
            <UsersTableWrapper>
              <UsersTable roles={roles} />
            </UsersTableWrapper>
          }
        </AdminWrapper>
      }
    </Wrapper>
  )
}
