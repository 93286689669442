import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../context/ModalContext'
import styled, { css } from 'styled-components'
import { useFetching } from '../../hooks/useFetching'
import PostService from '../../API/PostService'
import { useMessage } from '../../hooks/useMessage'
import Library from '../../Library'
import Button from '../Buttons/Button'
import CircleLoader from '../Loaders/CircleLoader'
import useModalContentSize from '../../hooks/useModalContentSize'
import { useLanguage } from '../../context/LanguageContext'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const TableWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  border: 1px solid var(--line-color);
  position: relative;
  overflow: auto;
  flex: 1;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    border: none;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-all;
  }

  td {
    padding: 8px 5px;
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    @media (min-width: 1200px) {
      padding: 10px 20px;
    }
    @media (min-width: 1600px) {
      padding: 12px 30px;
    }
  }

  th {
    padding: 10px 5px;
    @media (min-width: 1200px) {
      padding: 15px 20px;
    }
    @media (min-width: 1600px) {
      padding: 15px 30px;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1000;
    color: var(--text-inverted-color);
    background: var(--surface-color);
    html[data-theme='dark'] & {
      color: var(--text-color);
      background: var(--background-color);
    }
  }

  thead,
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--background-tertiary-color);
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: none;
  }

  th:nth-child(1), td:nth-child(1) {
    width: 20%;
    @media (min-width: 1200px) {
      padding: 15px 20px;
      width: 25%;
    }
    @media (min-width: 1600px) {
      padding: 15px 35px;
      width: 20%;
    }
  }
  th:nth-child(2), td:nth-child(2) {
    width: 25%;
    @media (min-width: 1200px) {
      padding: 15px 20px;
      width: 25%;
    }
    @media (min-width: 1600px) {
      padding: 15px 35px;
      width: 20%;
    }
  }
  th:nth-child(3), td:nth-child(3) {
    width: 55%;
    @media (min-width: 1200px) {
      padding: 15px 20px;
      width: 45%;
    }
    @media (min-width: 1600px) {
      padding: 15px 35px;
      width: 50%;
    }
  }
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
  flex-shrink: 0;
`

const UserSpan = styled.div`
  color: var(--main-color);
`

const text = {
  tableHeaders: {
    user: {
      en: 'User',
      ru: 'Пользователь'
    },
    changeDate: {
      en: 'Change Date',
      ru: 'Дата изменения'
    },
    changeDetails: {
      en: 'Change: Old Value → New Value',
      ru: 'Изменение: Старое значение → Новое значение'
    },
    close: {
      en: 'Close',
      ru: 'Закрыть'
    }
  }
}

export default function EntryHistory(props) {
  const { tableName, entryId, renderChangeDetails } = props
  const { language } = useLanguage()
  const message = useMessage()
  const [history, setHistory] = useState([])
  const { closeModal } = useContext(ModalContext)
  const { modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  const [fetchHistory, historyLoading, historyError, historyLoaded] = useFetching(
    async () => {
      const response = await PostService.get('data', `change-history-by-id`, { tableName, entryId }, language)
      if (response && !response.errors) {
        message(response.message)
      }
      const sortedHistory = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      setHistory(sortedHistory)
      historyError && console.log(historyError)
    }
  )

  useEffect(() => {
    fetchHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper
      $height={modalWrapperHeight}
      $width={modalWrapperWidth}
      $isLoading={!historyLoaded}
    >
      <TableWrapper
        $width={modalContentWidth}
        $isLoading={historyLoading}
      >
        {historyLoaded ?
          <Table>
            <thead>
              <tr>
                <th style={{ width: '200px' }}>{text.tableHeaders.user[language]}</th>
                <th style={{ width: '200px' }}>{text.tableHeaders.changeDate[language]}</th>
                <th>{text.tableHeaders.changeDetails[language]}</th>
              </tr>
            </thead>
            <tbody>
              {history.length === 0 ?
                <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                :
                history.map((change) => (
                  <tr key={change.id}>
                    <td><UserSpan>{Library.truncate(change.userEmail, 22)}</UserSpan></td>
                    <td>{Library.formatRecentDate(change.timestamp, language)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>
                      {renderChangeDetails(change)}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          :
          <CircleLoader />}
      </TableWrapper>
      {/* ------------(CLOSE BUTTON)------------ */}
      <FooterWrapper width={modalContentWidth}>
        <Button
          style={{ minWidth: '130px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          {text.tableHeaders.close[language]}
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}