import React, { createContext, forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled, { css } from 'styled-components'
import { FixedSizeList as List } from 'react-window'
import { areEqual } from 'react-window'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Library from '../../Library'
import useSingleAndDoubleClick from '../../hooks/useSingleAndDoubleClick'
import moment from 'moment'
import ContextMenu from '../ContextMenu/ContextMenu'

const ListWrapper = styled.div`
  position: relative;
  font-family: sans-serif;
  font-size: 0.8em;
`

const Headers = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0;
  font-weight: bold;
  border-radius: 0px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  height: 100%;
  flex-shrink: 0;
  background-color: var(--surface-color);
  width: ${(props) => props.width}px;
  padding-right: ${props => props.$isSortColumn ? 10 : 0}px;
`

const HeaderText = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Row = styled.div`
  display: flex;
  width: ${({ width }) => width}px !important;
`

const ActiveRowAfter = styled.div`
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    width: ${({ width }) => width}px;
    height: calc(100% + 1px);
    outline: 3px groove var(--list-outline-color);
    outline-offset: -2px;
    pointer-events: none;
  }
`

// Function to calculate color based on days difference
const calculateColor = (diffDays, isDarkTheme) => {
  if (diffDays > 30 && diffDays <= 1000) {
    return isDarkTheme ? 'var(--red-lighten-color)' : 'var(--red-color)'
  } else if (diffDays > 14 && diffDays <= 30) {
    return isDarkTheme ? 'var(--orange-lighten-color)' : '#fca228'
  } else if (diffDays >= 0 && diffDays <= 14) {
    return isDarkTheme ? 'var(--green-lighten-color)' : '#009926'
  }
  return 'inherit' // Default color
}

const Cell = styled.div`
  display: flex;
  align-items: center;
  html[data-theme='dark'] & {
    color: var(--list-text-color);
    background-color: var(--background-color);
  }
  border-radius: 0px;
  border-left: 1px solid var(--list-line-color);
  border-top: 1px solid var(--list-line-color);
  flex-shrink: 0;
  width: ${(props) => props.$width}px;

  ${({ $isLastCell }) => $isLastCell && `
    border-right: 1px solid var(--list-line-color);
  `}

  ${({ $isLastRow }) => $isLastRow && `
    && {
      border-bottom: 2px solid var(--list-line-color);
    }
  `}

  ${({ $isEven }) => $isEven && `
    background-color: var(--background-tertiary-color) !important;
  `}

  ${({ $isHovered }) => $isHovered && `
    font-weight: bold;
    color: var(--surface-color);
    html[data-theme='dark'] & {
      color: var(--list-text-color);
    }
  `}

  ${({ $isActive, $activeRowStyle, $lastModifiedColored, $header }) =>
    $isActive && (() => {
      switch ($activeRowStyle) {
        case 'fill':
          return css`
            font-weight: bold;
            background-color: var(--list-active-row) !important;
            color: ${($lastModifiedColored && $header.id === 'lastModified') ? '' : '#fff'};
          `
        default:
          return css``
      }
    })()
  }

  ${({ $previousDateNotEqual }) => $previousDateNotEqual && `
    border-top: 2px solid var(--surface-color);
    html[data-theme='dark'] & {
      border-top: 2px solid var(--list-line-color-variant);
    }
  `}

  ${({ $lastModifiedColored, $item, $header }) => {
    if ($lastModifiedColored && $item['lastModified'] && $header.id === 'lastModified') {
      const diffDays = moment().diff(moment($item[$header.id]), 'days')
      const isDarkTheme = document.documentElement.getAttribute('data-theme') === 'dark'
      const lastModifiedColor = calculateColor(diffDays, isDarkTheme)

      return `
        font-weight: bold;
        color: ${lastModifiedColor} !important;
      `
    }
  }}
`

const CellValueWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
`

const StickyListContext = createContext()
StickyListContext.displayName = 'StickyListContext'

const ItemWrapper = ({ data, index, style }) => {
  const { ItemRenderer } = data
  return <ItemRenderer index={index} style={style} />
}

const ListRow = memo((props) => {
  const { headers, items, index, style, isActive, onClick, activeRowStyle, zebraStriped, separateDateKey, filterQuery, lastModifiedColored, onRightClick, contextMenu } = props
  const item = items[index]
  const isLast = index === items.length - 1
  const isEven = zebraStriped && index % 2 === 0
  const previousDateNotEqual = separateDateKey && index > 0 && moment(items[index - 1][separateDateKey]).format('YYYY-MM-DD') !== moment(item[separateDateKey]).format('YYYY-MM-DD')
  const [isHovered, setIsHovered] = useState(false)

  const handleRightClickContextMenu = (e) => {
    if (!contextMenu) return
    const selection = window.getSelection()
    if (selection.toString().length > 1) return
    e.preventDefault()

    const rowRect = e.currentTarget.getBoundingClientRect()
    const x = e.pageX
    const y = rowRect.bottom - 15

    onRightClick(index, { x, y }, item)
  }

  const handleTwoFingerTouchContextMenu = (e) => {
    if (!contextMenu) return
    if (e.touches.length === 2) {
      e.preventDefault()

      const rowRect = e.currentTarget.getBoundingClientRect()
      const touchMidX = (e.touches[0].pageX + e.touches[1].pageX) / 2

      const x = touchMidX
      const y = rowRect.bottom - 15

      onRightClick(index, { x, y }, item)
    }
  }

  const visibleHeaders = headers.filter((header) => !header.hidden)
  const totalWidth = visibleHeaders.reduce(
    (acc, header) => acc + header.width,
    0
  )

  return (
    <Row
      key={`row-${item.id}-${index}`}
      id={item.id}
      style={style}
      width={totalWidth}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onContextMenu={handleRightClickContextMenu}
      onTouchStart={handleTwoFingerTouchContextMenu}
    >
      {(isActive && activeRowStyle === 'outline') && <ActiveRowAfter width={totalWidth} />}
      {headers.map((header, cellIndex) => {
        if (header.hidden) return null
        const [cellValue, cellTitle] = Library.formatCellValue(item, header, index, filterQuery, isActive, isHovered)
        return (
          <Cell
            key={`cell-${item.id}-${cellIndex}`}
            title={cellTitle}
            $isLast={isLast}
            $isEven={isEven}
            $isActive={isActive}
            $activeRowStyle={activeRowStyle}
            $isHovered={isHovered}
            $isLastCell={cellIndex === headers.length - 1}
            $isLastRow={index === items.length - 1}
            $previousDateNotEqual={previousDateNotEqual}
            $width={header.width}
            $item={item}
            $header={header}
            $lastModifiedColored={lastModifiedColored}
          >
            <CellValueWrapper>
              {cellValue}
            </CellValueWrapper>
          </Cell>
        )
      })}
    </Row>
  )
}, areEqual)

const HeadersRow = forwardRef(({ headers, headersHeight, style, setActiveIndex, setActiveRowId, setSort, sort }, ref) => {
  const onHeaderClick = () => {
    setActiveIndex(-1)
    setActiveRowId('')
  }

  const visibleHeaders = headers.filter((header) => !header.hidden)
  const totalWidth = visibleHeaders.reduce(
    (acc, header) => acc + header.width,
    0
  )
  return (
    <Headers
      ref={ref}
      onClick={onHeaderClick}
      style={style}
      height={headersHeight}
      width={totalWidth}
    >
      {headers.map(
        (header) =>
          !header.hidden && (
            <Header
              key={header.id}
              title={header.name}
              width={header.width}
              onClick={() => {
                if (header.type === 'increment') return
                setSort(header)
              }}
              $isSortColumn={sort.key === header.id}
            >
              <HeaderText>
                {header.name}
              </HeaderText>
              {sort.key === header.id && (
                <span>
                  {sort.direction ? '↓' : '↑'}
                </span>
              )}
            </Header>
          )
      )}
    </Headers>
  )
})
HeadersRow.displayName = 'HeadersRow'

const innerElementType = forwardRef(({ children, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({ headers, headerRef, headersHeight, setActiveIndex, setActiveRowId, setSort, sort }) => (
      <div ref={ref} {...rest}>
        <HeadersRow
          ref={headerRef}
          headers={headers}
          headersHeight={headersHeight}
          setActiveIndex={setActiveIndex}
          setActiveRowId={setActiveRowId}
          setSort={setSort}
          sort={sort}
          key={'headerRow'}
          id='headerRow'
        />
        {children}
      </div>
    )}
  </StickyListContext.Consumer>
))
innerElementType.displayName = 'InnerElementType'

const StickyList = forwardRef(({ children, headers, headerRef, headersHeight, setActiveIndex, setActiveRowId, setSort, sort, ...rest }, ref) => (
  <StickyListContext.Provider
    value={{ ItemRenderer: children, headers, headerRef, headersHeight, setActiveIndex, setActiveRowId, setSort, sort }}
  >
    <List
      ref={ref}
      itemData={{ ItemRenderer: children }}
      {...rest}
    >
      {ItemWrapper}
    </List>
  </StickyListContext.Provider>
))
StickyList.displayName = 'StickyList'

const VirtualizedList = React.memo(forwardRef((props, refs) => {
  // Ref to track the initial render
  const isInitialRender = useRef(true)
  const { offsetHeight = 0, headersHeight = 60, lineHeight = 30, overflowX = true, overflowY = true, headers, path = 'data', pageName, items, filterQuery, activeRowStyle = 'fill', zebraStriped = false, lastModifiedColored = false, separateDateKey, navigateTo, contextMenu = true, labelConfig = { labelKey: 'date', labelFormat: 'date' } } = props
  const dispatch = useDispatch()
  const router = useNavigate()
  const pageState = useSelector(state => pageName ? state.page[pageName] : null)
  const listWrapperRef = useRef(null)
  const [isScrollMoved, setIsScrollMoved] = useState(false)
  const [listWrapperRefCurrent, setListWrapperRefCurrent] = useState(null)
  const [listRefCurrent, setListRefCurrent] = useState(null)
  const [activeIndex, setActiveIndex] = useState(-1)

  const initialScrollOffset = useMemo(() => {
    if (pageState.activeRowId && listWrapperRefCurrent) {
      const scrollToIndex = items.findIndex(item => item.id === pageState.activeRowId)
      if (scrollToIndex !== -1) {
        // Calculate the top position of the desired row
        const rowTopPosition = scrollToIndex * lineHeight

        // Get the height of the list container
        const listHeight = listWrapperRef.current ? listWrapperRef.current.clientHeight : 0

        // Calculate the center position
        const centerPosition = listHeight / 2

        // Adjust the scroll position to center the row and account for the header
        return Math.max(rowTopPosition - centerPosition + (lineHeight / 2) + headersHeight, 0)
      }
    } else if (items.length > 0) {
      return Math.ceil((items.length - 1) * lineHeight)
    }
    return 0
  }, [listWrapperRefCurrent, pageState.activeRowId, items, headersHeight, lineHeight])

  const setSort = useCallback(
    (header) => {
      const isSameKey = pageState.sort.key === header.id
      const newDirection = isSameKey ? !pageState.sort.direction : true
      dispatch({
        type: 'SET_SORT',
        page: pageName,
        payload: {
          key: header.id,
          direction: newDirection,
          dataType: header.dataType
        }
      })
    },
    [dispatch, pageName, pageState]
  )

  const setActiveRowId = useCallback(
    (id) => {
      dispatch({ type: 'SET_ACTIVE_ROW_ID', page: pageName, payload: id })
    },
    [dispatch, pageName]
  )

  const handleRowClick = useCallback((index) => {
    if (index === undefined) {
      return
    }
    if (index >= 0 && index < items.length) {
      setActiveIndex((prevState) => {
        if (prevState !== index) {
          setActiveRowId(items[index].id)
          return index
        }
        return prevState
      })
    } else {
      console.error('Invalid index:', index)
    }
  }, [items, setActiveIndex, setActiveRowId])

  const onSingleClick = useCallback(
    (index) => {
      const selection = window.getSelection()
      if (index === undefined || index === activeIndex || selection.toString().length > 1) {
        return
      }
      handleRowClick(index)
    }, [handleRowClick, activeIndex])

  const onDoubleClick = useCallback(() => {
    if (activeIndex !== -1) {
      router(`/${navigateTo}/edit/${items[activeIndex].id}`)
    }
  }, [router, navigateTo, items, activeIndex])

  const handleClick = useSingleAndDoubleClick(onSingleClick, onDoubleClick)

  //Right click menu
  const [contextMenuState, setContextMenuState] = useState(null)

  const handleRightClick = (index, position, item) => {
    handleRowClick(index)
    setContextMenuState({ position, item })
  }

  const closeContextMenu = () => {
    setContextMenuState(null)
  }

  const setListWrapperHeight = useCallback(() => {
    const windowHeight = window.innerHeight
    if (listWrapperRef.current) {
      listWrapperRef.current.style.height = `${windowHeight - offsetHeight}px`
    }
  }, [offsetHeight])

  useEffect(() => {
    setListWrapperHeight()
    window.addEventListener('resize', setListWrapperHeight)
    return () => {
      window.removeEventListener('resize', setListWrapperHeight)
    }
  }, [setListWrapperHeight])

  useEffect(() => {
    if (!isScrollMoved && listRefCurrent && pageState.activeRowId) {
      const scrollToIndex = items.findIndex(item => item.id === pageState.activeRowId)
      if (scrollToIndex !== -1) {
        setActiveIndex(scrollToIndex)
        setIsScrollMoved(true)
      }
    }
  }, [listRefCurrent, isScrollMoved, items, pageState.activeRowId, setActiveIndex])

  useEffect(() => {
    if (!listRefCurrent) return

    // Skip this effect on initial render
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    // Use itemsLength state to get the current length
    if (items.length > 0) {
      setTimeout(() => {
        const lastIndex = items.length - 1
        setActiveIndex(-1) // Reset active index
        setActiveRowId('')
        listRefCurrent.scrollToItem(lastIndex, 'center')
      }, 0)
    }
  }, [listRefCurrent, filterQuery, items.length, setActiveRowId, setActiveIndex])

  return (
    <ListWrapper
      ref={(el) => {
        listWrapperRef.current = el
        setListWrapperRefCurrent(el)
      }}>
      <AutoSizer>
        {({ height, width }) => (
          <>
            {listWrapperRef.current && <StickyList
              ref={(el) => {
                refs.listRef.current = el
                setListRefCurrent(el)
              }}
              height={height}
              width={width}
              itemCount={items.length}
              itemSize={lineHeight}
              innerElementType={innerElementType}
              overscanCount={35}
              headerRef={refs.headerRef}
              headersHeight={headersHeight}
              setActiveIndex={setActiveIndex}
              setActiveRowId={setActiveRowId}
              setSort={setSort}
              sort={pageState.sort}
              headers={headers}
              initialScrollOffset={initialScrollOffset}
              style={{ overflowX: overflowX ? 'auto' : 'hidden', overflowY: overflowY ? 'auto' : 'hidden' }}
            >
              {({ index, style }) => (
                <ListRow
                  headers={headers}
                  items={items}
                  index={index}
                  style={{ ...style, top: style.top + headersHeight }}
                  isActive={index === activeIndex}
                  onClick={(e) => {
                    if (e.target.tagName.toLowerCase() === 'a') {
                      return
                    }
                    e.preventDefault()
                    e.stopPropagation()
                    handleClick(index)
                  }}
                  onRightClick={handleRightClick}
                  activeRowStyle={activeRowStyle}
                  zebraStriped={zebraStriped}
                  separateDateKey={separateDateKey}
                  filterQuery={filterQuery}
                  lastModifiedColored={lastModifiedColored}
                  contextMenu={contextMenu}
                />
              )}
            </StickyList>}
          </>
        )}
      </AutoSizer>
      {contextMenu && contextMenuState && (
        <ContextMenu
          listWrapperRef={listWrapperRef}
          position={contextMenuState.position}
          onClose={closeContextMenu}
          item={contextMenuState.item}
          path={path}
          request={pageName}
          label={contextMenuState.item[labelConfig.labelKey]
            ?
            (labelConfig.labelFormat === 'date'
              ?
              moment(contextMenuState.item[labelConfig.labelKey]).format('DD.MM.yyyy')
              :
              contextMenuState.item[labelConfig.labelKey])
            : '-'}
        />
      )}
    </ListWrapper>
  )
}))
VirtualizedList.displayName = 'VirtualizedList'

export default VirtualizedList