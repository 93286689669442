import React from 'react'
import styled from 'styled-components'
import CircleLoader from '../Loaders/CircleLoader'

const TableWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  position: relative;
  overflow: auto;
  flex: 1;
  border: 1px solid var(--surface-color);
  html[data-theme='dark'] & {
    border: 1px solid var(--list-line-color-variant);
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  td {
    border: none;
    padding: 8px 5px;
    text-align: left;
    vertical-align: middle;
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    @media (min-width: 1200px) {
      padding: 10px 20px;
    }
    @media (min-width: 1600px) {
      padding: 12px 30px;
    }
  }

  th {
    border: none;
    padding: 10px 5px;
    text-align: left;
    vertical-align: middle;
    @media (min-width: 1200px) {
      padding: 15px 20px;
    }
    @media (min-width: 1600px) {
      padding: 15px 30px;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1000;
    color: var(--text-inverted-color);
    background: var(--surface-color);
    html[data-theme='dark'] & {
      color: var(--text-color);
      background: var(--background-color);
    }
  }  

  thead,
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--background-tertiary-color);
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;

    th,
    td {
      padding: 8px 4px;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }

  ${props => props.$columnWidths && props.$columnWidths.map((width, index) => `
    th:nth-child(${index + 1}), td:nth-child(${index + 1}) {
      width: ${width};
    }
  `)}
`

export default function TableComponent({ columnWidths, children, tableWidth, isLoading = false }) {
  return (
    <TableWrapper width={tableWidth}>
      {isLoading ?
        <CircleLoader />
        :
        <Table $columnWidths={columnWidths}>
          {children}
        </Table>
      }
    </TableWrapper>
  )
}