import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Label = styled.label`
  position: absolute;
  cursor: text;
  transform-origin: 0% 100%;
  text-align: initial;
  width: 100%;
  line-height: 1rem;
  transition: color 0.2s, font-size 0.2s, top 0.2s, padding-top 0.2s, transform 0.2s${props => props.hasValue ? ', none' : ''};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};

  ${({ $isFocused, $hasValue }) =>
    $isFocused || $hasValue
      ? css`
          top: -26px;
          font-size: .8rem;
          transform: translateY(12px);
        `
      : css`
          top: 15px;
          font-size: .9rem;
          transform: none;
        `}

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 2.9rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  transition: box-shadow .3s, border .3s;
  color: var(--input-text-color);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};

  ${({ $textOverflow }) =>
    $textOverflow &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}

  ${({ $showClearButton }) =>
    $showClearButton &&
    css`
      &:after {
        content: '';
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        width: 24px;
        pointer-events: none;
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
    color: rgba(0,0,0,0.42);
    border-bottom: 1px dotted rgba(0,0,0,0.42);
  `}
`

const Suggestions = styled.ul`
  padding-left: 0;
  color: var(--input-text-color);
  background: var(--background-color);
  border: 1px solid var(--line-color);
  margin: 0;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  z-index: 9999;
  max-height: ${({ $optionsHeight }) => ($optionsHeight)}px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  & li.selected {
    background-color: #e0e0e0;
  }
  ${({ $isSuggestionsOpen, $position }) => `
    top: ${$position === 'up' ? '2px' : '47px'};
    visibility: ${$isSuggestionsOpen ? 'visible' : 'hidden'};
    opacity: ${$isSuggestionsOpen ? '1' : '0'};
    transform: ${$position === 'up' ? ($isSuggestionsOpen ? 'translateY(-100%)' : 'scaleY(1)') : ($isSuggestionsOpen ? 'scaleY(1)' : 'scaleY(1)')};
  `};
`

const Suggestion = styled.li`
  cursor: pointer;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  &:hover {
    background-color: var(--background-tertiary-color);
  }
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--background-tertiary-color)' : 'transparent')};
`

const SuggestionText = styled.div`
  flex-grow: 1;
`

const ClearButtonWrapper = styled.div`
  position: absolute;
  top: 42%;
  right: 2px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: ${({ $darkMode }) => ($darkMode ? '#FFFFFF' : '#d3d3d3')};
  box-sizing: border-box;
  height: 50%;
`


const ClearButton = styled.button`
  -webkit-transition: color 150ms;
  transition: color 150ms;
  background: none;
  border: none;
  padding: 3px 0 0 0;
  font-size: 12px;
  cursor: pointer;

  svg {
    fill: var(--input-element-color);
    transition: fill 0.2s;
  }

  &:hover:not(:disabled) svg {
    fill: var(--input-focused-color);
    filter: brightness(0.8);
    transition: fill 0.2s, filter 0.2s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-disabled);
      cursor: default;
    `
  }
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-right: 3px;
  color: hsl(0, 0%, 80%);
  font-size: 4px;
  line-height: 1;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
}`

export default function Autocomplete(props) {
  const { label = '', type = 'text', name = '', id, value = '', options = [], optionsHeight = 200, forceDarkMode = false, borderOption = 1, textOverflow = true, disabled, readOnly, onChange, onClick, onBlur, onFocus, onKeyDown, debounceDelay, isClearable, ...rest } = props
  const [debouncedHandleChange, isDebouncing] = useDebounce(onChange, debounceDelay)
  const [showClearButton, setShowClearButton] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [internalOptions, setInternalOptions] = useState(options)
  const [inputValue, setInputValue] = useState(value)
  const [hasValue, setHasValue] = useState(false)
  const [prevInputValuesMap, setPrevInputValuesMap] = useState(new Map())
  const [suggestions, setSuggestions] = useState([])
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)
  const inputRef = useRef(null)
  const focusedInputNameRef = useRef(null)
  const suggestionsRef = useRef(null)
  const generateRefs = (length) => Array.from({ length }, () => React.createRef())
  const [suggestionRefs, setSuggestionRefs] = useState(generateRefs(options.length))
  const [dropdownPosition, setDropdownPosition] = useState('down')

  const checkDropdownPosition = useCallback(() => {
    if (suggestionsRef.current) {
      const rect = suggestionsRef.current.getBoundingClientRect()
      const spaceToBottom = window.innerHeight - rect.bottom
      const spaceToTop = rect.top

      if (spaceToBottom < optionsHeight && spaceToTop > optionsHeight) {
        setDropdownPosition('up')
      } else {
        setDropdownPosition('down')
      }
    }
  }, [optionsHeight])

  useEffect(() => {
    if (isDebouncing) {
      setShowClearButton(false)
    } else {
      setShowClearButton(inputValue !== '')
    }
  }, [isDebouncing, inputValue])

  useEffect(() => {
    setInternalOptions(options)
    setSuggestionRefs(generateRefs(options.length))
  }, [options])

  useEffect(() => {
    setHasValue(!!value)
    setInputValue(value || '')
  }, [value])

  useEffect(() => {
    setHasValue(inputValue !== '')
  }, [inputValue])

  const inputHasValue = value !== null && value !== ''
  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')

  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const filterOptions = useCallback((inputValue) => {
    if (inputValue.length > 1) {
      const filtered = internalOptions.filter(
        (option) => option.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
      setSuggestions(filtered)

      const matchingIndex = filtered.findIndex(
        (option) => option.toLowerCase() === inputValue.toLowerCase()
      )

      if (matchingIndex !== -1) {
        setSelectedOptionIndex(matchingIndex)
      } else {
        setSelectedOptionIndex(-1)
      }
    } else {
      setSuggestions([])
      setSelectedOptionIndex(-1)
    }
  }, [internalOptions])

  const [debouncedFilterOptions, filterOptionsIsDebouncing] = useDebounce(filterOptions, 500)

  const handleChange = useCallback((e) => {
    const inputValue = e.target.value
    setInputValue(inputValue)
    if (e.target.value !== value) {
      debouncedFilterOptions(inputValue)
      if (typeof onChange === 'function') {
        if (debounceDelay) {
          debouncedHandleChange(e)
        } else {
          onChange(e)
        }
      }
    }
    if (!isSuggestionsOpen) {
      checkDropdownPosition()
      setIsSuggestionsOpen(true)
    }
  }, [onChange, debouncedHandleChange, isSuggestionsOpen, checkDropdownPosition, debounceDelay, debouncedFilterOptions, value])

  const handleClick = useCallback((e) => {
    const inputValue = e.target.value
    setInputValue(inputValue)
    if (!inputValue || internalOptions.length === 0) {
      return
    }
    if (inputValue !== '') {
      filterOptions(inputValue)
    }
    if (!isSuggestionsOpen) {
      checkDropdownPosition()
      setIsSuggestionsOpen(true)
    }
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick, internalOptions, isSuggestionsOpen, checkDropdownPosition, filterOptions])

  const handleOptionClick = useCallback(
    (option) => {
      if (option !== inputValue) {
        setInputValue(option)
        setHasValue(true)
        setSuggestions([])
        setSelectedOptionIndex(-1)
        const fakeEvent = {
          target: {
            name,
            type,
            value: option,
          },
        }
        if (typeof onChange === 'function') {
          onChange(fakeEvent)
        }
      }
    },
    [onChange, inputValue, name, type]
  )

  const handleBlur = useCallback((e) => {
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
    setTimeout(() => {
      setIsFocused(false)
      setSuggestions([])
      setIsSuggestionsOpen(false)
    }, 150)
  }, [onBlur])

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    focusedInputNameRef.current = name
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus, name])

  const handleClearValue = useCallback((e) => {
    e.preventDefault()
    if (!readOnly) {
      const currentValue = inputRef.current.value
      setPrevInputValuesMap((prevState) => {
        const newMap = new Map(prevState)
        if (!newMap.has(name)) {
          newMap.set(name, [])
        }
        newMap.get(name).push(currentValue)
        return newMap
      })
      if (inputRef.current) {
        inputRef.current.value = ''
        setInputValue('')
        const event = new Event('input', { bubbles: true, cancelable: true })
        inputRef.current.dispatchEvent(event)
      }
      if (typeof onChange === 'function') {
        const fakeEvent = {
          target: {
            name,
            type,
            value: '',
          },
        }
        onChange(fakeEvent)
      }
    }
  }, [onChange, readOnly, name, type])

  const inputClearedRef = useRef(false)
  const handleKeyDown = useCallback(
    (e) => {
      if (!isFocused || e.target !== inputRef.current || readOnly) {
        return
      }
      //=====DELETE=====//
      else if (e.key === 'Delete' && inputRef.current.value !== '') {
        setPrevInputValuesMap((prevState) => {
          const newMap = new Map(prevState)
          if (!newMap.has(name)) {
            newMap.set(name, [])
          }
          if (inputRef.current.value) {
            newMap.get(name).push(inputRef.current.value)
          }
          return newMap
        })
        setInputValue('')
        setSuggestions([])
        if (typeof onChange === 'function') {
          const fakeEvent = {
            target: {
              name,
              value: '',
              type: 'text',
            },
          }
          onChange(fakeEvent)
        }
      }
      //=====UP/DOWN=====//
      else if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault()
        const direction = e.key === 'ArrowUp' ? -1 : 1
        setSelectedOptionIndex((prevIndex) => {
          const newIndex = (prevIndex + direction + suggestions.length) % suggestions.length
          const selectedElement = suggestionRefs[newIndex]?.current
          if (selectedElement) {
            selectedElement.scrollIntoView({ block: 'nearest' })
          }
          return newIndex
        })
      }
      //=====ENTER=====//
      else if (e.key === 'Enter') {
        e.preventDefault()
        if (selectedOptionIndex !== -1) {
          handleOptionClick(suggestions[selectedOptionIndex])
          setSelectedOptionIndex(-1)
          setSuggestions([])
          setIsSuggestionsOpen(!isSuggestionsOpen)
          checkDropdownPosition()
        } else {
          setIsSuggestionsOpen(!isSuggestionsOpen)
          checkDropdownPosition()
          filterOptions(inputValue || '')
        }
      }
      //=====BACKSPACE=====//
      else if (e.key === 'Backspace') {
        if (inputRef.current.value === '') {
          setInputValue('')
          setSuggestions([])
          if (!inputClearedRef.current && typeof onChange === 'function') {
            inputClearedRef.current = true
            const fakeEvent = {
              target: {
                name,
                value: '',
                type: 'text',
              },
            }
            onChange(fakeEvent)
          }
        } else {
          inputClearedRef.current = false
        }
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    },
    [
      isFocused,
      handleOptionClick,
      onKeyDown,
      selectedOptionIndex,
      readOnly,
      onChange,
      suggestions,
      inputValue,
      isSuggestionsOpen,
      checkDropdownPosition,
      filterOptions,
      name,
      suggestionRefs
    ]
  )

  useEffect(() => {
    const handleUndo = (e) => {
      if (!isFocused) {
        return
      }
      if (
        e.ctrlKey &&
        e.code === 'KeyZ' &&
        document.activeElement === inputRef.current &&
        prevInputValuesMap.has(focusedInputNameRef.current)
      ) {
        if (e.target === inputRef.current) {
          const inputPrevValues = prevInputValuesMap.get(focusedInputNameRef.current)
          const restoredValue = inputPrevValues.pop()
          if (restoredValue !== undefined) {
            setInputValue(restoredValue)
            setPrevInputValuesMap((prevState) => {
              const newMap = new Map(prevState)
              const prevInputValues = newMap.get(focusedInputNameRef.current)
              prevInputValues.pop()
              if (prevInputValues.length === 0) {
                newMap.delete(focusedInputNameRef.current)
              } else {
                newMap.set(focusedInputNameRef.current, prevInputValues)
              }
              return newMap
            })
            const fakeEvent = {
              target: {
                name: focusedInputNameRef.current,
                value: restoredValue,
                type: type || 'text',
              },
            }
            if (typeof onChange === 'function') {
              onChange(fakeEvent)
            }
          }
        }
      }
    }

    document.addEventListener('keydown', handleUndo)
    return () => {
      document.removeEventListener('keydown', handleUndo)
    }
  }, [prevInputValuesMap, isFocused, onChange, type])

  return (
    <AutocompleteWrapper>
      <Label
        htmlFor={id}
        disabled={disabled}
        title={label}
        $isFocused={isFocused}
        $hasValue={hasValue || inputHasValue}
        $forceDarkMode={forceDarkMode}
      >
        {label}
      </Label>
      {(isDebouncing || filterOptionsIsDebouncing) &&
        <LoadingIndicator>
          <span></span>
          <span></span>
          <span></span>
        </LoadingIndicator>}
      <Input
        ref={inputRef}
        type={type}
        value={inputValue || ''}
        name={name}
        id={id}
        autoComplete='off'
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        onClick={handleClick}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $textOverflow={textOverflow}
        {...rest}
      />
      <Suggestions $isSuggestionsOpen={isSuggestionsOpen} $position={dropdownPosition} $optionsHeight={optionsHeight} ref={suggestionsRef}>
        {suggestions.map((option, index) => (
          <Suggestion
            ref={suggestionRefs[index]}
            key={index}
            onClick={() => handleOptionClick(option)}
            $isSelected={selectedOptionIndex === index}
          >
            <SuggestionText>
              {option.split(new RegExp(`(${inputValue})`, 'gi')).map((part, index) =>
                part.toLowerCase() === inputValue.toLowerCase() ? (
                  <span key={index} style={{ color: 'var(--input-focused-color)' }}>
                    {part}
                  </span>
                ) : (
                  <React.Fragment key={index}>{part}</React.Fragment>
                )
              )}
            </SuggestionText>
          </Suggestion>
        ))}
      </Suggestions>
      <ClearButtonWrapper $forceDarkMode={forceDarkMode}>
        <>
          {!disabled && isClearable && showClearButton &&
            <>
              <ClearButton
                disabled={disabled}
                onClick={handleClearValue}
                $forceDarkMode={forceDarkMode}
                $isFocused={isFocused}
              >
                <svg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'><path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path></svg>
              </ClearButton>
            </>}
        </>
      </ClearButtonWrapper>
    </AutocompleteWrapper>
  )
}

