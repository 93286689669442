import React from 'react'
import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
`
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
  opacity: 1;
  visibility: visible;
`
const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
`

const Spinner = styled.circle`
  fill: transparent;
  stroke: var(--main-color);
  stroke-width: 7px;
  stroke-linecap: round;
  filter: url(#shadow);
  transform-origin: center;
  animation: ${animation} 1.2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
`

const FullscreenLoadingSpinner = () => {
  return (
    <Wrapper>
      <Container>
        <svg viewBox='0 0 100 100'>
          <defs>
            <filter id='shadow'>
              <feDropShadow dx='0' dy='0' stdDeviation='1.5' floodColor='var(--main-color-lighten)' />
            </filter>
          </defs>
          <Spinner id='spinner' cx='50' cy='50' r='45' />
        </svg>
      </Container>
    </Wrapper>
  )
}

export default FullscreenLoadingSpinner