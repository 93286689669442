import React, { useContext, useState } from 'react'
import PostService from '../../../API/PostService'
import { useMessage } from '../../../hooks/useMessage'
import Button from '../../../components/Buttons/Button'
import styled from 'styled-components'
import ModalContext from '../../../context/ModalContext'
import Library from '../../../Library'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 480px) {
    width: 100%;
  }
  @media (min-width: 480px) {
    min-width: 280px;
    max-width: 360px;
  }
`

const LabelWrapper = styled.div`
  font-size: 20px;
  color: var(--text-tertiary-color);
  white-space: normal;
  overflow-wrap: break-word;
`

const Email = styled.span`
  color: var(--main-color);
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`

export default function DeleteUser({ currUser }) {
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const { language } = useLanguage()
  const translate = useTranslate()
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

  function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str
  }

  return (
    <Wrapper>
      <LabelWrapper>{translate('delete')}&nbsp;<Email>{truncate(currUser.email, 25)}</Email>?</LabelWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            minWidth: '140px',
          }}
          onClick={() => closeModal()}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{
            minWidth: '140px',
            backgroundColor: '#E57373',
          }}
          disabled={deleteButtonDisabled}
          onClick={async function (e) {
            e.preventDefault()
            setDeleteButtonDisabled(true)
            try {
              const response = await PostService.delete('auth', 'users', { id: currUser.id }, language)
              const data = response.data
              const status = response.status

              // Handling based on status
              if ([201, 404, 500].includes(status)) {
                message(data.message, Library.getStatusType(status))
              } else {
                message(data.message, 'error')
              }

              // Re-enable the delete button for non-successful cases
              if (status !== 201) {
                setTimeout(() => {
                  setDeleteButtonDisabled(false)
                }, 500)
              } else {
                closeModal()
              }
            } catch (error) {
              const serverMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message

              message(serverMessage, 'error')
              setTimeout(() => {
                setDeleteButtonDisabled(false)
              }, 500)
            }

          }}
        >
          {translate('delete')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}