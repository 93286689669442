import { useContext, useMemo } from 'react'
import { LanguageContext } from '../context/LanguageContext'
import translations from '../components/translations'

const useTranslate = () => {
  const { language } = useContext(LanguageContext)

  const translate = useMemo(() => {
    return (key) => {
      return translations[language][key] || key
    }
  }, [language])

  return translate
}

export default useTranslate
