import React, { forwardRef, useCallback, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { lighten } from 'polished'
import { useSoundTheme } from '../../hooks/useSoundTheme'

const rippleEffect = keyframes`
  to {
    transform: scale(75);
    opacity: 0;
  }
`

const RippleWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
`

const Ripple = styled.span`
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ${({ $animationDuration }) => css`${rippleEffect} ${$animationDuration} linear`};
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
  z-index: 0;
  top: ${({ y, size }) => y - size / 2}px;
  left: ${({ x, size }) => x - size / 2}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`

const StyledButton = styled.button`
  position: relative;
  box-sizing: border-box;
  overflow: visible;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  letter-spacing: .5px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  font-size: 14px;
  outline: 0;
  height: 36px;
  line-height: 36px;
  border: none;
  padding: 0 16px;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  vertical-align: middle;
  color: var(--button-text-color);
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.2);
  background-color: var(--button-color);

  &:hover:not(:disabled), &:active:not(:disabled) {
    background-color: ${props => getHoverColor(props)} !important;
  }

  &:disabled {
    opacity: 0.9 !important;
    cursor: auto !important;
    box-shadow: none !important;
    color: grey !important;
    background-color: #dfdfdf !important;
  }

  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
`

// Function to lighten the color on hover
function getHoverColor(props) {
  let colorToLighten

  if (props.style && props.style.backgroundColor) {
    // Fetch the actual color value of the CSS variable provided in props
    const buttonElement = document.createElement('button')
    buttonElement.style.setProperty('--temp-color', props.style.backgroundColor)
    document.body.appendChild(buttonElement)
    colorToLighten = getComputedStyle(buttonElement).getPropertyValue('--temp-color').trim()
    document.body.removeChild(buttonElement)

    // Check if colorToLighten is a valid color string
    if (/^#|rgb|hsl/.test(colorToLighten)) {
      return lighten(0.1, colorToLighten)
    } else {
      // Fallback if colorToLighten is not a valid color
      return 'var(--button-color-lighten)'
    }
  } else {
    return 'var(--button-color-lighten)'
  }
}

const Button = forwardRef((props, ref) => {
  const { style, id, name, onClick, disabled = false, title, waves = true } = props

  const { buttonSound } = useSoundTheme()

  const [ripples, setRipples] = useState(waves ? [] : [])

  const handleMouseDown = useCallback(
    (e) => {
      const button = e.currentTarget
      const buttonRect = button.getBoundingClientRect()
      const x = e.clientX - buttonRect.left
      const y = e.clientY - buttonRect.top

      const ripple = {
        id: Date.now(),
        x: x - buttonRect.width / 100,
        y: y - buttonRect.height / 30,
        size: Math.min(buttonRect.width, buttonRect.height),
        animationDuration: '3500ms',
      }

      setRipples((prevRipples) => [...prevRipples, ripple])
    }, [])

  const handleMouseUp = useCallback(() => {
    // Do nothing
  }, [])

  const handleAnimationEnd = useCallback(
    (rippleId) => {
      setRipples((prevRipples) => {
        if (Array.isArray(prevRipples)) {
          return prevRipples.filter((ripple) => ripple.id !== rippleId)
        } else {
          return null
        }
      })
    }, [])

  const handleClick = useCallback((e) => {
    buttonSound()
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick, buttonSound])

  return (
    <StyledButton
      ref={ref}
      style={style}
      name={name}
      id={id}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onClick={handleClick}
      disabled={disabled}
      title={title}
    >
      {props.children}
      {waves && ripples.map((ripple) => (
        <RippleWrapper key={`ripple-wrapper-${ripple.id}`}>
          <Ripple
            key={`ripple-${ripple.id}`}
            onAnimationEnd={() => handleAnimationEnd(ripple.id)}
            style={{
              top: ripple.y - ripple.size / 2,
              left: ripple.x - ripple.size / 2,
              width: ripple.size,
              height: ripple.size,
            }}
            $animationDuration={ripple.animationDuration}
          />
        </RippleWrapper>
      ))}
    </StyledButton>
  )
})

export default Button