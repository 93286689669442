import React, { useCallback, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { lighten } from 'polished'

const InputField = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  cursor: pointer;
`

const Button = styled.button`
  text-decoration: none;
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  font-size: 14px;
  outline: 0;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  background-color: ${({ $backgroundColor }) => $backgroundColor ? $backgroundColor : 'var(--button-color)'};

  ${({ $isHovering, $backgroundColor, disabled }) =>
    !disabled && $isHovering &&
    css`
      background-color: ${$backgroundColor ? lighten(0.1, $backgroundColor) : 'var(--button-color-lighten)'} !important;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.3);
    `}

  &:active:not(:disabled) {
    background-color: ${({ $backgroundColor }) => $backgroundColor ? lighten(0.1, $backgroundColor) : 'var(--button-color-lighten)'} !important;
  }

  &:disabled {
    opacity: 0.9 !important;
    cursor: auto !important;
    box-shadow: none !important;
    color: grey !important;
    background-color: #dfdfdf !important;
  }

  svg {
    margin-top: auto;
    margin-bottom: auto;
  }
`

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  pointer-events: none;
`

const FilePathWrapper = styled.div`
  display: flex;
  overflow: hidden;
  margin-left: 10px;
  padding-top: 2px;
  flex-grow: 1;
  cursor: text;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
  cursor: pointer;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
        border-bottom: ${$borderHeight} solid var(--input-focused-color);
        ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
        border-bottom: ${$borderHeight} solid var(--input-element-color);
        box-shadow: none;
      `}
      ${({ disabled }) =>
    disabled &&
    css`
    color: var(--text-color-disabled);
    border-bottom: 1px dotted var(--border-color-disabled);
  `}
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${({ disabled }) => disabled ? 'var(--text-color-disabled)' : 'var(--input-text-color)'} !important;
  }
  ::placeholder {
    color: var(--input-element-color);
  }
`

const FilePath = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding-top: 5px;
  color: var(--input-text-color);
`

const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  color: ${({ color }) => color || 'hsl(0, 0%, 80%)'};
  font-size: 4px;
  line-height: 1;
  margin-right: 4px;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }
`

export default function FileInput(props) {
  const {
    filesRef,
    multiple = false,
    placeholder = 'Загрузите один или несколько файлов',
    buttonText = 'File',
    accept = '*/*',
    backgroundColor,
    disabled = false,
    borderOption = 1,
    onFocus,
    onBlur,
    isLoading,
  } = props

  const [fileNames, setFileNames] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleFilePathClick = () => {
    if (filesRef && filesRef.current) {
      filesRef.current.click()
    }
  }

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files)
    const fileNameList = fileList.map((file) => file.name).join(', ')
    setFileNames(fileNameList)
  }

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus])

  const handleBlur = useCallback((e) => {
    setIsFocused(false)
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
  }, [onBlur])

  const handleMouseEnter = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsFocused(false)
  }, [])

  useEffect(() => {
    const currentRef = filesRef.current
    if (currentRef) {
      currentRef.addEventListener('change', handleFileChange)
      return () => {
        currentRef.removeEventListener('change', handleFileChange)
      }
    }
  }, [filesRef])

  return (
    <InputField>
      <Button
        $backgroundColor={backgroundColor}
        $isHovering={isFocused}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleFilePathClick}
      >
        <span>{buttonText}</span>
        <Input
          ref={filesRef}
          type='file'
          accept={accept}
          multiple={multiple}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Button>
      <FilePathWrapper
        disabled={disabled}
        $isFocused={isFocused}
        $focusedBoxShadow={focusedBoxShadow}
        $borderHeight={borderHeight}
      >
        <FilePath
          type='text'
          placeholder={placeholder}
          value={fileNames}
          readOnly
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleFilePathClick}
        />
        {isLoading ?
          <LoaderWrapper>
            <LoadingIndicator>
              {Array.from({ length: 5 }).map((_, index) => (
                <span key={index} style={{ animationDelay: `${index * 80}ms` }}></span>
              ))}
            </LoadingIndicator>
          </LoaderWrapper>
          :
          false
        }
      </FilePathWrapper>
    </InputField>
  )
}