import { useEffect, useRef } from 'react'

const useResizeObserver = (callback, delay) => {
  const observerRef = useRef(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      let throttleTimeout

      const handleResize = () => {
        if (throttleTimeout) {
          clearTimeout(throttleTimeout)
        }

        throttleTimeout = setTimeout(() => {
          callback(entries)
        }, delay)
      }

      handleResize()
    })

    observerRef.current = resizeObserver

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [callback, delay])

  return observerRef
}

export default useResizeObserver
