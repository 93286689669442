import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFetching } from '../hooks/useFetching'
import PostService from '../API/PostService'
import Button from '../components/Buttons/Button'
import TextInput from '../components/Inputs/TextInput'
import { useMessage } from '../hooks/useMessage'
import { useDispatch } from 'react-redux'
import { loginUser } from '../store/actions/authActions'
import { useAuth } from '../hooks/useAuth'
import { useLanguage } from '../context/LanguageContext'
import Library from '../Library'
import FullscreenLoadingSpinner from '../components/Loaders/FullscreenLoadingSpinner'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(200px, 1216px) 1fr;
  place-items: center;
  height: 100vh;
  width: 100%;
`

const AuthWrapper = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  overflow-y: auto;
  margin: 16px;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 1px) and (max-width: 480px) {
    width: 96%;
    margin: 6px 0 6px 0;
  }

  @media screen and (min-width: 480px) and (max-width: 768px) {
    width: 75%;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 55%;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 45%;
  }

  @media screen and (min-width: 1200px) {
    width: 40%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const AuthCenter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const Card = styled.div`
  background-color: var(--surface-color);
  border-radius: 2px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  width: 100%;
`

const CardContent = styled.div`
  padding: 20px;
  border-radius: 0 0 2px 2px;
`

const CardTitle = styled.h2`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 24px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
  color: var(--title-color);
`

const CardAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
  gap: 10px;
`

const InputField = styled.div`
  position: relative;
  margin-bottom: ${props => props.$marginBottom || 30}px;
`

export const Auth = () => {
  const { userSettings } = useAuth()
  const message = useMessage()
  const dispatch = useDispatch()
  const { language } = useLanguage()
  const [showSpinner, setShowSpinner] = useState(false)
  const [needsCode, setNeedsCode] = useState(false)
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: '',
  })

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const [fetchLogin, isLoginLoading, loginError] = useFetching(async () => {
    try {
      const response = await PostService.post('auth', 'login', form, language)

      Library.handleResponseStatus(response, message)

      if (response.data && response.data.code === 'verification_required') {
        setNeedsCode(true)
        return
      }

      const { status } = response

      if (status === 200) {
        dispatch(loginUser(
          response.data.id,
          response.data.email,
          response.data.username,
          response.data.telegramId,
          response.data.telegramNotifications,
          response.data.roles,
          response.data.twoStepVerification,
          response.data.settings || userSettings,
        ))
        if (response.data.settings && response.data.settings.sound.globalToggle && response.data.settings.sound.soundTheme.login.enabled) {
          const soundPath = response.data.settings.sound.soundTheme['login'].soundFile
          const audio = new Audio(`${window.location.origin}/sounds/${soundPath}`)
          audio.addEventListener('error', (e) => {
            console.error('Error playing sound:', e)
          })
          audio.volume = response.data.settings.sound.soundTheme['login'].volume
          audio.play().catch((error) => {
            console.error('Failed to play sound:', error)
          })
        }
      }

      loginError && message(loginError.message, 'error')
    } catch (error) {
      console.error(error)
    }
  })

  const [fetchVerify, isVerifyLoading, verifyError] = useFetching(async () => {
    try {
      const response = await PostService.post('auth', 'user-verify', { ...form, code }, language)

      Library.handleResponseStatus(response, message)

      if (response.data && response.data.code === 'verification_successful') {
        dispatch(loginUser(
          response.data.id,
          response.data.email,
          response.data.username,
          response.data.telegramId,
          response.data.telegramNotifications,
          response.data.roles,
          response.data.twoStepVerification,
          response.data.settings || userSettings,
        ))
        if (response.data.settings && response.data.settings.sound.globalToggle && response.data.settings.sound.soundTheme.login.enabled) {
          const soundPath = response.data.settings.sound.soundTheme['login'].soundFile
          const audio = new Audio(`${window.location.origin}/sounds/${soundPath}`)
          audio.addEventListener('error', (e) => {
            console.error('Error playing sound:', e)
          })
          audio.volume = response.data.settings.sound.soundTheme['login'].volume
          audio.play().catch((error) => {
            console.error('Failed to play sound:', error)
          })
        }
      }

      verifyError && message(verifyError.message, 'error')
    } catch (error) {
      console.error(error)
    }
  })

  useEffect(() => {
    const isLoading = isLoginLoading || isVerifyLoading
    setLoading(isLoading)

    let timeoutId

    if (isLoading) {
      timeoutId = setTimeout(() => {
        setShowSpinner(true)
      }, 1000)
    } else {
      clearTimeout(timeoutId)
      setShowSpinner(false)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoginLoading, isVerifyLoading])

  return (
    <Wrapper>
      <AuthWrapper>
        {showSpinner && <FullscreenLoadingSpinner />}
        <Row>
          <AuthCenter>
            <Card>
              <CardContent>
                <CardTitle>Авторизация</CardTitle>
                <InputField>
                  <TextInput
                    label='Email'
                    id='email'
                    name='email'
                    type='email'
                    value={form.email}
                    borderOption={2}
                    isClearable={false}
                    forceDarkMode={true}
                    autoComplete='email'
                    onChange={(e) => { changeHandler(e) }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        fetchLogin()
                      }
                    }}
                    disabled={loading}
                  />
                </InputField>
                <InputField>
                  <TextInput
                    label='Пароль'
                    id='password'
                    name='password'
                    type='password'
                    value={form.password}
                    borderOption={2}
                    isClearable={false}
                    forceDarkMode={true}
                    autoComplete='current-password'
                    onChange={(e) => { changeHandler(e) }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        fetchLogin()
                      }
                    }}
                    disabled={loading}
                  />
                </InputField>
                {needsCode &&
                  <InputField $marginBottom={20}>
                    <TextInput
                      label='Код подтверждения'
                      id='code'
                      name='code'
                      type='text'
                      borderOption={2}
                      isClearable={false}
                      forceDarkMode={true}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          fetchVerify()
                        }
                      }}
                      disabled={loading}
                    />
                  </InputField>}
              </CardContent>
              <CardAction>
                <Button
                  disabled={loading}
                  onClick={needsCode ? fetchVerify : fetchLogin}
                >
                  Войти
                </Button>
              </CardAction>
            </Card>
          </AuthCenter>
        </Row>
      </AuthWrapper >
    </Wrapper>
  )
}