const defaultState = {
  mainUserId: '01174ce2-a38e-4eec-880f-f9875a009974',
  roles: [
    { label: 'Админ', value: 'admin' },
    { label: 'Входное приветствие', value: 'welcome' },

    // logistics
    { label: 'Реестр маршрутов-Логистика', companyId: 'logistics', value: 'routes' },
    { label: 'Зарплата-Логистика', companyId: 'logistics', value: 'salary' },
    { label: 'Сотрудники-Логистика', companyId: 'logistics', value: 'employees' },
    { label: 'Заказчики-Логистика', companyId: 'logistics', value: 'customers' },
    { label: 'Автомобили-Логистика', companyId: 'logistics', value: 'cars' },
    { label: 'Наемный транспорт-Логистика', companyId: 'logistics', value: 'hired-cars' },
    { label: 'Наемные водители-Логистика', companyId: 'logistics', value: 'hired-drivers' },
    { label: 'Перевозчики-Логистика', companyId: 'logistics', value: 'carriers' },
    { label: 'Поставщики-Логистика', companyId: 'logistics', value: 'suppliers' },
    { label: 'Топливо-Логистика', companyId: 'logistics', value: 'refueling' },
    { label: 'Прибыль-Логистика', companyId: 'logistics', value: 'profit' },
    { label: 'Расходы-Логистика', companyId: 'logistics', value: 'expenses' },
    { label: 'Номенклатура-Логистика', companyId: 'logistics', value: 'inventory' },
    { label: 'Склад-Логистика', companyId: 'logistics', value: 'stock' },
    { label: 'Т.О. Ремонт-Логистика', companyId: 'logistics', value: 'maintenance' },
    { label: 'Файлы-Логистика', companyId: 'logistics', value: 'files' },
    { label: 'Удаление записей-Логистика', companyId: 'logistics', value: 'delete' },

    // leaderSpecTrans
    { label: 'Реестр маршрутов-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'routes' },
    { label: 'Зарплата-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'salary' },
    { label: 'Сотрудники-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'employees' },
    { label: 'Заказчики-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'customers' },
    { label: 'Автомобили-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'cars' },
    { label: 'Наемный транспорт-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'hired-cars' },
    { label: 'Наемные водители-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'hired-drivers' },
    { label: 'Перевозчики-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'carriers' },
    { label: 'Поставщики-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'suppliers' },
    { label: 'Топливо-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'refueling' },
    { label: 'Прибыль-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'profit' },
    { label: 'Расходы-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'expenses' },
    { label: 'Номенклатура-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'inventory' },
    { label: 'Склад-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'stock' },
    { label: 'Т.О. Ремонт-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'maintenance' },
    { label: 'Файлы-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'files' },
    { label: 'Удаление записей-ЛидерСпецТранс', companyId: 'leaderSpecTrans', value: 'delete' },

    // ipKarpov
    { label: 'Реестр маршрутов-ИП Карпов', companyId: 'ipKarpov', value: 'routes' },
    { label: 'Зарплата-ИП Карпов', companyId: 'ipKarpov', value: 'salary' },
    { label: 'Сотрудники-ИП Карпов', companyId: 'ipKarpov', value: 'employees' },
    { label: 'Заказчики-ИП Карпов', companyId: 'ipKarpov', value: 'customers' },
    { label: 'Автомобили-ИП Карпов', companyId: 'ipKarpov', value: 'cars' },
    { label: 'Наемный транспорт-ИП Карпов', companyId: 'ipKarpov', value: 'hired-cars' },
    { label: 'Наемные водители-ИП Карпов', companyId: 'ipKarpov', value: 'hired-drivers' },
    { label: 'Перевозчики-ИП Карпов', companyId: 'ipKarpov', value: 'carriers' },
    { label: 'Поставщики-ИП Карпов', companyId: 'ipKarpov', value: 'suppliers' },
    { label: 'Топливо-ИП Карпов', companyId: 'ipKarpov', value: 'refueling' },
    { label: 'Прибыль-ИП Карпов', companyId: 'ipKarpov', value: 'profit' },
    { label: 'Расходы-ИП Карпов', companyId: 'ipKarpov', value: 'expenses' },
    { label: 'Номенклатура-ИП Карпов', companyId: 'ipKarpov', value: 'inventory' },
    { label: 'Склад-ИП Карпов', companyId: 'ipKarpov', value: 'stock' },
    { label: 'Т.О. Ремонт-ИП Карпов', companyId: 'ipKarpov', value: 'maintenance' },
    { label: 'Файлы-ИП Карпов', companyId: 'ipKarpov', value: 'files' },
    { label: 'Удаление записей-ИП Карпов', companyId: 'ipKarpov', value: 'delete' },
  ],
  activeTabId: '',
  defaultMapCenter: [59.9343, 30.3351], //Saint-Petersburg
}

export const mainReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'ROLES':
      return state.roles
    case 'MAIN_USER':
      return state.mainUser
    case 'SET_ACTIVE_TAB_ID':
      return { ...state, activeTabId: action.payload }
    default:
      return state
  }
}