import { useState } from 'react'

export const useFetching = (callback) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [loaded, setLoaded] = useState(false)

  const fetching = async (...args) => {
    try {
      setLoading(true)
      await callback(...args)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }

  return [fetching, loading, error, loaded]
}
