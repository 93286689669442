import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import Footer from '../../components/Footer'
import VirtualizedList from './components/VirtualizedList/VirtualizedList'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import Button from '../../components/Buttons/Button'
import ListScrollAllSides from '../../components/ListScrollAllSides'
import { TotalRouteCost } from './components/TotalRouteCost'
import { Debt } from './components/Debt'
import ResultInput from '../../components/Inputs/ResultInput'
import ModalContext from '../../context/ModalContext'
import { RoutesChart } from './components/RoutesChart'
import BarChartIcon from '../../components/Icons/BarChartIcon'
import useTranslate from '../../hooks/useTranslate'
import styled from 'styled-components'
import FilterButton from './components/ListFilter/components/FilterButton'

const ResultInputWrapper = styled.div`
  margin-left: 10px;
  min-width: 115px;
  max-width: 115px;
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const translate = useTranslate()
  const router = useNavigate()
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalWeight, setTotalWeight] = useState(0)
  const [totalHiredDebt, setTotalHiredDebt] = useState(0)
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [activeIndex, setActiveIndex] = useState(-1)
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const { cars, hiredCars, hiredDrivers, employees, customers, carriers, routes, loading, error } = useSelector(state => state.data)
  const drivers = useMemo(() => employees.filter(employee => employee.isDriver), [employees])
  const [dataReady, setDataReady] = useState(false)
  const [routesReplaced, setRoutesReplaced] = useState([])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [routes, mainState])

  const filteredRoutes = useMemo(() => {
    const { search, status, overdueDebt, paymentStatus, paymentType, transportCategory, startDate, endDate } = filterQuery

    const matchesSearch = (route) =>
      !search || Object.values(route)
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())

    const matchesStatus = (item) =>
      !status || item.status === status

    const matchesPaymentType = (route) =>
      !paymentType || route.paymentType === paymentType

    const matchesPaymentStatus = (route) => {
      if (paymentStatus === 'Не оплачен') {
        return ['', 'Не оплачен', 'Завис'].includes(route.paymentStatus)
      }
      return !paymentStatus || route.paymentStatus === paymentStatus
    }

    const matchesTransportCategory = (route) => {
      if (!transportCategory) return true
      if (transportCategory === 'hired') return route.isHired === 1
      if (transportCategory === 'own') return route.isHired === 0
      return true
    }

    const matchesStartDate = (route) =>
      !startDate || moment(route.dateOfDeparture).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (route) =>
      !endDate || moment(route.dateOfDeparture).isSameOrBefore(endDate, 'day')

    const matchesOverdueDebt = (route) => {
      if (!overdueDebt) return true
      const dueDate = moment(route.dueDate)
      return dueDate.isBefore(moment(), 'day') && route.paymentStatus !== 'Оплачен'
    }

    const result = routesReplaced.filter(
      (route) =>
        matchesSearch(route) &&
        matchesStatus(route) &&
        matchesPaymentType(route) &&
        matchesPaymentStatus(route) &&
        matchesTransportCategory(route) &&
        matchesStartDate(route) &&
        matchesEndDate(route) &&
        matchesOverdueDebt(route)
    )

    pageState.sort.key && Library.sort(result, pageState.sort)

    setActiveIndex(-1)
    return result
  }, [routesReplaced, filterQuery, pageState.sort])

  useEffect(() => {
    let tmp_01 = 0
    let tmp_02 = 0
    let tmp_03 = 0
    filteredRoutes.forEach((item) => {
      tmp_01 += item.routeCost || 0
      tmp_02 += item.weight || 0
      tmp_03 += item.debt || 0
    })
    setTotalAmount(tmp_01)
    setTotalWeight(tmp_02)
    setTotalHiredDebt(tmp_03)
  }, [filteredRoutes])

  //======NEW ENTRY======//
  const newEntry = useCallback(() => {
    router(`/${pageName}/new`)
  }, [router, pageName])

  useEffect(() => {
    if (!isDataLoaded || loading) return
    setDataReady(true)
  }, [isDataLoaded, loading])

  useEffect(() => {
    if (!dataReady || loading || error) {
      return
    }
    let replaced = []
    routes.forEach((originalItem) => {
      let item = { ...originalItem }
      Object.keys(item).forEach((key) => {
        switch (key) {
          case 'driver': {
            item[key] = Library.findNameByKey(drivers, item.driver)
            break
          }
          case 'car': {
            item[key] = Library.findNameByKey(cars, item.car)
            break
          }
          case 'hiredCar': {
            item[key] = Library.findNameByKey(hiredCars, item.hiredCar)
            break
          }
          case 'hiredDriver': {
            item[key] = Library.findNameByKey(hiredDrivers, item.hiredDriver)
            break
          }
          case 'customer': {
            item[key] = Library.findNameByKey(customers, item.customer)
            break
          }
          case 'carrier': {
            item[key] = Library.findNameByKey(carriers, item.carrier)
            break
          }
          default: { }
        }
      })
      replaced.push(item)
    })
    setRoutesReplaced(replaced)
  }, [loading, error, dataReady, routes, carriers, cars, hiredCars, hiredDrivers, customers, drivers])

  return (
    <>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {dataReady && isDataLoaded &&
        <>
          <VirtualizedList
            ref={virtualizedListRefs}
            pageName={pageName}
            pageState={pageState}
            items={filteredRoutes}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            filterQuery={filterQuery}
            navigateTo={pageName}
            separateDateKey={pageState.sort.dataType === 'date' && pageState.sort.key}
          />
          <Footer>
            <ListScrollAllSides virtualizedListRefs={virtualizedListRefs} />
            {/* ------- NEW ENTRY BTN ------- */}
            <Button
              onClick={newEntry}
              title={translate('newEntry')}
            >
              {translate('newEntry')}
            </Button>
            {/* ------- SUM BTN ------- */}
            <Button
              onClick={(e) => {
                e.preventDefault()
                setModalContent(<TotalRouteCost routes={routes} filteredRoutes={filteredRoutes} activeIndex={activeIndex} />)
                showModal()
              }}
              title='Сумма по № счета'
            >
              <span style={{ fontWeight: '350', fontSize: '18px' }}>Σ</span>
            </Button>
            {/* ------- CHART BTN ------- */}
            <Button
              style={{ minWidth: '50px', maxWidth: '50px' }}
              onClick={(e) => {
                e.preventDefault()
                setModalContent(<RoutesChart data={routes} />)
                setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
                showModal()
              }}
              title='График заказов по месяцам'
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '22px' }}>
                <BarChartIcon />
              </div>
            </Button>
            {/* ------- DEBT BTN ------- */}
            <Button
              onClick={(e) => {
                e.preventDefault()
                setModalContent(<Debt data={routesReplaced} />)
                setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
                showModal()
              }}
              title={translate('debt')}
            >
              {translate('debt')}
            </Button>
            {/* ------- FILTER BUTTON ------- */}
            <FilterButton
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              pageName={pageName}
            />
            {/* ------- TOTAL WEIGHT------- */}
            <ResultInputWrapper title='Общий вес'>
              <ResultInput
                value={`${totalWeight?.toLocaleString()} Кг.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
            {/* ------- TOTAL COST ------- */}
            <ResultInputWrapper title='Общая стоимость'>
              <ResultInput
                value={`${totalAmount?.toLocaleString()} Руб.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
            {/* ------- TOTAL HIRED DEBT------- */}
            <ResultInputWrapper title='Общая задолженность перевозчиков'>
              <ResultInput
                value={`${totalHiredDebt?.toLocaleString()} Руб.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
          </Footer>
        </>}
    </>
  )
}