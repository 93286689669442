import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'

const TextareaWrapper = styled.div`
  display: block;
  font-size: 14px;
  width: 100%;
  height: 100%;
`

const StyledTextarea = styled.textarea`
  position: relative;
  font-size: 1rem;
  padding: 1rem;
  margin-top: 1.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: box-shadow .3s, border .3s;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  background-color: transparent;
  color: ${({ color }) => (color ? color : 'var(--input-text-color)')};
  ${({ $isFocused }) =>
    $isFocused
      ? css`
          border: 1px solid var(--input-focused-color);
          box-shadow: none;
        `
      : css`
          border: 1px solid var(--input-element-color);
          box-shadow: none;
        `}
  ${({ disabled }) =>
    disabled &&
    css`
    color: var(--text-color-disabled);
    border: 1px dotted var(--border-color-disabled);
  `}
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 5px;
`

const Label = styled.label`
  position: absolute;
  font-size: 0.8rem;
  line-height: 1rem;
  color: ${({ $isFocused, readOnly }) => ($isFocused && !readOnly ? 'var(--input-focused-color)' : 'var(--input-label-color)')};
  transition: color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
    color: var(--text-color-disabled);
  `}
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate(0, -50%);
  color: hsl(0, 0%, 80%) !important;
  font-size: 4px;
  line-height: 1;
  transition: color 150ms;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
}`

export default function Textarea(props) {
  const { style, id, name, value, label = '-', color, onChange, onClick, onFocus, onBlur, onKeyDown, debounceDelay, disabled, readOnly, ...rest } = props
  const [debouncedOnChange, isDebouncing] = useDebounce(onChange, debounceDelay)
  const [inputValue, setInputValue] = useState(value || '')
  const [rows, setRows] = useState(1)
  const [prevInputValuesMap, setPrevInputValuesMap] = useState(new Map())
  const inputRef = useRef(null)
  const focusedInputNameRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setRows(inputValue.split('\n').length)
  }, [inputValue])

  useEffect(() => {
    setInputValue(value || '')
    setTimeout(() => {
      adjustHeight()
    }, 100)
  }, [value])

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'
      const scrollHeight = inputRef.current.scrollHeight
      inputRef.current.style.height = (scrollHeight + 2) + 'px'
    }
  }

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value)
    setRows(e.target.value.split('\n').length)
    adjustHeight()
    if (typeof onChange === 'function') {
      if (debounceDelay) {
        debouncedOnChange(e)
      } else {
        onChange(e)
      }
    }
  }, [onChange, debounceDelay, debouncedOnChange])

  const handleClick = useCallback((e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick])

  const handleBlur = useCallback((e) => {
    setIsFocused(false)
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
  }, [onBlur])

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    focusedInputNameRef.current = name
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus, name])

  const handleKeyDown = useCallback((e) => {
    //-------DELETE KEY-------//
    if (e.key === 'Delete' && !readOnly && inputRef.current.value !== '') {
      setPrevInputValuesMap((prevState) => {
        const newMap = new Map(prevState)
        if (!newMap.has(name)) {
          newMap.set(name, [])
        }
        newMap.get(name).push(inputRef.current.value)
        return newMap
      })
      setInputValue('')
      if (typeof onChange === 'function') {
        const fakeEvent = {
          target: {
            name,
            value: '',
            type: 'textarea',
          },
        }
        onChange(fakeEvent)
      }
    }
    if (typeof onKeyDown === 'function') {
      onKeyDown(e)
    }
  }, [onKeyDown, inputRef, readOnly, onChange, name])

  useEffect(() => {
    const handleUndo = (e) => {
      if (!isFocused) {
        return
      }
      if (
        e.ctrlKey &&
        e.code === 'KeyZ' &&
        document.activeElement === inputRef.current &&
        prevInputValuesMap.has(focusedInputNameRef.current)
      ) {
        if (e.target === inputRef.current) {
          const inputPrevValues = prevInputValuesMap.get(focusedInputNameRef.current)
          const restoredValue = inputPrevValues.pop()
          if (restoredValue !== undefined) {
            setInputValue(restoredValue)
            setPrevInputValuesMap((prevState) => {
              const newMap = new Map(prevState)
              const prevInputValues = newMap.get(focusedInputNameRef.current)
              prevInputValues.pop()
              if (prevInputValues.length === 0) {
                newMap.delete(focusedInputNameRef.current)
              } else {
                newMap.set(focusedInputNameRef.current, prevInputValues)
              }
              return newMap
            })
            const fakeEvent = {
              target: {
                name: focusedInputNameRef.current,
                value: restoredValue,
                type: 'textarea',
              },
            }
            if (typeof onChange === 'function') {
              onChange(fakeEvent)
            }
          }
        }
      }
    }

    document.addEventListener('keydown', handleUndo)
    return () => {
      document.removeEventListener('keydown', handleUndo)
    }
  }, [prevInputValuesMap, isFocused, onChange])

  useEffect(() => {
    adjustHeight()
  }, [])

  return (
    <TextareaWrapper>
      <LabelWrapper >
        <Label
          htmlFor={id}
          readOnly={readOnly}
          disabled={disabled}
          title={label}
          $isFocused={isFocused}
        >
          {label}
        </Label>
        {isDebouncing &&
          <LoadingIndicator>
            <span></span>
            <span></span>
            <span></span>
          </LoadingIndicator>}
      </LabelWrapper>
      <StyledTextarea
        ref={inputRef}
        id={id}
        name={name}
        value={inputValue}
        disabled={disabled}
        readOnly={readOnly}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...rest}
        $rows={rows}
        $color={color}
        $isFocused={isFocused}
      />
    </TextareaWrapper>
  )
}