import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'
import Button from '../Buttons/Button'

const Container = styled.div`
  display: flex;
  align-items: left;
  gap: 10px;
  height: 36px;
  width: 500px;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 100%;
  width: 300px;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  color: var(--text-inverted-color);
  html[data-theme='dark'] & {
    color: var(--input-text-color);
  }
  box-shadow: none;
  box-sizing: content-box;
  transition: border 0.3s;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-element-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
          html[data-theme='dark'] & {
            border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
          }   
        `
      : css`
          border-bottom: ${$borderHeight} solid var(--text-color-variant);
          box-shadow: none;
        `}
  line-height: 1.5rem;
  &::placeholder {
    color: var(--text-color-variant);
  }
`

export default function SearchInput(props) {
  const { style, type, placeholder = 'Поиск...', name, id = 'search', value, borderOption = 1, disabled, readOnly, onChange, onClick, onBlur, onFocus, onKeyDown, debounceDelay } = props
  const [debouncedHandleChange, isDebouncing] = useDebounce(onChange, debounceDelay)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')
  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = true
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleClick = useCallback((e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick])

  const handleBlur = useCallback((e) => {
    setIsFocused(false)
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
  }, [onBlur])

  const handleChange = useCallback(
    (e) => {
      setInputValue(e.target.value)
      if (typeof onChange === 'function') {
        if (debounceDelay) {
          debouncedHandleChange(e)
        } else {
          onChange(e)
        }
      }
    },
    [onChange, debounceDelay, debouncedHandleChange]
  )

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus])

  const handleKeyDown = useCallback((e) => {
    if (typeof onKeyDown === 'function') {
      onKeyDown(e)
    }
    if (e.key === 'Delete') {
      setInputValue('')
      const fakeEvent = {
        target: {
          name,
          value: '',
          type: 'delete-btn'
        },
      }
      handleChange(fakeEvent)
    }
  }, [onKeyDown, handleChange, name])

  return (
    <Container style={{ ...style }}>
      <Input
        type={type ? type : 'text'}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        readOnly={readOnly}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        $isFocused={isFocused}
        $borderHeight={borderHeight}
        $focusedBorderHeight={focusedBoxShadow}
        $focusedBoxShadow={focusedBoxShadow}
      />
      {(!isDebouncing && inputValue) && <Button
        style={{ backgroundColor: 'var(--alert-button-bg)' }}
        onClick={(e) => {
          e.preventDefault()
          setInputValue('')
          const fakeEvent = {
            target: {
              name,
              value: '',
              type: 'clear-btn'
            },
          }
          handleChange(fakeEvent)
        }}
      >
        &#10006;
      </Button>}
    </Container>
  )
}