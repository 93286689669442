import React from 'react'

export default function UserIcon({ color = 'var(--main-color)', size = 24 }) {
  return (
    <svg viewBox='0 0 1024 1024' width={`${size}px`} height={`${size}px`} fill={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'>
      <path d='M537.216 1003.776h-427.52c-27.072 0-52.8-11.456-70.592-31.296a76.352 76.352 0 0 1-19.904-58.88l-0.192-60.16a32 32 0 0 1 64 0v63.872c-0.384 5.44 0.576 8.832 3.968 12.608a30.656 30.656 0 0 0 22.72 9.856h355.52a32 32 0 0 1 0 64z' />
      <path d='M392.784 1003.776h427.52c27.072 0 52.8-11.456 70.592-31.296a76.352 76.352 0 0 0 19.904-58.88l0.192-60.16a32 32 0 0 0 -64 0v63.872c0.384 5.44 -0.576 8.832 -3.968 12.608a30.656 30.656 0 0 1 -22.72 9.856h-355.52a32 32 0 0 0 0 64z' />
      <path d='M50.752 934.144a32 32 0 0 1-32-32v-60.032a457.536 457.536 0 0 1 277.952-421.12 32.192 32.192 0 0 1 42.048 16.832 32.128 32.128 0 0 1-16.896 42.112 393.408 393.408 0 0 0-239.104 362.24v60.032a32 32 0 0 1-32 31.936z' />
      <path d='M879.248 934.144a32 32 0 0 0 32-32v-60.032a457.536 457.536 0 0 0 -277.952-421.12 32.192 32.192 0 0 0 -42.048 16.832 32.128 32.128 0 0 0 16.896 42.112 393.408 393.408 0 0 1 239.104 362.24v60.032a32 32 0 0 0 32 31.936z' />
      <path d='M460.736 514.496a247.36 247.36 0 0 1-247.104-247.104A247.424 247.424 0 0 1 460.736 20.224a247.424 247.424 0 0 1 247.168 247.168 247.36 247.36 0 0 1-247.168 247.104z m0-430.272a183.36 183.36 0 0 0-183.104 183.168 183.296 183.296 0 0 0 183.104 183.104 183.36 183.36 0 0 0 183.168-183.104 183.36 183.36 0 0 0-183.168-183.168z' />
    </svg>
  )
}
