const translations = {
  en: {
    newEntry: 'New entry',
    clickTwiceToAddText: 'Click twice to add text',
    confirm: 'Confirm',
    ok: 'Ok',
    turnOn: 'Turn on',
    turnOff: 'Turn off',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    back: 'Back',
    unlink: 'Unlink',
    deleteEntry: 'Delete entry',
    edit: 'Edit',
    markForDelition: 'Mark for delition',
    unmarkForDelition: 'Unmark for delition',
    admin: 'Administrator',
    switchLang: 'Switch to Russian',
    darkTheme: 'Dark theme',
    logout: 'Logout',
    login: 'Login',
    username: 'Username',
    password: 'Password',
    newPassword: 'New password',
    resetFilter: 'Reset filter',
    access: 'Access',
    added: 'Added',
    user: 'User',
    users: 'Users',
    settings: 'Settings',
    selectOrganization: 'Select organization',
    server: 'Server',
    developed: 'developed by Pocket Office',
    interfaceLanguage: 'Interface language',
    telegram: 'Telegram',
    files: 'Files',
    history: 'History',
    lastActivity: 'Last Activity',
    online: 'Online',
    change: 'Change',
    roles: 'Roles',
    role: 'Role',
    enable: 'Enable',
    disable: 'Disable',
    editUserRoles: 'Edit user roles',
    disableUserAccess: 'Disable access for user',
    enableUserAccess: 'Enable access for user',
    changeUserPassword: 'Change user password',
    deleteUser: 'Delete user',
    enableTwoStepVerification: 'Enable two-step verification',
    disableTwoStepVerification: 'Disable two-step verification',
    filter: 'Filter',
    userRoles: 'User roles',
    newUser: 'New user',
    addUser: 'Add user',
    register: 'Register',
    filterInUse: 'Filter in use',
    debt: 'Debt',
    moveUpDown: 'Move up/down',
    moveRightLeft: 'Move right/left',
    unlinkTelegramId: 'Unlink telegram ID',
    enterMessage: 'Enter message',
    messageToUsers: 'Message to users',
    send: 'Send',
    sent: 'Sent',
    message: 'Message',
    messageFrom: 'Message from',
    close: 'Close',
    alert: 'Alert',
    alerts: 'Alerts',
  },
  ru: {
    newEntry: 'Новая запись',
    clickTwiceToAddText: 'Кликните дважды, чтобы добавить текст',
    confirm: 'Подтвердить',
    ok: 'Ок',
    turnOn: 'Включить',
    turnOff: 'Отключить',
    save: 'Сохранить',
    cancel: 'Отмена',
    delete: 'Удалить',
    back: 'Назад',
    unlink: 'Отвязать',
    deleteEntry: 'Удалить запись',
    edit: 'Редактировать',
    markForDelition: 'Пометить на удаление',
    unmarkForDelition: 'Снять пометку на удаление',
    admin: 'Администратор',
    switchLang: 'Switch to English',
    darkTheme: 'Темная тема',
    logout: 'Выйти',
    login: 'Войти',
    username: 'Имя пользователя',
    password: 'Пароль',
    newPassword: 'Новый пароль',
    resetFilter: 'Сбросить фильтр',
    access: 'Доступ',
    added: 'Добавлен',
    user: 'Пользователь',
    users: 'Пользователи',
    settings: 'Настройки',
    selectOrganization: 'Выбрать организацию',
    server: 'Сервер',
    developed: 'разработано Pocket Office',
    interfaceLanguage: 'Язык интерфейса',
    telegram: 'Телеграм',
    files: 'Файлы',
    history: 'История',
    lastActivity: 'Последняя активность',
    online: 'Онлайн',
    change: 'Изменить',
    roles: 'Роли',
    role: 'Роль',
    enable: 'Разрешить',
    disable: 'Запретить',
    editUserRoles: 'Редактировать роли пользователя',
    disableUserAccess: 'Запретить доступ пользователю',
    enableUserAccess: 'Разрешить доступ пользователю',
    changeUserPassword: 'Изменить пароль пользователя',
    deleteUser: 'Удалить пользователя',
    enableTwoStepVerification: 'Включить двухэтапную аутентификацию',
    disableTwoStepVerification: 'Отключить двухэтапную аутентификацию',
    filter: 'Фильтр',
    userRoles: 'Роли пользователя',
    newUser: 'Новый пользователь',
    addUser: 'Добавить пользователя',
    register: 'Регистрация',
    filterInUse: 'Используется фильтр',
    debt: 'Дебиторка',
    moveUpDown: 'Сдвинуть вверх/вниз',
    moveRightLeft: 'Сдвинуть вправо/влево',
    unlinkTelegramId: 'Отвязать телеграм ID',
    enterMessage: 'Введите сообщение',
    messageToUsers: 'Сообщение пользователям',
    send: 'Отправить',
    sent: 'Отправлено',
    message: 'Сообщение',
    messageFrom: 'Сообщение от',
    close: 'Закрыть',
    alert: 'Оповещение',
    alerts: 'Оповещения',
  },
}

export default translations
