import React, { useContext, useEffect, useRef, useState } from 'react'
import PostService from '../../API/PostService'
import { useFetching } from '../../hooks/useFetching'
import { useMessage } from '../../hooks/useMessage'
import { FilesTable } from './FilesTable'
import styled, { css } from 'styled-components'
import Button from '../Buttons/Button'
import FileInput from '../Inputs/FileInput'
import ModalContext from '../../context/ModalContext'
import useModalContentSize from '../../hooks/useModalContentSize'
import { useLanguage } from '../../context/LanguageContext'
import { useSelector } from 'react-redux'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const TableWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  border: 1px solid var(--line-color);
  position: relative;
  overflow: auto;
  flex: 1;
`

const InputWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0px 0px 15px 0px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
  flex-shrink: 0;
`

const text = {
  fileInput: {
    buttonText: {
      en: 'Files',
      ru: 'Файлы'
    },
    placeholder: {
      en: 'Upload one or multiple files',
      ru: 'Загрузите один или несколько файлов'
    }
  },
  uploadButton: {
    en: 'Upload',
    ru: 'Загрузить'
  },
  loadingText: {
    en: 'Uploading...',
    ru: 'Загрузка...'
  },
  closeButton: {
    en: 'Close',
    ru: 'Закрыть'
  },
  messages: {
    noFilesSelected: {
      en: 'Please select files',
      ru: 'Выберите файлы'
    },
    somethingWentWrong: {
      en: 'Something went wrong, please try again',
      ru: 'Что-то пошло не так, попробуйте снова'
    }
  }
}

export default function UploadFiles({ fileFolder, subFolder }) {
  const { language } = useLanguage()
  const { closeModal } = useContext(ModalContext)
  const { companyId } = useSelector(state => state.data)
  const { modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const message = useMessage()
  const filesRef = useRef(null)
  const [isSending, setIsSending] = useState(false)

  const [fileList, setFileList] = useState([])

  const [fetchFileList, fileListLoading, fileListError, fileListLoaded] = useFetching(async () => {
    const payload = { 'file-folder': fileFolder, 'sub-folder': subFolder }
    const response = await PostService.get('file',`${companyId}/file-list`, payload, language)
    if (response && !response.errors) {
      message(response.message)
    }
    setFileList(response.data)
    fileListError && console.log(fileListError)
  })

  const sendFiles = async (e) => {
    e.preventDefault()
    const myFiles = filesRef.current.files
    if (!myFiles.length) {
      return message('Выберите файлы', 'warn')
    }

    setIsSending(true)
    try {
      const formData = new FormData()
      formData.append('file-folder', fileFolder)
      formData.append('sub-folder', subFolder)
      Object.keys(myFiles).forEach(key => {
        formData.append(myFiles.item(key).name, myFiles.item(key))
      })

      const response = await fetch(`/api/file/${companyId}/upload-files`, {
        method: 'POST',
        body: formData,
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      })

      const json = await response.json()

      if (response.ok) {
        message(json?.message, 'success')
        fetchFileList()
      } else {
        message(json?.message, 'warn')
      }
    } catch (error) {
      console.error(error)
      message('Что-то пошло не так, попробуйте снова', 'error')
    } finally {
      setIsSending(false)
    }
  }

  useEffect(() => {
    fetchFileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper
      $height={modalWrapperHeight}
      $width={modalWrapperWidth}
      $isLoading={!fileListLoaded}
    >
      <InputWrapper $width={modalContentWidth}>
        <FileInput
          filesRef={filesRef}
          multiple={true}
          accept='image/*, .pdf, .doc, .docx, .xlsx, .xsl'
          buttonText={text.fileInput.buttonText[language]}
          placeholder={text.fileInput.placeholder[language]}
          isLoading={fileListLoading}
        />
        <Button
          style={{ margin: '0px 0px 0px 10px', minWidth: '130px' }}
          onClick={(e) => {
            sendFiles(e)
          }}
          disabled={isSending}
        >
          {isSending ? text.loadingText[language] : text.uploadButton[language]}
        </Button>
      </InputWrapper>
      {fileList.length ?
        <TableWrapper
          $width={modalContentWidth}
          $isLoading={fileListLoading}
        >
          <FilesTable fetchFileList={fetchFileList} fileList={fileList} fileFolder={fileFolder} subFolder={subFolder} />
        </TableWrapper> : null}
      {/* ------------(CLOSE BUTTON)------------ */}
      <FooterWrapper $width={modalContentWidth}>
        <Button
          style={{ minWidth: '130px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          {text.closeButton[language]}
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}
