import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 2.3rem;
  width: 100%;
  font-size: 14px;
  margin: 0 0 1px;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--input-text-color);
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}

    ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
          border-bottom: 1px dotted var(--text-color-disabled);
        `
      : $forceDarkMode
        ? css`
          color: #FFFFFF;
          &::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `
        : css`
          html[data-theme='dark'] & {
            color: #FFFFFF;
            &::-webkit-calendar-picker-indicator {
              filter: invert(1);
            }
          }
        `
  }
`

export default function ResultInput(props) {
  const { id, name, style, borderOption = 1, value = 0, forceDarkMode = false, disabled = false } = props
  const [isFocused, setIsFocused] = useState(false)

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState(true)
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = true
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  return (
    <Container>
      <Input
        style={style}
        id={id}
        name={name}
        type='text'
        value={value}
        readOnly={true}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
      />
    </Container>
  )
}
