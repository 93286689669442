import { createContext } from 'react'

const ModalContext = createContext({
  showModal: () => { },
  closeModal: () => { },
  modalContent: null,
  setModalContent: () => { },
  modalPadding: { smallScreen: '25px', largeScreen: '25px' },
  setModalPadding: () => { },
})

export default ModalContext
