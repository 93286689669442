import { useState, useEffect, useContext } from 'react'
import useWindowSize from './useWindowSize'
import ModalContext from '../context/ModalContext'

export default function useModalContentSize() {
  const windowSize = useWindowSize()
  const { modalPadding } = useContext(ModalContext)
  const [isSmallScreen, setIsSmallScreen] = useState(windowSize.isSmallScreen)
  const [modalWrapperWidth, setModalWrapperWidth] = useState(windowSize.isSmallScreen ? windowSize.width : windowSize.width * 0.7)
  const [modalWrapperHeight, setModalWrapperHeight] = useState(windowSize.height * 0.8)
  const [modalContentWidth, setModalContentWidth] = useState(windowSize.isSmallScreen ? windowSize.width - getPaddingValue(modalPadding.smallScreen) : windowSize.width * 0.7)

  function getPaddingValue(str) {
    try {
      if (typeof str === 'string' && str.trim()) {
        const paddingValues = str.trim().split(' ')
        if (paddingValues.length === 1) {
          return 0
        }
        return parseInt(paddingValues[1].match(/\d+/)[0], 10) * 2
      }
      return 0
    } catch (error) {
      console.log('Failed to get modal padding value', error)
      return 0
    }
  }

  useEffect(() => {
    setIsSmallScreen(windowSize.isSmallScreen)
    setModalWrapperWidth(windowSize.isSmallScreen ? windowSize.width : windowSize.width * 0.7)
    setModalWrapperHeight(windowSize.height * 0.8)
    setModalContentWidth(windowSize.isSmallScreen ? windowSize.width - getPaddingValue(modalPadding.smallScreen) : windowSize.width * 0.7)
  }, [windowSize, modalPadding.smallScreen])

  return { windowSize, isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth }
}