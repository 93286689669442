import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import ModalContext from '../../context/ModalContext'
import Library from '../../Library'
import Button from '../Buttons/Button'
import { useAuth } from '../../hooks/useAuth'
// import ColorTheme from './components/ColorTheme/ColorTheme'
import SoundTheme from './components/SoundTheme/SoundTheme'
import useModalContentSize from '../../hooks/useModalContentSize'
import { setSettings } from '../../store/actions/authActions'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
    maxHeight: props.height + 'px',
  },
}))`
  position: relative;
  display: flex;
  flex-direction: column;
`

const SettingsWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width < 570 ? props.width + 'px' : '570px',
    borderColor: props.color,
  },
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: 1px solid;
  overflow-y: auto;
  padding: 10px 0 15px 0;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width < 570 ? props.width + 'px' : '570px',
  },
}))`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  justify-content: flex-end;
`

export default function UserSettings() {
  const { id, settings } = useAuth()
  const dispatch = useDispatch()
  const { modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const { closeModal, setModalContent } = useContext(ModalContext)
  const maxWidth = 570

  const [form, setForm] = useState({
    colorTheme: settings.colorTheme,
    sound: settings.sound,
  })

  const handler = (e) => {
    e.preventDefault()
    setSaveButtonDisabled(true)
    Library.handler({
      e,
      path: 'auth',
      request: 'set-user-settings',
      form: { id, settings: form },
      edit: true,
      onSuccess: () => {
        dispatch(setSettings(form))
          // Save the user settings separately
          localStorage.setItem('auth-user-settings', JSON.stringify(form))
        setSaveButtonDisabled(false)
      },
      onFail: () => {
        setTimeout(() => {
          setSaveButtonDisabled(false)
        }, 500)
      },
    })
  }

  useEffect(() => {
    return () => {
      setModalContent(null)
    }
  }, [setModalContent])

  return (
    <Wrapper height={modalWrapperHeight} width={modalWrapperWidth > maxWidth ? maxWidth : modalWrapperWidth}>
      <SettingsWrapper
        width={modalContentWidth}
      >
        {/* <ColorTheme
          form={form}
          setForm={setForm}
          modalWrapperHeight={modalWrapperHeight}
        /> */}
        <SoundTheme
          form={form}
          setForm={setForm}
        />
      </SettingsWrapper>
      <FooterWrapper width={modalContentWidth}>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            minWidth: '130px',
            maxWidth: '130px',
          }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={false}
        >
          Закрыть
        </Button>
        <Button
          style={{
            minWidth: '130px',
            maxWidth: '130px',
          }}
          disabled={saveButtonDisabled}
          onClick={handler}
        >
          Применить
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}