import React from 'react'
import styled, { css } from 'styled-components'

const ModalContainer = styled.div`
  position: fixed;
  z-index: 9998;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  height: auto;
  width: auto;
  border-radius: 2px;

  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 5px;
      bottom: 59px;
      box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px, rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
      background: var(--surface-color);
    `}
`

const ModalContent = styled.div`
`

export default function FilterModal(props) {
  const { children, modal, setModal } = props
  return (
    <ModalContainer
      $active={modal}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.preventDefault()
          setModal(false)
        }
      }}
    >
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalContainer>
  )
}
