import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../../Library'
import moment from 'moment'
import { useMessage } from '../../../hooks/useMessage'
import { useLocation, useParams } from 'react-router-dom'
import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock'
import Textarea from '../../../components/Inputs/Textarea'
import Autocomplete from '../../../components/Inputs/Autocomplete'
import NumberInput from '../../../components/Inputs/NumberInput'
import DateInput from '../../../components/Inputs/DateInput'
import TextInput from '../../../components/Inputs/TextInput'
import Checkbox from '../../../components/Inputs/Checkbox'
import Select from '../../../components/Inputs/Select'
import styled from 'styled-components'
import useWindowHeight from '../../../hooks/useWindowHeight'
import { useSelector } from 'react-redux'
import { useLanguage } from '../../../context/LanguageContext'
import LinearLoader from '../../../components/Loaders/LinearLoader'

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 649px) { 
    max-width: 300px;
    margin: 35px auto 85px auto;
  }

  @media (min-width: 650px) and (max-width: 959px) {
    max-width: 610px;
    margin: 35px auto 60px auto;
  }

  @media (min-width: 960px) {
    max-width: 920px;
    margin: 35px 15px 10px 25px;
  }
`

const Divider = styled.div`
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
  margin: 0 0 20px 0;

  @media (max-width: 649px) {
    margin: 20px 0 40px 0;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 30px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  @media (max-width: 649px) {  
    row-gap: 30px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  width: 300px;
  flex-wrap: nowrap;
  gap: 10px;
`

const LayoutGap = styled.div`
  min-width: 145px;
`

const CommentWrapper = styled.div`
  width: 100%;
  @media (max-width: 649px) { 
    margin-bottom: 30px;
  }
`

export const Form = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1).split('/')[0]
  const params = useParams()
  const message = useMessage()
  const { language } = useLanguage()
  const wrapperRef = useRef(null)
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const { companyId } = useSelector(state => state.data)
  useWindowHeight(wrapperRef, 84)
  const debounceDelay = 500
  const [changedField, setChangedField] = useState('')
  const [dataReady, setDataReady] = useState(false)
  const initialFormData = useMemo(() => ({
    id: params.id,
    documentsSent: '',
    documentsReceived: '',
    dueDate: '',
    paymentStatus: '',
    contractNumber: '',
    cargoName: '',
    dateOfDeparture: '',
    arrivalDate: '',
    accountNumber: '',
    customer: '',
    isHired: 0,
    carrier: '',
    carrierOrderNumber: '',
    hiredCar: '',
    hiredDriver: '',
    car: '',
    loadingAddress: '',
    unloadingAddress: '',
    paymentType: '',
    weight: '',
    rate: '',
    routeCost: '',
    driver: '',
    carrierPaymentType: '',
    driverSalary: '',
    debt: '',
    carrierDocumentsReceived: '',
    carrierDueDate: '',
    paymentDate: '',
    paymentAmount: '',
    balance: '',
    fine: '',
    comment: '',
  }), [params.id])
  const [form, setForm] = useState(initialFormData)

  const [isRouteCostManuallyEdited, setIsRouteCostManuallyEdited] = useState(false)

  const { cars, hiredCars, hiredDrivers, employees, customers, carriers, routes, loading, error } = useSelector(state => state.data)
  const drivers = useMemo(() => employees.filter(employee => employee.isDriver), [employees])

  useEffect(() => {
    if (isDataLoaded && !loading && !error) {
      if (params.id && routes.length) {
        const formData = Library.findItemById(routes, params.id)
        if (formData) {
          setForm(formData)
        } else {
          setForm(initialFormData)
        }
      }
      setDataReady(true)
    }
  }, [initialFormData, isDataLoaded, loading, error, routes, params.id])

  const handleRouteCostChange = (newRouteCost) => {
    setChangedField('routeCost')
    setForm(prevState => ({ ...prevState, routeCost: newRouteCost }))
    setIsRouteCostManuallyEdited(true)
  }

  const [addressOptions, setAddressOptions] = useState([])

  useEffect(() => {
    if (routes.length > 0) {
      let arr = []
      routes.forEach((item) => {
        arr.push(item.loadingAddress)
        arr.push(item.unloadingAddress)
      })
      setAddressOptions([...new Set(arr)])
    }
  }, [routes])

  const paymentTypeSelectOptions = [
    {
      value: '',
      label: '-',
      disabled: false,
      defaultValue: true,
    },
    { value: 'Ндс', label: 'Ндс' },
    { value: 'Нал', label: 'Нал' },
    { value: 'Без ндс', label: 'Без ндс' },
  ]

  const paymentStatusSelectOptions = [
    {
      value: '',
      label: '-',
      disabled: false,
      defaultValue: true,
    },
    { value: 'Оплачен', label: 'Оплачен' },
    { value: 'Завис', label: 'Завис' },
  ]

  const carrierPaymentTypeSelectOptions = [
    {
      value: '',
      label: '-',
      disabled: false,
      defaultValue: true,
    },
    { value: 'Ндс', label: 'Ндс' },
    { value: 'Нал', label: 'Нал' },
    { value: 'Без ндс', label: 'Без ндс' },
  ]

  function getSelectOptions(data) {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (data && data.length) {
      data.forEach((item) => {
        arr.push({ value: item.id, label: item.name })
      })
    }
    return arr
  }

  function toNumberForCalculation(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value)
  }

  const recalculate = useCallback(() => {
    let debt = 0
    let balance = 0
    let routeCost = toNumberForCalculation(form.routeCost)
    let paymentAmount = toNumberForCalculation(form.paymentAmount)
    const driverSalary = toNumberForCalculation(form.driverSalary)

    // Only recalculate routeCost if it has not been manually edited
    if (!isRouteCostManuallyEdited && form.weight && form.rate) {
      const weight = toNumberForCalculation(form.weight)
      const rate = toNumberForCalculation(form.rate)
      routeCost = parseFloat((weight / 1000 * rate).toFixed())
    }

    if (form.isHired) {
      //--- Ндс/Ндс ---//
      if (form.paymentType === 'Ндс' && form.carrierPaymentType === 'Ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = parseFloat(((routeCost - driverSalary) * 0.48).toFixed())

        // console.log('driverSalary:', driverSalary)
        // console.log('paymentAmount:', paymentAmount)
        // console.log('debt:', debt)
        // console.log('routeCost:', routeCost)
        // console.log('balance:', balance)
      }
      //--- Ндс/Нал ---//
      else if (form.paymentType === 'Ндс' && form.carrierPaymentType === 'Нал') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = parseFloat(((routeCost - driverSalary) * 0.35).toFixed())
      }
      //--- Ндс/Без ндс ---//
      else if (form.paymentType === 'Ндс' && form.carrierPaymentType === 'Без ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = 0
      }
      //--- Нал/Ндс ---//
      else if (form.paymentType === 'Нал' && form.carrierPaymentType === 'Ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = 0
      }
      //--- Нал/Нал ---//
      else if (form.paymentType === 'Нал' && form.carrierPaymentType === 'Нал') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = parseFloat(((routeCost - form.driverSalary) * 0.8).toFixed())
      }
      //--- Нал/Без ндс ---//
      else if (form.paymentType === 'Нал' && form.carrierPaymentType === 'Без ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = 0
      }
      //--- Без ндс/Ндс ---//
      else if (form.paymentType === 'Без ндс' && form.carrierPaymentType === 'Ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = 0
      }
      //--- Без ндс/Нал ---//
      else if (form.paymentType === 'Без ндс' && form.carrierPaymentType === 'Нал') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = parseFloat(((routeCost - driverSalary) * 0.7).toFixed())
      }
      //--- Без ндс/Без ндс ---//
      else if (form.paymentType === 'Без ндс' && form.carrierPaymentType === 'Без ндс') {
        debt = parseFloat((driverSalary - paymentAmount).toFixed())
        balance = parseFloat(((routeCost - driverSalary) * 0.8).toFixed())
      }
      else {
        debt = 0
        balance = 0
        paymentAmount = 0
      }
    }

    return { routeCost, debt, balance }
  }, [
    form.weight,
    form.rate,
    form.isHired,
    form.driverSalary,
    form.paymentType,
    form.carrierPaymentType,
    form.paymentAmount,
    form.routeCost,
    isRouteCostManuallyEdited
  ])

  useEffect(() => {
    if (['weight', 'rate', 'isHired', 'driverSalary', 'paymentAmount', 'paymentType', 'carrierPaymentType', 'routeCost'].includes(changedField)) {
      const newValues = recalculate()
      setForm(prevState => ({
        ...prevState,
        debt: newValues.debt,
        balance: newValues.balance,
        routeCost: newValues.routeCost
      }))
      setChangedField('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedField, recalculate, isRouteCostManuallyEdited])

  useEffect(() => {
    if (['customer', 'documentsReceived'].includes(changedField)) {
      if (form.customer && form.documentsReceived) {

        const customer = customers.find(item => item.id === form.customer)
        const paymentDelay = customer.paymentDelay // days

        // Ensure that documentsReceived is a valid date
        const receivedDate = moment(form.documentsReceived)
        if (receivedDate.isValid()) {
          const dueDateMoment = receivedDate.add(paymentDelay, 'days')
          const dueDateString = dueDateMoment.format('YYYY-MM-DD')

          // Update form with the new due date
          setForm({ ...form, dueDate: dueDateString })
          message(
            <div>
              <span style={{ fontWeight: 'bold' }}>{customer.name}</span><br />
              отсрочка {customer.paymentDelay} дн.<br />
              Срок оплаты установлен на: <span style={{ fontWeight: 'bold' }}>{dueDateMoment.format('DD.MM.YYYY')}</span>
            </div>, 'info')
        }

        // Reset the changed fields for customer and documentsReceived
        setChangedField(prev => ({ ...prev, customer: false, documentsReceived: false }))
      } else {
        setForm({ ...form, dueDate: '' })
      }
      setChangedField('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedField])

  //------- CHANGE HISTORY -------//

  const keyTranslation = useMemo(() => [
    { id: 'id', name: 'id' },
    { id: 'timestamp', name: 'timestamp' },
    { id: 'status', name: 'Статус' },
    { id: 'documentsSent', name: 'Док. отправлены' },
    { id: 'documentsReceived', name: 'Док. получены' },
    { id: 'dueDate', name: 'Срок оплаты' },
    { id: 'paymentStatus', name: 'Статус оплаты' },
    { id: 'contractNumber', name: 'Д-з №' },
    { id: 'cargoName', name: 'Наименование груза' },
    { id: 'dateOfDeparture', name: 'Дата погрузки' },
    { id: 'arrivalDate', name: 'Дата выгрузки' },
    { id: 'accountNumber', name: '№ счета' },
    { id: 'customer', name: 'Заказчик' },
    { id: 'isHired', name: 'Статус привлечен.' },
    { id: 'carrier', name: 'Перевозчик' },
    { id: 'carrierOrderNumber', name: 'Заявка-перевозчика №' },
    { id: 'hiredCar', name: 'Автомобиль перевозчика' },
    { id: 'hiredDriver', name: 'Водитель перевозчика' },
    { id: 'car', name: 'Автомобиль' },
    { id: 'loadingAddress', name: 'Адрес погрузки' },
    { id: 'unloadingAddress', name: 'Адрес выгрузки' },
    { id: 'paymentType', name: 'Форма оплаты' },
    { id: 'weight', name: 'Вес/часы' },
    { id: 'rate', name: 'Ставка' },
    { id: 'routeCost', name: 'Стоимость' },
    { id: 'driver', name: 'Водитель' },
    { id: 'carrierPaymentType', name: 'Форма оплаты перевозчика' },
    { id: 'driverSalary', name: 'Зарплата водителя' },
    { id: 'debt', name: 'Задолженность' },
    { id: 'carrierDocumentsReceived', name: 'Дата получения документов' },
    { id: 'carrierDueDate', name: 'Срок оплаты перевозчику' },
    { id: 'paymentDate', name: 'Дата оплаты' },
    { id: 'paymentAmount', name: 'Сумма оплаты' },
    { id: 'balance', name: 'Сальдо' },
    { id: 'fine', name: 'Штраф' },
    { id: 'comment', name: 'Комментарий' },
  ], [])

  const formatValue = (key, value) => {
    switch (key) {
      case 'status': {
        return value === 'active' ? 'активный' : 'помечен на удаление'
      }
      case 'driver': {
        return Library.findNameByKey(drivers, value)
      }
      case 'car': {
        return Library.findNameByKey(cars, value)
      }
      case 'hiredCar': {
        return Library.findNameByKey(hiredCars, value)
      }
      case 'hiredDriver': {
        return Library.findNameByKey(hiredDrivers, value)
      }
      case 'customer': {
        return Library.findNameByKey(customers, value)
      }
      case 'carrier': {
        return Library.findNameByKey(carriers, value)
      }
      case 'documentsSent':
      case 'documentsReceived':
      case 'dueDate':
      case 'dateOfDeparture':
      case 'arrivalDate':
      case 'paymentDate': {
        return Library.formatDate(value, 'DD.MM.yyyy')
      }
      case 'isHired': {
        return value ? '☑' : ''
      }
      default: {
        return value
      }
    }
  }

  const getKeyTranslation = keyTranslation.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})

  const renderChangeDetails = (change) => {
    if (!change.newData) {
      return ''
    }

    const parseJSON = (data) => {
      try {
        return JSON.parse(data.replace(/\n/g, '\\n'))
      } catch (e) {
        console.error('Error parsing JSON: ', e)
        return {}
      }
    }

    const isValueEmpty = (value) => {
      return value === '' || value === null || value === 0
    }

    const isValueChanged = (oldValue, newValue) => {
      return oldValue !== newValue
    }

    const parsedNewData = parseJSON(change.newData.replace(/\n/g, '\\n'))
    const parsedOldData = change.oldData ? parseJSON(change.oldData.replace(/\n/g, '\\n')) : {}

    return Object.keys(parsedNewData).map((key) => {
      let oldData = parsedOldData[key] ? formatValue(key, parsedOldData[key]) : ''
      let newData = parsedNewData[key] ? formatValue(key, parsedNewData[key]) : ''

      if ((isValueEmpty(oldData) && isValueEmpty(newData)) || !isValueChanged(oldData, newData)) {
        return null
      }

      return (
        <div key={key} style={{ marginBottom: '10px' }}>
          <span>{getKeyTranslation[key] || key}:</span>
          {'\u00A0'}
          <span>{oldData ? oldData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
          {'\u00A0\u2192\u00A0'}
          <span>{newData ? newData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
        </div>
      )
    }).filter(Boolean)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && dataReady &&
        <FormWrapper>
          <SectionWrapper $maxWidth={1000}>
            <RowWrapper>
              {/* ------- CUSTOMER ------- */}
              < Select
                label='Заказчик'
                id='customer'
                name='customer'
                value={form.customer}
                options={getSelectOptions(customers)}
                searchable={true}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CONTRACT NUMBER ------- */}
              <TextInput
                label='Договор-заявка №'
                id='contractNumber'
                name='contractNumber'
                value={form.contractNumber}
                debounceDelay={debounceDelay}
                isClearable={false}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CARGO NAME ------- */}
              <TextInput
                label='Наименование груза'
                id='cargoName'
                name='cargoName'
                value={form.cargoName}
                debounceDelay={debounceDelay}
                isClearable={false}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DATE OF DEPARTURE ------- */}
              <DateInput
                label='Дата погрузки'
                name='dateOfDeparture'
                id='dateOfDeparture'
                value={form.dateOfDeparture ? moment(form.dateOfDeparture).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
              {/* ------- ARRIVAL DATE ------- */}
              <DateInput
                label='Дата выгрузки'
                name='arrivalDate'
                id='arrivalDate'
                value={form.arrivalDate ? moment(form.arrivalDate).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- WEIGHT ------- */}
              <NumberInput
                label='Вес/часы'
                name='weight'
                id='weight'
                value={form.weight || ''}
                debounceDelay={debounceDelay}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                readOnly={false}
              />
              {/* ------- RATE ------- */}
              <NumberInput
                label='Ставка'
                name='rate'
                id='rate'
                value={form.rate || ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- ROUTE COST ------- */}
              <NumberInput
                label='Стоимость'
                name='routeCost'
                id='routeCost'
                value={form.routeCost || ''}
                onChange={e => handleRouteCostChange(e.target.value)}
                debounceDelay={debounceDelay}
                readOnly={false}
              />
              {/* ------- ACCOUNT NUMBER ------- */}
              <NumberInput
                label='№ счета'
                name='accountNumber'
                id='accountNumber'
                value={form.accountNumber || ''}
                debounceDelay={debounceDelay}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                readOnly={false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- PAYMENT TYPE ------- */}
              <Select
                label='Форма оплаты'
                name='paymentType'
                id='paymentType'
                value={form.paymentType}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                options={paymentTypeSelectOptions}
              />
              {/* ------- PAYMENT STATUS ------- */}
              <Select
                label='Статус оплаты'
                name='paymentStatus'
                id='paymentStatus'
                value={form.paymentStatus}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                options={paymentStatusSelectOptions}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- LOADING ADDRESS ------- */}
              <Autocomplete
                options={addressOptions}
                name='loadingAddress'
                id='loadingAddress'
                label='Адрес погрузки'
                isClearable={false}
                disabled={false}
                value={form.loadingAddress}
                debounceDelay={debounceDelay}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- UNLOADING ADDRESS ------- */}
              <Autocomplete
                options={addressOptions}
                name='unloadingAddress'
                id='unloadingAddress'
                label='Адрес выгрузки'
                isClearable={false}
                disabled={false}
                value={form.unloadingAddress}
                debounceDelay={debounceDelay}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CAR ------- */}
              < Select
                label='Автомобиль'
                id='car'
                name='car'
                value={form.car}
                options={getSelectOptions(cars)}
                searchable={true}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DRIVER ------- */}
              < Select
                label='Водитель'
                id='driver'
                name='driver'
                value={form.driver}
                options={getSelectOptions(drivers)}
                searchable={true}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DRIVER SALARY ------- */}
              <NumberInput
                label='Зарплата водителя'
                name='driverSalary'
                id='driverSalary'
                value={form.driverSalary || ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={false}
              />
              {/* ------- FINE ------- */}
              <NumberInput
                label='Штраф'
                name='fine'
                id='fine'
                value={form.fine || ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DOCUMENTS SENT ------- */}
              <DateInput
                label='Док-ты отправлены'
                name='documentsSent'
                id='documentsSent'
                value={form.documentsSent ? moment(form.documentsSent).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
              {/* ------- DOCUMENTS RECEIVED ------- */}
              <DateInput
                label='Док-ты получены'
                name='documentsReceived'
                id='documentsReceived'
                value={form.documentsReceived ? moment(form.documentsReceived).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DUE DATE ------- */}
              <DateInput
                label='Срок оплаты'
                name='dueDate'
                id='dueDate'
                value={form.dueDate ? moment(form.dueDate).format('yyyy-MM-DD') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
              <LayoutGap />
            </RowWrapper>
          </SectionWrapper>
          <Divider />

          <SectionWrapper $maxWidth={700}>
            <RowWrapper>
              {/* ------- IS HIRED ------- */}
              <Checkbox
                label='Привлечен.'
                name='isHired'
                id='isHired'
                size='24px'
                checked={form.isHired ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
              {/* ------- CARRIER PAYMENT TYPE ------- */}
              <Select
                label='Форма опл. перевозчика'
                name='carrierPaymentType'
                id='carrierPaymentType'
                value={form.isHired ? form.carrierPaymentType : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                options={carrierPaymentTypeSelectOptions}
                disabled={!form.isHired ? true : false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CARRIER ------- */}
              < Select
                label='Перевозчик'
                id='carrier'
                name='carrier'
                value={form.isHired ? form.carrier : ''}
                options={getSelectOptions(carriers)}
                searchable={true}
                disabled={!form.isHired ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- HIRED DRIVER ------- */}
              < Select
                label='Водитель перевозчика'
                id='hiredDriver'
                name='hiredDriver'
                value={form.isHired ? form.hiredDriver : ''}
                options={getSelectOptions(hiredDrivers)}
                searchable={true}
                disabled={!form.isHired ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- HIRED CAR ------- */}
              < Select
                label='Автомобиль перевозчика'
                id='hiredCar'
                name='hiredCar'
                value={form.isHired ? form.hiredCar : ''}
                options={getSelectOptions(hiredCars)}
                searchable={true}
                disabled={!form.isHired ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CARRIER ORDER NUMBER ------- */}
              <TextInput
                label='Заявка-перевозчика №'
                id='carrierOrderNumber'
                name='carrierOrderNumber'
                value={form.isHired ? form.carrierOrderNumber : ''}
                debounceDelay={debounceDelay}
                isClearable={false}
                disabled={!form.isHired ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CARRIER DOCUMENTS RECEIVED ------- */}
              <DateInput
                label='Дата получения документов'
                name='carrierDocumentsReceived'
                id='carrierDocumentsReceived'
                value={form.isHired ? (form.carrierDocumentsReceived ? moment(form.carrierDocumentsReceived).format('yyyy-MM-DD') : '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                disabled={!form.isHired ? true : false}
              />
              {/* ------- CARRIER DUE DATE ------- */}
              <DateInput
                label='Срок оплаты перевозчику'
                name='carrierDueDate'
                id='carrierDueDate'
                value={form.isHired ? (form.carrierDueDate ? moment(form.carrierDueDate).format('yyyy-MM-DD') : '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                disabled={!form.isHired ? true : false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- PAYMENT DATE ------- */}
              <DateInput
                label='Дата оплаты'
                name='paymentDate'
                id='paymentDate'
                value={form.isHired ? (form.paymentDate ? moment(form.paymentDate).format('yyyy-MM-DD') : '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                disabled={!form.isHired ? true : false}
              />
              {/* ------- PAYMENT AMOUNT ------- */}
              <NumberInput
                label='Сумма оплаты'
                name='paymentAmount'
                id='paymentAmount'
                value={form.isHired ? (form.paymentAmount || '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={false}
                disabled={!form.isHired ? true : false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- DEBT ------- */}
              <NumberInput
                label='Задолженность'
                name='debt'
                id='debt'
                value={form.isHired ? (form.debt || '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={true}
                disabled={!form.isHired ? true : false}
              />
              {/* ------- BALANCE ------- */}
              <NumberInput
                label='Сальдо'
                name='balance'
                id='balance'
                value={form.isHired ? (form.balance || '') : ''}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm, setChangedField)
                }}
                debounceDelay={debounceDelay}
                readOnly={true}
                disabled={!form.isHired ? true : false}
              />
            </RowWrapper>
          </SectionWrapper>

          <Divider />
          {/* ------- COMMENT ------- */}
          <CommentWrapper>
            <Textarea
              label='Комментарий'
              name='comment'
              id='comment'
              value={form.comment}
              disabled={false}
              onChange={(e) => {
                Library.changeHandler(e, form, setForm, setChangedField)
              }}
              debounceDelay={debounceDelay}
            />
          </CommentWrapper>
          <ButtonsBlock
            handler={Library.handler}
            form={form}
            edit={params.id ? true : false}
            label={Library.findNameByKey(customers, form.customer)}
            message={message}
            fileFolder={pageName}
            path='data'
            request={pageName}
            changeHistoryTableName={`${companyId}_change_history`}
            changeHistoryId={form.id}
            renderChangeDetails={renderChangeDetails}
          />
        </FormWrapper >}
    </Wrapper>
  )
}
