import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import { useAuth } from '../hooks/useAuth'
import { useSelector } from 'react-redux'
import SocketListener from '../components/SocketListener'
import Library from '../Library'

const SocketContext = createContext()

export const useSocketContext = () => {
  return useContext(SocketContext)
}

export const SocketProvider = ({ children }) => {
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const socketRef = useRef(null)
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
  const serverUrl = (process.env.NODE_ENV === 'development' && protocol === 'ws') ? `${process.env.REACT_APP_DEV_URL}:${process.env.REACT_APP_DEV_PORT}` : window.location.origin
  const { isAuthenticated } = useAuth()
  const [isSocketInitialized, setIsSocketInitialized] = useState(false)

  const attachSocketEvents = () => {
    socketRef.current.on('disconnect', () => {
      // console.log('Socket disconnected')
    })

    socketRef.current.on('reconnect', () => {
      // console.log('Socket reconnected')
    })

    socketRef.current.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`)
    })

    // Listen for room_created event
    socketRef.current.on('room_created', (data) => { // <-- For debugging
      // console.log('New room created:', data.id)
    })

    // Additional event listeners can go here
  }

  const handleVisibilityChange = () => {
    // console.log('Visibility state:', document.visibilityState) // <-- For debugging
    if (document.visibilityState === 'visible') {
      if (isAuthenticated) {
        if (socketRef.current && !socketRef.current.connected) {
          socketRef.current.connect()
          attachSocketEvents()
        } else if (!socketRef.current) {
          socketRef.current = io(serverUrl, {
            'force new connection': true,
            reconnectionAttempts: 'Infinity',
            timeout: 10000,
            transports: ['websocket'],
            withCredentials: true,
          })
          attachSocketEvents()
        }
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated || !isDataLoaded) {
      if (socketRef.current) {
        socketRef.current.disconnect()
      }
      setIsSocketInitialized(false)
      return
    }

    if (isSocketInitialized) return

    // console.log('process.env.NODE_ENV', process.env.NODE_ENV) // <-- For debugging
    // console.log('protocol', protocol)
    // console.log('serverUrl', serverUrl) // <-- For debugging
    // console.log('init socket') // <-- For debugging

    socketRef.current = io(serverUrl, {
      'force new connection': true,
      reconnectionAttempts: 'Infinity',
      timeout: 10000,
      transports: ['websocket'],
      withCredentials: true,
    })

    attachSocketEvents()
    document.addEventListener('visibilitychange', handleVisibilityChange)
    setIsSocketInitialized(true)

    return () => {
      if (socketRef.current) {
        socketRef.current.off()
        socketRef.current.disconnect()
      }
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverUrl, isAuthenticated, isDataLoaded])

  const handleActivity = () => {
    if (isAuthenticated) {
      if (socketRef.current && !socketRef.current.connected) {
        socketRef.current.connect()
        attachSocketEvents()
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleActivity()
    }, 5000) // Check every 5 seconds

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const throttledHandleActivity = Library.throttle(handleActivity, 2000)

  useEffect(() => {
    window.addEventListener('mousemove', throttledHandleActivity)
    window.addEventListener('click', handleActivity)

    return () => {
      window.removeEventListener('mousemove', throttledHandleActivity)
      window.removeEventListener('click', handleActivity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return (
    <SocketContext.Provider value={socketRef.current}>
      {children}
      {isSocketInitialized && <SocketListener />}
    </SocketContext.Provider>
  )
}

export default SocketContext