import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../context/ModalContext'
import Button from '../../../components/Buttons/Button'
import styled from 'styled-components'
import TableComponent from '../../../components/Tables/TableComponent'

const Wrapper = styled.div`
  @media only screen and (min-width: 600px) {
    overflow-y: auto;
    max-height: 250px;
    width: 450px;
  }
`

export const TotalRouteCost = (props) => {
  const { routes, filteredRoutes, activeIndex } = props
  const [activeRowData, setActiveRowData] = useState()
  const [elExists, setElExists] = useState(false)
  const { closeModal } = useContext(ModalContext)

  useEffect(() => {
    if (activeIndex !== -1) {
      const id = filteredRoutes[activeIndex].id
      if (id) {
        const data = routes.filter(route => route.id === id)[0]
        setActiveRowData(data)
        setElExists(true)
      }
    }
  }, [activeIndex, filteredRoutes, routes])

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)

  useEffect(() => {
    if (activeRowData) {
      let obj_1 = { paymentType: 'Ндс', amount: 0, count: 0 }
      let obj_2 = { paymentType: 'Без ндс', amount: 0, count: 0 }
      const currYear = new Date(activeRowData.dateOfDeparture).getFullYear()

      routes.forEach((item) => {
        const itemYear = new Date(item.dateOfDeparture).getFullYear()

        if (itemYear === currYear) {
          if (item.accountNumber === activeRowData.accountNumber) {
            if (item.paymentType === 'Ндс') {
              obj_1.amount += parseFloat(item.routeCost)
              obj_1.count++
            }
            if (item.paymentType === 'Без ндс') {
              obj_2.amount += parseFloat(item.routeCost)
              obj_2.count++
            }
          }
        }
      })
      setTableData([obj_1, obj_2])
      setTableDataReady(true)
    }
  }, [activeRowData, routes])

  return (
    <Wrapper>
      {tableDataReady &&
        elExists ?
        (activeRowData.accountNumber ?
          <TableComponent>
            {/* -----HEADERS----- */}
            <thead>
              <tr>
                <th key={'Форма оплаты'}>Форма оплаты</th>
                <th key={'Сумма'}>Сумма</th>
                <th key={'Кол-во'}>Кол-во</th>
              </tr>
            </thead>
            <tbody>
              {/* -----ROWS----- */}
              {tableData.map((row, i) => {
                return (
                  <tr key={row.paymentType}>
                    <td>{row.paymentType}</td>
                    <td>{row.amount ? parseFloat(row.amount.toFixed()).toLocaleString() : '-'}</td>
                    <td>{row.count ? row.count.toLocaleString() : '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </TableComponent>
          : <div><h3>№ счета отсутствует...</h3></div>
        )
        : <div><h3>Выделите строку...</h3></div>
      }
      <Button
        style={{ margin: '15px 0px 0px 0px', minWidth: '100px' }}
        onClick={(e) => {
          e.preventDefault()
          closeModal()
        }}
      >
        Закрыть
      </Button>
    </Wrapper>
  )
}