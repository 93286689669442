import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '../../Icons/MenuIcon'
import { useLanguage } from '../../../context/LanguageContext'

const Wrapper = styled.nav`
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
`

const Menu = styled.ul`
  list-style: none;
  display: flex;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  opacity: 1;
  visibility: visible;

  @media (max-width: 768px) {
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 100000;
    top: 100%;
    left: 0;
    box-shadow: var(--box-shadow);
    background-color: var(--surface-color);
  }
`

const MenuItem = styled.li`
  margin: 0 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  color: #c6d0d5;

  &:hover {
    color: #FFF;
  }

  @media (max-width: 768px) {
    margin: 1rem;
  }
`


const MobileMenuWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    cursor: pointer;
  }
`

const text = {
  menuTitle: {
    en: 'Menu',
    ru: 'Меню'
  },
  link_1: {
    en: 'Link 1',
    ru: 'Ссылка 1'
  },
  link_2: {
    en: 'Link 2',
    ru: 'Ссылка 2'
  }
}

export default function SideNavigation() {
  const navigate = useNavigate()
  const menuRef = useRef(null)
  const menuIconRef = useRef(null)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { language } = useLanguage()

  const handleMenuItemClick = (path) => {
    navigate(path)
    setMobileMenuOpen(false)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
        menuIconRef.current && !menuIconRef.current.contains(event.target)) {
        setMobileMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setMobileMenuOpen])

  return (
    <Wrapper>
      <MobileMenuWrapper
        title={text.menuTitle[language]}
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        ref={menuIconRef}>
        <MenuIcon />
      </MobileMenuWrapper>
      <Menu $isOpen={mobileMenuOpen} ref={menuRef}>
        <MenuItem onClick={() => handleMenuItemClick('/path-1')}>{text.link_1[language]}</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('/path-2')}>{text.link_2[language]}</MenuItem>
      </Menu>
    </Wrapper>
  )
}
