import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import ModalContext from '../../../context/ModalContext'
import Button from '../../../components/Buttons/Button'
import Library from '../../../Library'
import styled, { css } from 'styled-components'
import useModalContentSize from '../../../hooks/useModalContentSize'
import TableComponent from '../../../components/Tables/TableComponent'
import { useTheme } from '../../../context/ThemeContext'
import ResultInput from '../../../components/Inputs/ResultInput'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
`

export const ShowPayments = (props) => {
  const { filterQuery, expenses, drivers } = props
  const { theme } = useTheme()
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)
  const [total, setTotal] = useState(0)
  const driver = filterQuery.driver
  const period = filterQuery.startDate ? `${new Date(filterQuery.startDate).getFullYear()}-${(new Date(filterQuery.startDate).getMonth() + 1).toString().length < 2 ? '0' + (new Date(filterQuery.startDate).getMonth() + 1) : new Date(filterQuery.startDate).getMonth() + 1}` : ''

  useEffect(() => {
    let data = []
    let totalSum = 0
    function rowData(item) {
      return {
        date: item.date ? item.date : '',
        driver: item.bind ? item.bind : '',
        amount: item.amount ? item.amount : '',
        period: item.period ? Library.replacePeriod(item.period) : '',
        comment: item.comment ? item.comment : '',
      }
    }
    expenses.forEach((item) => {

      if (driver && period) {
        if (item.bindingType === 'Водитель' && item.bind === driver && item.period === period) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else if (!driver && period) {
        if (item.bindingType === 'Водитель' && item.period === period) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else if (driver && !period) {
        if (item.bindingType === 'Водитель' && item.bind === driver) {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      } else {
        if (item.bindingType === 'Водитель') {
          totalSum += item.amount || 0
          data.push(rowData(item))
        }
      }
    })
    if (data.length) {
      setTableData(data)
    } else {
      setTableData([{
        date: '',
        driver: '-',
        amount: '-',
        period: '-',
        comment: '-',
      }])
    }
    setTotal(totalSum)
    setTableDataReady(true)
  }, [filterQuery, driver, expenses, period])

  return (
    <Wrapper $isLoading={!tableDataReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <TableComponent columnWidths={['5%', '10%', '30%', '10%', '20%', '25%']} tableWidth={modalContentWidth} isLoading={!tableDataReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key={'№'}>№</th>
            <th key={'Дата'}>Дата</th>
            <th key={'Водитель'}>Водитель</th>
            <th key={'Сумма'}>Сумма</th>
            <th key={'Период'}>Период</th>
            <th key={'Комментарий'}>Комментарий</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {!tableData || tableData.length === 0 ?
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            :
            tableData.map((row, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row.date ? moment(row.date).format('DD.MM.yyyy') : '-'}</td>
                  <td>{Library.findNameByKey(drivers, row.driver)}</td>
                  <td><span style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}>{row.amount.toLocaleString()}</span></td>
                  <td>{row.period}</td>
                  <td>{row.comment}</td>
                </tr>
              )
            })}
        </tbody>
      </TableComponent>
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <Button
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        >
          Закрыть
        </Button>
        <div style={{ width: '120px' }}>
          <ResultInput
            value={`${total?.toLocaleString() || 0} Руб.`}
            forceDarkMode={true}
            borderOption={3}
          />
        </div>
      </FooterWrapper>
    </Wrapper>
  )
}