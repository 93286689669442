const initialState = {
  id: null,
  email: null,
  username: null,
  roles: [],
  settings: {
    colorTheme: {
      value: 'ghostlyWaves',
      label: {
        en: 'Ghostly Waves',
        ru: 'Призрачные волны'
      },
      colors: {
        primaryColor: {
          hex: '#4d7183',
          description: {
            en: 'Main color for the interface',
            ru: 'Основной цвет интерфейса'
          }
        },
        secondaryColor: {
          hex: '#648CA5',
          description: {
            en: 'Secondary color for the interface',
            ru: 'Вторичный цвет интерфейса'
          }
        },
        activeTabColor: {
          hex: '#7EA2BD',
          description: {
            en: 'Color for the active tab in the interface',
            ru: 'Цвет активной вкладки в интерфейсе'
          }
        }
      }
    },
    sound: {
      globalToggle: false,
      soundTheme: {
        login: {
          soundFile: 'login/login-01.mp3',
          enabled: true,
          volume: 0.5
        },
        logout: {
          soundFile: 'logout/logout-01.mp3',
          enabled: true,
          volume: 0.5
        },
        tab: {
          soundFile: 'tab/tab-01.mp3',
          enabled: true,
          volume: 0.5
        },
        button: {
          soundFile: 'button/button-01.mp3',
          enabled: true,
          volume: 0.5
        },
        notification: {
          soundFile: 'notification/notification-01.mp3',
          enabled: true,
          volume: 0.5
        }
      }
    }
  },
  accessEnabled: true,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_ID':
      return {
        ...state,
        id: action.payload,
      }
    case 'SET_USER_EMAIL':
      return {
        ...state,
        email: action.payload,
      }
    case 'SET_USERNAME':
      return {
        ...state,
        username: action.payload,
      }
    case 'SET_TELEGRAM_ID':
      return {
        ...state,
        telegramId: action.payload,
      }
    case 'SET_TELEGRAM_NOTIFICATIONS':
      return {
        ...state,
        telegramNotifications: action.payload,
      }
    case 'SET_USER_ROLES':
      return {
        ...state,
        roles: action.payload,
      }
    case 'SET_TWO_STEP_VERIFICATION':
      return {
        ...state,
        twoStepVerification: action.payload,
      }
    case 'SET_USER_SETTINGS':
      return {
        ...state,
        settings: action.payload ? action.payload : initialState.settings,
      }
    case 'SET_USER_ACCESS_ENABLED':
      return {
        ...state,
        accessEnabled: action.payload,
      }
    case 'LOGOUT':
      return {
        ...initialState,
      }
    default:
      return state
  }
}
