import { useSelector } from 'react-redux'
import { useMemo } from 'react'

export function useColorTheme() {
  const colorTheme = useSelector((state) => state.auth.settings.colorTheme.colors)

  const memoizedColorTheme = useMemo(() => {
    const { primaryColor, secondaryColor, activeTabColor } = colorTheme
    return {
      primaryColor: primaryColor.hex,
      secondaryColor: secondaryColor.hex,
      activeTabColor: activeTabColor.hex,
    }
  }, [colorTheme])

  return memoizedColorTheme
}
