import React from 'react'
import styled, { css } from 'styled-components'
import { useTheme } from '../../context/ThemeContext'

const Svg = styled.svg`
  transition: transform 0.1s ease;

  ${({ $isHovered, $isActive }) => ($isHovered || $isActive) ? css`
    transform: scale(1.03);
  ` : css`
    transform: scale(1);
  `}
`

export default function ArrowIncome({ size = 24, isActive = false, isHovered = false }) {
  const { theme } = useTheme()
  return (
    <Svg xmlns='http://www.w3.org/2000/svg' width={`${size}px`} height={`${size}px`} viewBox='0 0 24 24' fill='none' $isHovered={isHovered} $isActive={isActive}>
      <path
        d='M12.9999 21.9994C17.055 21.9921 19.1784 21.8926 20.5354 20.5355C21.9999 19.0711 21.9999 16.714 21.9999 12C21.9999 7.28595 21.9999 4.92893 20.5354 3.46447C19.071 2 16.714 2 11.9999 2C7.28587 2 4.92884 2 3.46438 3.46447C2.10734 4.8215 2.00779 6.94493 2.00049 11'
        strokeWidth='2'
        strokeLinecap='round'
        stroke={theme === 'light' ?
          ((isActive) ? '#fff' : (isHovered ? 'var(--main-color)' : 'var(--grey-color)'))
          :
          ((isActive || isHovered) ? 'var(--list-text-color)' : 'var(--grey-color)')}
      />
      <path
        d='M3 21L11 13M11 13H5M11 13V19'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke={theme === 'light' ?
          (isActive ? 'var(--green-lighten-color)' : 'green')
          :
          'var(--green-lighten-color)'}
      />
    </Svg>
  )
}