import React, { useEffect } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import styled from 'styled-components'
import { useTheme } from '../../../context/ThemeContext'

const Wrapper = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  height: 50px;
  gap: 10px;
  align-items: center;
`

const ResultInputWrapper = styled.div`
min-width: 130px;
max-width: 130px;
`

export const ExpensesSummary = (props) => {
  const { filteredExpenses, filterQuery, expensesTotal, setExpensesTotal } = props
  const { theme } = useTheme()

  //=== SET VALUES ===//
  useEffect(() => {
    let val = 0
    filteredExpenses.forEach((item) => {
      val += parseFloat(item.amount || 0)
    })
    setExpensesTotal(val)
  }, [filterQuery, filteredExpenses, setExpensesTotal])

  return (
    <Wrapper>
      {/* ------- EXPENSES COUNT ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'blue' : 'var(--blue-lighten-color)' }}
          label='Кол-во'
          name='expensesCount'
          id='expensesCount'
          value={filteredExpenses.length ? filteredExpenses.length.toLocaleString() : '0'}
          readOnly={true}
        />
      </ResultInputWrapper>
      {/* ------- TOTAL EXPENSES AMOUNT ------- */}
      <ResultInputWrapper>
        <TextInput
          style={{ color: theme === 'light' ? 'green' : 'var(--green-lighten-color)' }}
          label='Стоимость'
          name='totalExpensesCost'
          id='totalExpensesCost'
          value={expensesTotal ? expensesTotal.toLocaleString() + ' р.' : '0'}
          readOnly={true}
        />
      </ResultInputWrapper>
    </Wrapper>
  )
}