import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalContext from '../context/ModalContext'
import { resetDataState, setCompany } from '../store/actions/dataActions'
import styled from 'styled-components'
import Checkbox from './Inputs/Checkbox'
import Button from './Buttons/Button'
import { setDataLoaded } from '../store/actions/loadingActions'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from './Loaders/LoadingSpinner'
import { useAuth } from '../hooks/useAuth'
import useRouteParams from '../hooks/useRouteParams'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: clamp(100%, auto, 350px);
  min-width: 280px;
  @media (max-width: 768px) {
    padding: 0px 8px 0px 8px;
  }
`

const ButtonWrapper = styled.div`
  margin: 10px 0px 0px 10px;
  display: flex;
  column-gap: 10px;
  text-align: left;
  justify-content: flex-end;
`

const ItemWrapper = styled.div`
  padding: 3px;
  color: black;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: var(--background-tertiary-color);
  }
`

const Divider = styled.div`
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
  margin: 5px 0px 5px 0px;
`

export default function CompanyPicker() {
  const dispatch = useDispatch()
  const { isAdmin, roles } = useAuth()
  const { companyId } = useSelector(state => state.data)
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const { closeModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const paramsId = useRouteParams()

  const companies = [
    { label: 'Логистика', value: 'logistics' },
    { label: 'Лидер спец транс', value: 'leaderSpecTrans' },
    { label: 'Ип Карпов', value: 'ipKarpov' }
  ]

  const handleCompanySelect = (companyValue) => {
    const currentPath = window.location.pathname.split('/')[1]
    const hasRoleForCompanyAndPath = roles.some(role =>
      role.companyId === companyValue && role.value === currentPath
    )

    if (companyId !== companyValue) {
      if ((!isAdmin && !hasRoleForCompanyAndPath) || paramsId) {
        navigate('/')
      }
      dispatch(setDataLoaded(false))
      dispatch(resetDataState())
      dispatch({ type: 'RESET_ALL_PAGES_STATE' })
      setTimeout(() => {
        dispatch(setCompany(companyValue))
        localStorage.setItem('last-selected-company', companyValue)
      }, 0)
    }
  }

  return (
    <Wrapper>
      <h3>Выберите организацию:</h3>
      <Divider />
      {companies.map((company, i) => (
        <ItemWrapper key={`item_wrapper_${i}`}>
          <Checkbox
            key={company.value}
            name='company'
            id={company.value}
            size='24px'
            checked={companyId === company.value}
            onChange={() => handleCompanySelect(company.value)}
            label={company.label}
            disabled={!isDataLoaded}
          />
          {!isDataLoaded && companyId === company.value && <LoadingSpinner key={`loading_spinner_${i}`} borderWidth={3} speed={1} />}
        </ItemWrapper>
      ))}
      <Divider />
      <ButtonWrapper>
        <Button
          style={{ minWidth: '130px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          Закрыть
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}
