import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Button from '../../../components/Buttons/Button'
import ModalContext from '../../../context/ModalContext'
import { useDispatch, useSelector } from 'react-redux'
import useModalContentSize from '../../../hooks/useModalContentSize'
import { fetchExpenses } from '../../../store/actions/dataActions'
import TableComponent from '../../../components/Tables/TableComponent'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`

export const FuelBalance = ({ refueling, suppliers }) => {
  const dispatch = useDispatch()
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const [dataReady, setDataReady] = useState(false)

  const { expenses, loading, error } = useSelector(state => state.data)

  //=== SUPPLIERS ===//
  const [involvedSuppliers, setInvolvedSuppliers] = useState([])
  const [involvedSuppliersReady, setInvolvedSuppliersReady] = useState(false)
  useEffect(() => {
    if (dataReady) {
      let arr = []
      refueling.forEach((item) => {
        if (item.supplier && arr.indexOf(item.supplier) === -1) {
          arr.push(item.supplier)
        }
      })
      setInvolvedSuppliers(arr)
      setInvolvedSuppliersReady(true)
    }
  }, [dataReady, refueling])

  //=== PREPARE TABLE DATA ===//
  const [tableData, setTableData] = useState()
  const [tableDataReady, setTableDataReady] = useState(false)
  useEffect(() => {
    if (dataReady && involvedSuppliersReady) {
      let data = []
      involvedSuppliers.forEach((item) => {
        let obj = { name: findNameByKey(suppliers, item) }
        let received = 0
        expenses.forEach((row) => {
          if (row.bind === item) {
            received += row.amount || 0
          }
        })
        obj['received'] = received
        let spent = 0
        refueling.forEach((row) => {
          if (row.supplier === item && row.payType !== 'Нал') {
            spent += row.amount || 0
          }
        })
        obj['spent'] = spent
        obj['balance'] = (received - spent).toFixed()
        data.push(obj)
      })
      setTableData(data)
      setTableDataReady(true)
    }
  }, [dataReady, involvedSuppliersReady, expenses, refueling, involvedSuppliers, suppliers])

  //=====FIND NAME BY KEY=====//
  function findNameByKey(arr, id) {
    let name = ''
    arr.forEach((item) => {
      if (item.id === id) {
        name = item.name
      }
    })
    return name
  }

  useEffect(() => {
    if (!loading && !error) {
      if (expenses.length) {
        setDataReady(true)
      } else if (!expenses.length) {
        dispatch(fetchExpenses())
      }
    }
  }, [dispatch, loading, error, expenses])

  return (
    <Wrapper $isLoading={!tableDataReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <TableComponent columnWidths={['49%', '17%', '17%', '17%']} tableWidth={modalContentWidth} isLoading={!tableDataReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key={'Поставщик'}>Поставщик</th>
            <th key={'Поступления'}>Поступления</th>
            <th key={'Затраты'}>Затраты</th>
            <th key={'Остаток'}>Остаток</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {!tableData || tableData.length === 0 ?
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            :
            tableData.map((row) => {
              return (
                <tr key={row.name}>
                  <td>{row.name.toLocaleString()}</td>
                  <td>{row.received.toLocaleString()}</td>
                  <td>{row.spent.toLocaleString()}</td>
                  <td>{row.balance > 0 ? parseFloat(row.balance).toLocaleString() : <span style={{ color: 'red' }}>{parseFloat(row.balance).toLocaleString()}</span>}</td>
                </tr>
              )
            })
          }
        </tbody>
      </TableComponent>
      {/* ------------(CLOSE BUTTON)------------ */}
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <Button
          style={{ minWidth: '130px' }}
          value=''
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          Закрыть
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}