import React, { useContext, useState } from 'react'
import Library from '../../../Library'
import { useMessage } from '../../../hooks/useMessage'
import Button from '../../../components/Buttons/Button'
import styled from 'styled-components'
import TextInput from '../../../components/Inputs/TextInput'
import ModalContext from '../../../context/ModalContext'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'

const Wrapper = styled.div`
  position: relative;
  width: 420px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: 300px;
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0px 0px 0px
`

const UserWrapper = styled.div`
  font-size: 20px;
  color: var(--text-tertiary-color);
  margin: 0px 0px 5px 0px;
  white-space: normal;
  overflow-wrap: break-word;
`

const ButtonsWrapper = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`

const UserEmail = styled.span`
  color: var(--main-color);
  font-weight: bold;
`

export default function ChangePassword({ currUser }) {
  const { language } = useLanguage()
  const translate = useTranslate()
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [form, setForm] = useState({
    id: currUser.id,
    email: currUser.email,
    password: '',
  })

  const handler = (e) => {
    e.preventDefault()
    setSaveButtonDisabled(true)
    Library.handler({
      e,
      path: 'auth',
      request: 'password',
      form,
      message: message,
      edit: true,
      onSuccess: () => closeModal(),
      onFail: () => {
        setTimeout(() => {
          setSaveButtonDisabled(false)
        }, 500)
      },
      language
    })
  }

  function truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str
  }

  return (
    <Wrapper>
      <UserWrapper>{translate('user')}:&nbsp;<UserEmail>{truncate(currUser.email, 50)}</UserEmail></UserWrapper>
      {/* ------- password ------- */}
      <InputWrapper>
        <TextInput
          label={translate('newPassword')}
          id='password'
          name='password'
          type='password'
          value={form.password}
          isClearable={false}
          debounceDelay={200}
          onChange={(e) => {
            Library.changeHandler(e, form, setForm)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handler(e)
            }
          }}
        />
      </InputWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            minWidth: '130px',
            maxWidth: '130px',
          }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{ minWidth: '130px', maxWidth: '130px', }}
          disabled={saveButtonDisabled}
          onClick={handler}
        >
          {translate('save')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}