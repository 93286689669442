import React, { useCallback, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'

const DateInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Label = styled.label`
  position: absolute;
  top: -15px;
  line-height: 1rem;
  font-size: 0.8rem;
  width: 100%;
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  transform-origin: 0% 100%;
  transition: color 0.2s;

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const Input = styled.input`
  background-color: ${({ readOnly }) => (readOnly ? 'var(--input-read-only-bg)' : 'transparent')};
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  height: 2.9rem;
  margin: 0 0 8px;
  font-size: 16px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--input-text-color);
  transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}

    ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
          border-bottom: 1px dotted var(--text-color-disabled);
        `
      : $forceDarkMode
        ? css`
          color: #FFFFFF;
          &::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `
        : css`
          html[data-theme='dark'] & {
            color: #FFFFFF;
            &::-webkit-calendar-picker-indicator {
              filter: invert(1);
            }
          }
        `
  }
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-right: 2px;
  color: hsl(0, 0%, 80%);
  font-size: 4px;
  line-height: 1;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
`

export default function DateInput(props) {
  const { label, value = '', name = '', id, type = 'date', forceDarkMode = false, borderOption = 1, debounceDelay, onChange, onClick, onBlur, onFocus, readOnly, disabled } = props
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')
  const [debouncedHandleChange, isDebouncing] = useDebounce(onChange, debounceDelay)

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleClick = useCallback(
    (e) => {
      if (onClick) {
        onClick(e)
      }
    },
    [onClick]
  )

  const handleBlur = useCallback(
    (e) => {
      setIsFocused(false)
      if (onBlur) {
        onBlur(e)
      }
    },
    [onBlur]
  )

  const handleChange = useCallback(
    (e) => {
      setInputValue(e.target.value)

      if (typeof onChange === 'function') {
        if (debounceDelay) {
          debouncedHandleChange(e)
        } else {
          onChange(e)
        }
      }
    },
    [onChange, debounceDelay, debouncedHandleChange]
  )

  const handleFocus = useCallback(
    (e) => {
      setIsFocused(true)
      if (onFocus) {
        onFocus(e)
      }
    },
    [onFocus]
  )

  const handleDelete = useCallback(
    (e) => {
      if (!readOnly && e.target.value !== '') {
        e.target.value = ''
        if (onChange) {
          onChange(e)
        }
      }
    },
    [readOnly, onChange]
  )

  return (
    <DateInputWrapper>
      <Label
        htmlFor={id}
        disabled={disabled}
        title={label}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
      >
        {label}
      </Label>
      {isDebouncing &&
        <LoadingIndicator>
          <span></span>
          <span></span>
          <span></span>
        </LoadingIndicator>}
      <Input
        name={name}
        id={props.id}
        type={type}
        value={inputValue}
        disabled={disabled}
        readOnly={readOnly}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Delete') {
            handleDelete(e)
          }
        }}
        $isFocused={isFocused}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $forceDarkMode={forceDarkMode}
      />
    </DateInputWrapper>
  )
}
