import React, { useContext, useState } from 'react'
import { useMessage } from '../../../hooks/useMessage'
import Library from '../../../Library'
import Checkbox from '../../../components/Inputs/Checkbox'
import styled from 'styled-components'
import Button from '../../../components/Buttons/Button'
import ModalContext from '../../../context/ModalContext'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'

const Wrapper = styled.div`
  width: 690px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-width: 300px;
  }
`

const User = styled.p`
  width: 100%;
  font-size: 20px;
  color: var(--text-tertiary-color);
  margin: 10px 0px 10px 0px;
`

const UserEmail = styled.span`
  color: var(--main-color);
  font-weight: bold;
`

const FormWrapper = styled.form`
  height: 350px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #9e9e9e;
  padding: 10px 10px 15px 15px;
`

const ButtonsWrapper = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  width: 100%;
`

export default function EditRoles(params) {
  const { currUser, roles, users } = params
  const { language } = useLanguage()
  const translate = useTranslate()
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const userRoles = JSON.parse(users.filter(user => user.id === currUser.id)[0].roles)
  const [form, setForm] = useState({ id: currUser.id, roles: userRoles })

  const [previousRoles, setPreviousRoles] = useState([])

  const handleChange = (role, isChecked) => {
    // Check if the role being changed is 'admin'
    if (role.value === 'admin') {
      if (isChecked) {
        // Save current roles before setting all to checked
        setPreviousRoles(form.roles)
        // Set all roles to checked
        setForm({ ...form, roles: roles })
      } else {
        // Restore the roles to the state before 'admin' was checked
        setForm({ ...form, roles: previousRoles })
      }
    } else {
      // Handle changes for non-admin roles as before
      setForm(prevForm => {
        let newRoles = [...prevForm.roles]
        if (isChecked) {
          newRoles.push(role)
        } else {
          newRoles = newRoles.filter(r => r.value !== role.value || r.companyId !== role.companyId)
        }
        return { ...prevForm, roles: newRoles }
      })
    }
  }

  const handler = (e) => {
    e.preventDefault()
    setSaveButtonDisabled(true)

    Library.handler({
      e,
      path: 'auth',
      request: 'roles',
      form,
      message,
      edit: true,
      onSuccess: () => {
        closeModal()
      },
      onFail: () => {
        setTimeout(() => {
          setSaveButtonDisabled(false)
        }, 500)
      },
      language
    })
  }

  return (
    <Wrapper>
      <User>{translate('userRoles')}: <UserEmail>{Library.truncate(currUser.email, 44)}</UserEmail></User>
      <FormWrapper>
        {roles.map((item, i) => {
          const isChecked = form.roles.some(role => role.value === item.value && (role.companyId ? role.companyId === item.companyId : true))
          const isDisabled = (item.value !== 'admin' && form.roles.some(role => role.value === 'admin')) ? true : false
          return (
            <div className='row' key={item.value + i}>
              <Checkbox
                label={item.label}
                name={item.value + i}
                id={item.value + i}
                color={item.value === 'admin' ? '#E57373' : ''}
                size='22px'
                disabled={isDisabled}
                checked={isChecked}
                onChange={(e) => handleChange(item, e.target.checked)}
              />
            </div>
          )
        })}
      </FormWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            minWidth: '140px',
          }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{ minWidth: '140px' }}
          disabled={saveButtonDisabled}
          onClick={handler}
        >
          {translate('save')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}