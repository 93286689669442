import React, { useContext, useEffect, useState } from 'react'
import PostService from '../API/PostService'
import { useFetching } from '../hooks/useFetching'
import { useMessage } from '../hooks/useMessage'
import Button from './Buttons/Button'
import styled from 'styled-components'
import ModalContext from '../context/ModalContext'
import { useLanguage } from '../context/LanguageContext'

const Wrapper = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 350px;
  height: 283px;
`

const InfoWrapper = styled.div`
  position: relative;
  min-width: 300px;
  max-width: 350px;
  height: 247px;
`

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--main-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`

const Divider = styled.div`
  height: 1px;
  overflow: hidden;
  background-color: var(--line-color);
  margin: 10px 0px 10px 0px;
`

const Progress = styled.progress`
  border: none;
  width: 100%;
  height: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);

  &::-webkit-progress-value {
    background: var(--main-color);
    border-radius: 2px;
  }

  &::-moz-progress-bar {
    background: var(--main-color);
    border-radius: 2px;
  }

  &.red::-webkit-progress-value {
    background: var(--main-color);
    border-radius: 2px;
  }

  &.blue::-webkit-progress-value {
    background: var(--main-color);
    border-radius: 2px;
  }

  &::-webkit-progress-bar {
    background-color: var(--grey-color);
    html[data-theme='dark'] & {
      background: var(--grey-color);
    }
    border-radius: 2px;
  }
`
const LabelWrapper = styled.div`
  display: flex;
`
const Label = styled.div`
  text-align: left;
  color: var(--text-secondary-color);
  font-weight: bold;
`

const Amount = styled.span`
  margin-left: auto;
  color: var(--text-secondary-color);
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  margin: 10px 0px 0px 10px;
  display: flex;
  column-gap: 10px;
  text-align: left;
  justify-content: flex-end;
`

export default function ServerInfo() {
  const { closeModal } = useContext(ModalContext)
  const { language } = useLanguage()
  const message = useMessage()

  const [serverInfoLoaded, setServerInfoLoaded] = useState(false)
  const [serverInfo, setServerInfo] = useState({})
  const [fetchServerInfo, , error] = useFetching(async () => {
    const response = await PostService.get('data', 'server-info', {}, language)
    if (response && !response.errors) {
      message(response.message)
    }
    setServerInfo(response.data)
    setServerInfoLoaded(true)
    error && console.log(error)
  })

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  useEffect(() => {
    fetchServerInfo()
    const intervalId = setInterval(() => {
      fetchServerInfo()
    }, 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <InfoWrapper>
        {
          serverInfoLoaded ?
            <>
              {/* ------------(SERVER UP TIME)------------ */}
              <LabelWrapper>
                <Label>SERVER UPTIME&nbsp;:</Label>
                <Amount>
                  <span>{new Date(serverInfo.upTime * 1000).toISOString().slice(11, 19)}</span>
                </Amount>
              </LabelWrapper>
              <Divider />
              {/* ------------(CPU)------------ */}
              <LabelWrapper>
                <Label>CPU&nbsp;:</Label>
                <Amount>
                  <span>{`${serverInfo.cpuUsage ? serverInfo.cpuUsage.toFixed() : 'N/A'}% - ${serverInfo.cpuTemp ? serverInfo.cpuTemp.toFixed() : 'N/A'} °C`}</span>
                </Amount>
              </LabelWrapper>
              <Progress
                id='cpuUsage'
                max={100}
                value={serverInfo.cpuUsage || 0}
              />
              <Divider />
              {/* ------------(RAM)------------ */}
              <LabelWrapper>
                <Label>RAM&nbsp;:</Label>
                <Amount>
                  <span>{formatBytes(serverInfo['RAM'] ? (serverInfo['RAM'].totalRAM - serverInfo['RAM'].freeRAM) : 0)}</span>
                  &nbsp;
                  <span>/</span>
                  &nbsp;
                  <span>{formatBytes(serverInfo['RAM'] ? serverInfo['RAM'].totalRAM : 0)}</span>
                </Amount>
              </LabelWrapper>
              <Progress
                id='memory'
                max={serverInfo['RAM'] ? serverInfo['RAM'].totalRAM : 0}
                value={serverInfo['RAM'] ? (serverInfo['RAM'].totalRAM - serverInfo['RAM'].freeRAM) : 0}
              />
              <Divider />
              {/* ------------(DISK)------------ */}
              <LabelWrapper>
                <Label>DISK&nbsp;:</Label>
                <Amount>
                  <span>{formatBytes(serverInfo.diskSpace ? (serverInfo.diskSpace.size - serverInfo.diskSpace.free) : 0)}</span>
                  &nbsp;
                  <span>/</span>
                  &nbsp;
                  <span>{formatBytes(serverInfo.diskSpace ? serverInfo.diskSpace.size : 0)}</span>
                </Amount>
              </LabelWrapper>
              <Progress
                id='diskSpace'
                max={serverInfo.diskSpace ? serverInfo.diskSpace.size : 0}
                value={serverInfo.diskSpace ? (serverInfo.diskSpace.size - serverInfo.diskSpace.free) : 0}
              />
              <Divider />
            </>
            :
            <Loader />
        }
      </InfoWrapper>
      {/* ------------(CLOSE BUTTON)------------ */}
      <ButtonWrapper>
        <Button
          style={{ width: '130px' }}
          id='cancel-btn'
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          Закрыть
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}