import { useEffect, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useSoundTheme = () => {
  const authState = useSelector((state) => state.auth)
  const settings = authState.settings
  const settingsRef = useRef(settings)

  useEffect(() => {
    settingsRef.current = settings
  }, [settings])

  const playSound = useMemo(() => {
    return async (soundType) => {
      if (!settingsRef.current.sound.globalToggle || !settingsRef.current.sound.soundTheme[soundType].enabled) return Promise.resolve()

      const soundPath = settingsRef.current.sound.soundTheme[soundType].soundFile
      const audio = new Audio(`${window.location.origin}/sounds/${soundPath}`)
      audio.addEventListener('error', (e) => {
        console.error('Error playing sound:', e)
      })
      audio.volume = settingsRef.current.sound.soundTheme[soundType].volume
      return audio.play().catch((error) => {
        console.error('Failed to play sound:', error)
      })
    }
  }, [])

  const soundActions = useMemo(() => {
    return {
      loginSound: () => playSound('login'),
      logoutSound: () => playSound('logout'),
      tabSound: () => playSound('tab'),
      buttonSound: () => playSound('button'),
      notificationSound: () => playSound('notification'),
    }
  }, [playSound])

  return soundActions
}
