const initialState = {
  users: [],
  onlineUsers: {},
  employees: [],
  cars: [],
  hiredCars: [],
  hiredDrivers: [],
  customers: [],
  carriers: [],
  suppliers: [],
  routes: [],
  expenses: [],
  refueling: [],
  maintenance: [],
  inventory: [],
  stock: [],
  companyId: 'logistics',
  loading: false,
  error: null,
  isDataLoaded: {
    users: false,
    cars: false,
    hiredCars: false,
    hiredDrivers: false,
    employees: false,
    customers: false,
    carriers: false,
    suppliers: false,
    routes: false,
    expenses: false,
    refueling: false,
    maintenance: false,
    inventory: false,
    stock: false,
  }
}

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {

    // Users
    case 'FETCH_USERS_REQUEST':
      return { ...state, dataLoading: true, dataError: null }
    case 'SET_USERS':
      return { ...state, users: action.payload.sort((a, b) => a.email.localeCompare(b.email)) }
    case 'ADD_USER':
      return { ...state, users: [...state.users, action.payload].sort((a, b) => a.email.localeCompare(b.email)) }
    case 'UPDATE_USER':
      return { ...state, users: state.users.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.email.localeCompare(b.email)) }
    case 'TOGGLE_ACCESS':
      return {
        ...state,
        users: state.users.map(user =>
          user.id === action.payload.id
            ? { ...user, accessEnabled: action.payload.accessEnabled }
            : user
        ).sort((a, b) => a.email.localeCompare(b.email))
      }
    case 'DELETE_USER':
      return { ...state, users: state.users.filter(item => item.id !== action.payload.id) }
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state, dataLoading: false, isDataLoaded: {
          ...state.isDataLoaded,
          users: true
        }
      }
    case 'FETCH_USERS_FAILURE':
      return { ...state, dataLoading: false, dataError: action.error }

    // Update online users
    case 'UPDATE_ONLINE_USERS':
      return {
        ...state,
        onlineUsers: action.payload,
      }

    // Employees
    case 'FETCH_EMPLOYEES_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_EMPLOYEES':
      return { ...state, employees: action.payload }
    case 'ADD_EMPLOYEE':
      return { ...state, employees: [...state.employees, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_EMPLOYEE':
      return { ...state, employees: state.employees.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_EMPLOYEE':
      return { ...state, employees: state.employees.filter(item => item.id !== action.payload.id) }
    case 'FETCH_EMPLOYEES_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          employees: true
        }
      }
    case 'FETCH_EMPLOYEES_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Cars
    case 'FETCH_CARS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_CARS':
      return { ...state, cars: action.payload }
    case 'ADD_CAR':
      return { ...state, cars: [...state.cars, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_CAR':
      return { ...state, cars: state.cars.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_CAR':
      return { ...state, cars: state.cars.filter(item => item.id !== action.payload.id) }
    case 'FETCH_CARS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          cars: true
        }
      }
    case 'FETCH_CARS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Hired cars
    case 'FETCH_HIRED_CARS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_HIRED_CARS':
      return { ...state, hiredCars: action.payload }
    case 'ADD_HIRED_CAR':
      return { ...state, hiredCars: [...state.hiredCars, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_HIRED_CAR':
      return { ...state, hiredCars: state.hiredCars.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_HIRED_CAR':
      return { ...state, hiredCars: state.hiredCars.filter(item => item.id !== action.payload.id) }
    case 'FETCH_HIRED_CARS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          hiredCars: true
        }
      }
    case 'FETCH_HIRED_CARS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Hired drivers
    case 'FETCH_HIRED_DRIVERS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_HIRED_DRIVERS':
      return { ...state, hiredDrivers: action.payload }
    case 'ADD_HIRED_DRIVER':
      return { ...state, hiredDrivers: [...state.hiredDrivers, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_HIRED_DRIVER':
      return { ...state, hiredDrivers: state.hiredDrivers.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_HIRED_DRIVER':
      return { ...state, hiredDrivers: state.hiredDrivers.filter(item => item.id !== action.payload.id) }
    case 'FETCH_HIRED_DRIVERS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          hiredDrivers: true
        }
      }
    case 'FETCH_HIRED_DRIVERS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Customers
    case 'FETCH_CUSTOMERS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_CUSTOMERS':
      return { ...state, customers: action.payload }
    case 'ADD_CUSTOMER':
      return { ...state, customers: [...state.customers, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_CUSTOMER':
      return { ...state, customers: state.customers.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_CUSTOMER':
      return { ...state, customers: state.customers.filter(item => item.id !== action.payload.id) }
    case 'FETCH_CUSTOMERS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          customers: true
        }
      }
    case 'FETCH_CUSTOMERS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Carriers
    case 'FETCH_CARRIERS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_CARRIERS':
      return { ...state, carriers: action.payload }
    case 'ADD_CARRIER':
      return { ...state, carriers: [...state.carriers, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_CARRIER':
      return { ...state, carriers: state.carriers.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_CARRIER':
      return { ...state, carriers: state.carriers.filter(item => item.id !== action.payload.id) }
    case 'FETCH_CARRIERS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          carriers: true
        }
      }
    case 'FETCH_CARRIERS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Suppliers
    case 'FETCH_SUPPLIERS_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_SUPPLIERS':
      return { ...state, suppliers: action.payload }
    case 'ADD_SUPPLIER':
      return { ...state, suppliers: [...state.suppliers, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_SUPPLIER':
      return { ...state, suppliers: state.suppliers.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_SUPPLIER':
      return { ...state, suppliers: state.suppliers.filter(item => item.id !== action.payload.id) }
    case 'FETCH_SUPPLIERS_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          suppliers: true
        }
      }
    case 'FETCH_SUPPLIERS_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Routes
    case 'FETCH_ROUTES_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_ROUTES':
      return { ...state, routes: action.payload }
    case 'ADD_ROUTE':
      return { ...state, routes: [...state.routes, action.payload].sort((a, b) => new Date(a.dateOfDeparture) - new Date(b.dateOfDeparture)) }
    case 'UPDATE_ROUTE':
      return { ...state, routes: state.routes.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => new Date(a.dateOfDeparture) - new Date(b.dateOfDeparture)) }
    case 'DELETE_ROUTE':
      return { ...state, routes: state.routes.filter(item => item.id !== action.payload.id) }
    case 'FETCH_ROUTES_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          routes: true
        }
      }
    case 'FETCH_ROUTES_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Expenses
    case 'FETCH_EXPENSES_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_EXPENSES':
      return { ...state, expenses: action.payload }
    case 'ADD_EXPENSE':
      return { ...state, expenses: [...state.expenses, action.payload].sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'UPDATE_EXPENSE':
      return { ...state, expenses: state.expenses.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'DELETE_EXPENSE':
      return { ...state, expenses: state.expenses.filter(item => item.id !== action.payload.id) }
    case 'FETCH_EXPENSES_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          expenses: true
        }
      }
    case 'FETCH_EXPENSES_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Refueling
    case 'FETCH_REFUELING_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_REFUELING':
      return { ...state, refueling: action.payload }
    case 'ADD_REFUELING':
      return { ...state, refueling: [...state.refueling, action.payload].sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'UPDATE_REFUELING':
      return { ...state, refueling: state.refueling.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'DELETE_REFUELING':
      return { ...state, refueling: state.refueling.filter(item => item.id !== action.payload.id) }
    case 'FETCH_REFUELING_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          refueling: true
        }
      }
    case 'FETCH_REFUELING_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Maintenance
    case 'FETCH_MAINTENANCE_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_MAINTENANCE':
      return { ...state, maintenance: action.payload }
    case 'ADD_MAINTENANCE':
      return { ...state, maintenance: [...state.maintenance, action.payload].sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'UPDATE_MAINTENANCE':
      return { ...state, maintenance: state.maintenance.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'DELETE_MAINTENANCE':
      return { ...state, maintenance: state.maintenance.filter(item => item.id !== action.payload.id) }
    case 'FETCH_MAINTENANCE_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          maintenance: true
        }
      }
    case 'FETCH_MAINTENANCE_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Inventory
    case 'FETCH_INVENTORY_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_INVENTORY':
      return { ...state, inventory: action.payload }
    case 'ADD_INVENTORY':
      return { ...state, inventory: [...state.inventory, action.payload].sort((a, b) => a.name.localeCompare(b.name)) }
    case 'UPDATE_INVENTORY':
      return { ...state, inventory: state.inventory.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => a.name.localeCompare(b.name)) }
    case 'DELETE_INVENTORY':
      return { ...state, inventory: state.inventory.filter(item => item.id !== action.payload.id) }
    case 'FETCH_INVENTORY_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          inventory: true
        }
      }
    case 'FETCH_INVENTORY_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Stock
    case 'FETCH_STOCK_REQUEST':
      return { ...state, loading: true, error: null }
    case 'SET_STOCK':
      return { ...state, stock: action.payload }
    case 'ADD_STOCK':
      return { ...state, stock: [...state.stock, action.payload].sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'UPDATE_STOCK':
      return { ...state, stock: state.stock.map(item => item.id === action.payload.id ? action.payload : item).sort((a, b) => new Date(a.date) - new Date(b.date)) }
    case 'DELETE_STOCK':
      return { ...state, stock: state.stock.filter(item => item.id !== action.payload.id) }
    case 'FETCH_STOCK_SUCCESS':
      return {
        ...state, loading: false, isDataLoaded: {
          ...state.isDataLoaded,
          stock: true
        }
      }
    case 'FETCH_STOCK_FAILURE':
      return { ...state, loading: false, error: action.error }

    // Handle setCompany action
    case 'SET_COMPANY':
      return { ...state, companyId: action.payload }

    // Reset data state but keep companyId
    case 'RESET_DATA_STATE':
      return {
        ...initialState,
        companyId: state.companyId
      }

    default:
      return state
  }
}
