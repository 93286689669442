import React, { useContext, useState } from 'react'
import { useMessage } from '../../../hooks/useMessage'
import Button from '../../Buttons/Button'
import styled from 'styled-components'
import ModalContext from '../../../context/ModalContext'
import { useLanguage } from '../../../context/LanguageContext'
import Library from '../../../Library'
import useTranslate from '../../../hooks/useTranslate'
import PostService from '../../../API/PostService'

const Wrapper = styled.div`
  width: 300px;
`

const LabelWrapper = styled.div`
  font-size: 20px;
  color: gray;
`
const Label = styled.span`
  font-size: 20px;
  color: var(--main-color);
  font-weight: bold;
`

const ButtonsWrapper = styled.div`
  margin: 10px 0px 0px 0px;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`

export default function MarkConfirmation({ item, label, path, request }) {
  const { closeModal } = useContext(ModalContext)
  const message = useMessage()
  const { language } = useLanguage()
  const translate = useTranslate()
  const [markButtonDisabled, setMarkButtonDisabled] = useState(false)

  function getItemStatus(currentStatus) {
    if (currentStatus === 'active') {
      return 'markedForDeletion'
    } else if (currentStatus === 'markedForDeletion') {
      return 'active'
    }
  }

  return (
    <Wrapper>
      <LabelWrapper>{item.status === 'active' ? translate('markForDelition') : translate('unmarkForDelition')} <Label>{Library.truncate(label, 25)}</Label>?</LabelWrapper>
      <ButtonsWrapper>
        <Button
          style={{
            color: 'var(--cancel-button-text)',
            backgroundColor: 'var(--cancel-button-bg)',
            border: 'var(--cancel-button-border)',
            minWidth: '140px',
          }}
          onClick={() => closeModal()}
          waves={false}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{
            minWidth: '140px',
            backgroundColor: 'var(--alert-button-bg)',
          }}
          disabled={markButtonDisabled}
          onClick={async (e) => {
            e.preventDefault()
            setMarkButtonDisabled(true)
            const requestPath = `/${request}/${item.id}/status`
            const response = await PostService.patch(path, requestPath, { newStatus: getItemStatus(item.status) }, language)

            Library.handleResponseStatus(response, message)

            const { status } = response

            if (status === 200) {
              closeModal()
            } else {
              setTimeout(() => {
                setMarkButtonDisabled(false)
              }, 100)
            }
          }
          }
        >
          {translate('confirm')}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}