import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSoundTheme } from '../../hooks/useSoundTheme'
import Button from '../Buttons/Button'
import useTranslate from '../../hooks/useTranslate'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  height: 36px;
  width: 100%;
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  height: 36px;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: box-shadow .3s, border .3s;
  color: var(--input-text-color);
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}
  ${({ readOnly, disabled, $isFocused }) =>
    ((readOnly || disabled) && !$isFocused) &&
    css`
    border: none;
    border-bottom: 1px dotted var(--input-element-color);
  `
  }
  ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
        `
      : $forceDarkMode
      && css`
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `}
`

const EditSvg = ({ size, title }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={`${size}px`} height={`${size}px`} viewBox='0 0 24 24'>
      <title>{title}</title>
      <g id='Complete'>
        <g id='edit'>
          <g>
            <path d='M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8' fill='none' stroke='#FFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
            <polygon fill='none' points='12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8' stroke='#FFF' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default function EditableInput(props) {
  const { value, name, title, type = 'text', onClick, borderOption = 1, readOnly = false, forceDarkMode = false, disabled } = props

  const translate = useTranslate()
  const [isEditable, setIsEditable] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')
  const [isFocused, setIsFocused] = useState(false)
  const [prevInputValuesMap, setPrevInputValuesMap] = useState(new Map())
  const inputRef = useRef(null)
  const focusedInputNameRef = useRef(null)
  const buttonRef = useRef(null)
  const { buttonSound } = useSoundTheme()

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleEdit = useCallback((e) => {
    buttonSound()

    setIsEditable(prevIsEditable => {
      if (!prevIsEditable) {
        // If the input is not currently editable, make it editable and focus it
        setTimeout(() => inputRef.current?.focus(), 0)
      } else if (prevIsEditable && typeof onClick === 'function') {
        onClick(inputValue)
      }
      setIsFocused(!prevIsEditable)
      return !prevIsEditable
    })
  }, [onClick, inputValue, buttonSound])

  useEffect(() => {
    if (isEditable) {
      inputRef.current?.focus()
    }
  }, [isEditable])

  const handleBlur = useCallback((e) => {
    if (buttonRef.current && buttonRef.current.contains(e.relatedTarget)) {
      return
    }
    setIsFocused(false)
    if (isEditable) {
      handleEdit()
    }
  }, [isEditable, handleEdit])

  const handleFocus = useCallback((e) => {
    focusedInputNameRef.current = name
    if (!isEditable) return
    setIsFocused(true)
  }, [isEditable, name])

  const handleKeyDown = useCallback((e) => {
    if (isFocused && e.target === inputRef.current) {
      //=====DELETE=====//
      if (e.key === 'Delete' && !readOnly && inputRef.current.value !== '') {
        setPrevInputValuesMap((prevState) => {
          const newMap = new Map(prevState)
          if (!newMap.has(name)) {
            newMap.set(name, [])
          }
          newMap.get(name).push(inputRef.current.value)
          console.log(newMap)
          return newMap
        })
        setInputValue('')
      }
      //=====ENTER=====//
      else if (e.key === 'Enter') {
        e.preventDefault()
        handleEdit()
      }
    }
  }, [inputRef, name, isFocused, readOnly, handleEdit])

  useEffect(() => {
    const handleUndo = (e) => {
      if (!isFocused) {
        return
      }

      if (
        e.ctrlKey &&
        e.code === 'KeyZ' &&
        document.activeElement === inputRef.current &&
        prevInputValuesMap.has(focusedInputNameRef.current)
      ) {
        if (e.target === inputRef.current) {
          const inputPrevValues = prevInputValuesMap.get(focusedInputNameRef.current)
          const restoredValue = inputPrevValues.pop()
          if (restoredValue !== undefined) {
            setInputValue(restoredValue)
            setPrevInputValuesMap((prevState) => {
              const newMap = new Map(prevState)
              const prevInputValues = newMap.get(focusedInputNameRef.current)
              prevInputValues.pop()
              if (prevInputValues.length === 0) {
                newMap.delete(focusedInputNameRef.current)
              } else {
                newMap.set(focusedInputNameRef.current, prevInputValues)
              }
              return newMap
            })
          }
        }
      }
    }

    document.addEventListener('keydown', handleUndo)
    return () => {
      document.removeEventListener('keydown', handleUndo)
    }
  }, [prevInputValuesMap, isFocused])

  return (
    <Wrapper>
      <Input
        ref={inputRef}
        title={title}
        type={type}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onDoubleClick={() => {
          if (isEditable || disabled) return
          handleEdit()
        }}
        readOnly={!isEditable}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        $isFocused={isFocused}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $forceDarkMode={forceDarkMode}
      />
      <Button
        ref={buttonRef}
        title={isEditable ? translate('save') : translate('edit')}
        style={{ minWidth: '42px', padding: '0 12px' }}
        onClick={handleEdit}
        disabled={disabled}
      >
        {isEditable ? <>&#10003;</> : <EditSvg size={18} title={isEditable ? translate('save') : translate('edit')} />}
      </Button>
    </Wrapper>
  )
}