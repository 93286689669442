import { useState, useLayoutEffect } from 'react'

export const useHorizontalScroll = (ref, deps) => {
  const [isScrollDisplayed, setIsScrollDisplayed] = useState(false)

  useLayoutEffect(() => {

    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    const checkScroll = () => {
      if (ref.current) {
        const isScroll = !isMobile && ref.current.scrollWidth > ref.current.clientWidth
        setIsScrollDisplayed(isScroll)
      }
    }

    window.addEventListener('resize', checkScroll)
    checkScroll()

    return () => {
      window.removeEventListener('resize', checkScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...deps])

  return isScrollDisplayed
}

