import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import { Filter } from './components/Filter'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RoutesSummary } from './components/RoutesSummary'
import { RefuelingSummary } from './components/RefuelingSummary'
import { ExpensesSummary } from './components/ExpensesSummary'
import useWindowHeight from '../../hooks/useWindowHeight'
import { useLocation } from 'react-router-dom'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll'

const Container = styled.div`
  position: fixed;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
`

const ListsWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const SectionsWrapper = styled.div`
  display:flex;
`

const SectionWrapper = styled.div`
  min-width: ${props => props.width}px;
`

const LabelWrapper = styled.div`
  border-style: solid;
  border-color: var(--border-color);
  margin-bottom: 5px;
  text-align: center;
  position: sticky;
  top: 0;
  width: calc(100% - 10px);
  color: var(--text-color);
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const profitRoutesPageState = useSelector(state => state.page.profitRoutes)
  const profitRefuelingPageState = useSelector(state => state.page.profitRefueling)
  const profitExpensesPageState = useSelector(state => state.page.profitExpenses)
  const containerRef = useRef(null)
  useWindowHeight(containerRef, 85)
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [routesTotal, setRoutesTotal] = useState(0)
  const [salaryTotal, setSalaryTotal] = useState(0)
  const [refuelingTotal, setFuelTotal] = useState(0)
  const [expensesTotal, setExpensesTotal] = useState(0)
  const [dataReady, setDataReady] = useState(false)
  const offsetHeight = 300
  const isScrollDisplayed = useHorizontalScroll(containerRef, [dataReady])

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const routesListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const expensesListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const refuelingListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }

  const { cars, employees, customers, refueling, routes, expenses, loading, error } = useSelector(state => state.data)
  const drivers = useMemo(() => employees.filter(employee => employee.isDriver), [employees])

  //=====FILTERED ROUTES=====//
  const filteredRoutes = useMemo(() => {
    const { car, startDate, endDate } = filterQuery

    const matchesCar = (route) =>
      !car || route.car === car

    const matchesStartDate = (route) =>
      !startDate || moment(route.dateOfDeparture).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (route) =>
      !endDate || moment(route.dateOfDeparture).isSameOrBefore(endDate, 'day')

    const filtered = routes.filter(
      (route) =>
        matchesCar(route) &&
        matchesStartDate(route) &&
        matchesEndDate(route)
    )

    profitRoutesPageState.sort.key && Library.sort(filtered, profitRoutesPageState.sort)

    return filtered
  }, [routes, filterQuery, profitRoutesPageState.sort])

  //=====FILTERED REFUELING=====//
  const filteredRefueling = useMemo(() => {
    const { car, startDate, endDate } = filterQuery

    const matchesCar = (item) =>
      !car || item.car === car

    const matchesStartDate = (item) =>
      !startDate || moment(item.date).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (item) =>
      !endDate || moment(item.date).isSameOrBefore(endDate, 'day')

    const filtered = refueling.filter(
      (item) =>
        matchesCar(item) &&
        matchesStartDate(item) &&
        matchesEndDate(item)
    )

    profitRefuelingPageState.sort.key && Library.sort(filtered, profitRefuelingPageState.sort)

    return filtered
  }, [refueling, filterQuery, profitRefuelingPageState.sort])

  //=====FILTERED EXPENSES=====//
  const filteredExpenses = useMemo(() => {

    function getStartDate(str) {
      if (str) {
        str = moment(str).format('YYYY-MM')
        return new Date(str.slice(0, 4), parseFloat(str.slice(-2)) - 1, 1)
      }
    }

    function getEndDate(str) {
      if (str) {
        str = moment(str).format('YYYY-MM')
        return new Date(str.slice(0, 4), parseFloat(str.slice(-2)), 0)
      }
    }

    const { car, startDate, endDate } = filterQuery

    const matchesCar = (item) =>
      !car || item.bind === car

    const matchesStartDate = (item) =>
      !startDate || moment(item.period).isSameOrAfter(getStartDate(startDate), 'day')

    const matchesEndDate = (item) =>
      !endDate || moment(getEndDate(item.period)).isSameOrBefore(endDate, 'day')

    const isCarExpense = (item) =>
      item.bindingType === 'Автомобиль'

    const filtered = expenses.filter(
      (item) =>
        matchesCar(item) &&
        matchesStartDate(item) &&
        matchesEndDate(item) &&
        isCarExpense(item)
    )

    profitExpensesPageState.sort.key && Library.sort(filtered, profitExpensesPageState.sort)

    return filtered
  }, [expenses, filterQuery, profitExpensesPageState.sort])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [mainState])

  useEffect(() => {
    if (!isDataLoaded || loading) return
    setDataReady(true)
  }, [isDataLoaded, loading])

  const [routesReplaced, setRoutesReplaced] = useState([])
  useEffect(() => {
    if (!dataReady || loading || error) {
      return
    }
    let replaced = []
    filteredRoutes.forEach((originalItem) => {
      let item = { ...originalItem }
      Object.keys(item).forEach((key) => {
        switch (key) {
          case 'driver': {
            item[key] = Library.findNameByKey(drivers, item.driver)
            break
          }
          case 'car': {
            item[key] = Library.findNameByKey(cars, item.car)
            break
          }
          case 'customer': {
            item[key] = Library.findNameByKey(customers, item.customer)
            break
          }
          default: { }
        }
      })
      replaced.push(item)
    })
    setRoutesReplaced(replaced)
  }, [loading, error, dataReady, filteredRoutes, cars, customers, drivers, profitRoutesPageState.sort])

  const expensesHeaders = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 50 },
    { id: 'date', name: 'Дата', dataType: 'date', type: 'date', width: 80 },
    { id: 'amount', name: 'Сумма', dataType: 'number', width: 70 },
    { id: 'budgetItem', name: 'Статья бюджета', width: 200 },
    { id: 'comment', name: 'Комментарий', width: 150 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const refuelingHeaders = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 70 },
    { id: 'date', name: 'Дата', dataType: 'date', type: 'date', width: 80 },
    { id: 'refuelingType', name: 'Вид топлива', width: 60 },
    { id: 'payType', name: 'Форма оплаты', width: 60 },
    { id: 'amount', name: 'Сумма', width: 70 },
    { id: 'litersOrKilograms', name: 'Литры/Кг', dataType: 'number', width: 70 },
    { id: 'refuelingConsumption', name: 'Расход', dataType: 'number', width: 70 },
    { id: 'comment', name: 'Комментарий', width: 150 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const routesHeaders = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 50 },
    { id: 'dateOfDeparture', name: 'Дата', dataType: 'date', type: 'date', width: 80 },
    { id: 'car', name: 'Автомобиль', width: 150 },
    { id: 'paymentType', name: 'Тип оплаты', width: 60 },
    { id: 'weight', name: 'Вес', dataType: 'number', width: 70 },
    { id: 'rate', name: 'Ставка', dataType: 'number', width: 70 },
    { id: 'routeCost', name: 'Стоимость', dataType: 'number', width: 70 },
    { id: 'driver', name: 'Водитель', width: 150 },
    { id: 'driverSalary', name: 'Зарплата водителя', dataType: 'number', width: 70 },
    { id: 'comment', name: 'Комментарий', width: 150 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  return (
    <Container ref={containerRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {dataReady && isDataLoaded &&
        <ListsWrapper>
          <Filter
            routes={routes}
            expenses={expenses}
            refueling={refueling}
            cars={cars}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            profit={routesTotal - refuelingTotal - expensesTotal - salaryTotal}
            setRoutesTotal={setRoutesTotal}
            setSalaryTotal={setSalaryTotal}
            setFuelTotal={setFuelTotal}
            setExpensesTotal={setExpensesTotal}
            findNameByKey={Library.findNameByKey}
            pageName={pageName}
          />
          <SectionsWrapper>
            {/* Маршруты */}
            <SectionWrapper width={930}>
              <LabelWrapper>
                Маршруты
              </LabelWrapper>
              <RoutesSummary
                filteredRoutes={filteredRoutes}
                filterQuery={filterQuery}
                setRoutesTotal={setRoutesTotal}
                routesTotal={routesTotal}
                setSalaryTotal={setSalaryTotal}
                salaryTotal={salaryTotal}
              />
              <VirtualizedList
                ref={routesListRefs}
                offsetHeight={isScrollDisplayed ? offsetHeight : offsetHeight - 7}
                overflowX={false}
                headers={routesHeaders}
                pageName={`${pageName}Routes`}
                items={routesReplaced}
                filterQuery={filterQuery}
                navigateTo='routes'
                contextMenu={false}
              />
            </SectionWrapper>
            {/* Топливо */}
            <SectionWrapper width={640} >
              <LabelWrapper>
                Топливо
              </LabelWrapper>
              <RefuelingSummary
                filteredRefueling={filteredRefueling}
                filterQuery={filterQuery}
                setFuelTotal={setFuelTotal}
                refuelingTotal={refuelingTotal}
              />
              <VirtualizedList
                ref={refuelingListRefs}
                offsetHeight={isScrollDisplayed ? offsetHeight : offsetHeight - 7}
                overflowX={false}
                headers={refuelingHeaders}
                pageName={`${pageName}Refueling`}
                items={filteredRefueling}
                filterQuery={filterQuery}
                navigateTo='refueling'
                contextMenu={false}
              />
            </SectionWrapper>
            {/* Расходы */}
            <SectionWrapper width={560} >
              <LabelWrapper>
                Расходы
              </LabelWrapper>
              <ExpensesSummary
                filteredExpenses={filteredExpenses}
                filterQuery={filterQuery}
                setExpensesTotal={setExpensesTotal}
                expensesTotal={expensesTotal}
              />
              <VirtualizedList
                ref={expensesListRefs}
                offsetHeight={isScrollDisplayed ? offsetHeight : offsetHeight - 7}
                overflowX={false}
                headers={expensesHeaders}
                pageName={`${pageName}Expenses`}
                items={filteredExpenses}
                filterQuery={filterQuery}
                navigateTo='expenses'
                contextMenu={false}
              />
            </SectionWrapper>
          </SectionsWrapper>
        </ListsWrapper>
      }
    </Container >
  )
}
