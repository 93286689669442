import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useMessage } from '../../../hooks/useMessage'
import { useFetching } from '../../../hooks/useFetching'
import { useSelector } from 'react-redux'
import Library from '../../../Library'
import DateInput from '../../../components/Inputs/DateInput'
import Textarea from '../../../components/Inputs/Textarea'
import PostService from '../../../API/PostService'
import Checkbox from '../../../components/Inputs/Checkbox'
import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock'
import styled from 'styled-components'
import LinearLoader from '../../../components/Loaders/LinearLoader'
import moment from 'moment'
import Select from '../../../components/Inputs/Select'
import NumberInput from '../../../components/Inputs/NumberInput'
import useWindowHeight from '../../../hooks/useWindowHeight'
import { useLanguage } from '../../../context/LanguageContext'

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  @media (max-width: 649px) { 
    max-width: 300px;
    margin: 35px auto 85px auto;
  }

  @media (min-width: 650px) {
    margin: 35px 15px 10px 25px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: 10px;
  row-gap: 25px;
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  @media (max-width: 649px) {  
    row-gap: 30px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  flex-wrap: nowrap;
  gap: 10px;
`

const CommentWrapper = styled.div`
  width: 100%;
  @media (max-width: 649px) { 
    margin-bottom: 30px;
  }
`

export const Form = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1).split('/')[0]
  const params = useParams()
  const message = useMessage()
  const { language } = useLanguage()
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const wrapperRef = useRef(null)
  useWindowHeight(wrapperRef, 84)
  const { companyId } = useSelector(state => state.data)
  const [dataReady, setDataReady] = useState(false)
  const [firstRecord, setFirstRecord] = useState(false)
  const [fuelLastRecord, setFuelLastRecord] = useState([])
  const initialFormData = useMemo(() => ({
    id: params.id,
    date: '',
    supplier: '',
    consider: 0,
    fuelType: '',
    payType: '',
    amount: null,
    car: '',
    mileage: null,
    litersOrKilograms: null,
    fuelConsumption: null,
    distance: null,
    comment: ''
  }), [params.id])
  const [form, setForm] = useState(initialFormData)

  const { cars, suppliers, refueling, loading, error } = useSelector(state => state.data)

  useEffect(() => {
    if (isDataLoaded && !loading && !error) {
      if (params.id && refueling.length) {
        const formData = Library.findItemById(refueling, params.id)
        if (formData) {
          setForm(formData)
        } else {
          setForm(initialFormData)
        }
      }
      setDataReady(true)
    }
  }, [initialFormData, isDataLoaded, loading, error, refueling, params.id])

  const [supplierSelectOptions, setSupplierSelectOptions] = useState([])

  const [fetchRefuelingLastRecord, , fuelLastRecordError,] = useFetching(
    async () => {
      const response = await PostService.get('data', `${companyId}/refueling-last-record`, { id: params.id ? params.id : null, car: form.car, date: form.date }, language)
      if (response && !response.errors) {
        message(response.message)
      }
      setFuelLastRecord(response.data)
      if (response.data) {
        setFirstRecord(false)
      } else {
        setFirstRecord(true)
      }
      fuelLastRecordError && console.log(fuelLastRecordError)
    }
  )

  function getSelectOptions(data) {
    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]
    if (data && data.length) {
      data.forEach((item) => {
        arr.push({ value: item.id, label: item.name })
      })
    }
    return arr
  }

  const payTypeSelectOptions = [
    {
      value: '',
      label: '-',
      disabled: false,
      defaultValue: true,
    },
    { value: 'Б/н', label: 'Б/н' },
    { value: 'Нал', label: 'Нал' },
  ]

  const fuelTypeSelectOptions = [
    {
      value: '',
      label: '-',
      disabled: false,
      defaultValue: true,
    },
    { value: 'Кпг', label: 'Кпг' },
    { value: 'Спг', label: 'Спг' },
  ]

  useEffect(() => {
    if (dataReady) {
      if (params.id) {
        if (form.car && form.date) {
          fetchRefuelingLastRecord()
        }
      }
      setSupplierSelectOptions(getSelectOptions(suppliers))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReady, params.id, suppliers])

  //=========RECALCULATE=========//
  const recalculate = useCallback(async (e) => {
    if (!dataReady) {
      return
    }
    const { name, value, type } = e.target
    let newForm = {
      ...form,
      [name]: type === 'number' ? parseFloat(value) : value
    }
    const { date, car } = newForm

    if (date && car) {
      if (['date', 'car'].includes(name)) {
        newForm.distance = 0
        newForm.mileage = 0
        newForm.fuelConsumption = 0
        const response = await PostService.get('data', `${companyId}/refueling-last-record`, { id: params.id ? params.id : null, car, date }, language)
        if (response && !response.errors) {
          const isFuelLastRecordExists = Boolean(response.data)
          setFirstRecord(!isFuelLastRecordExists)
          console.log(response.data)
          setFuelLastRecord(response.data || {})
          const messageText = (Boolean(response.data) && response.data?.mileage)
            ? `Предыдущая запись: ${Library.findNameByKey(cars, car)} от ${moment(response.data.date).format('DD.MM.yyyy')} пробег: ${response.data.mileage?.toLocaleString()} км`
            : `Первая запись: ${Library.findNameByKey(cars, car)}`
          message(messageText, 'info')
        } else {
          setFuelLastRecord({})
          message('Что-то пошло не так, попробуйте снова')
        }
      } else {
        const { fuelType, mileage, litersOrKilograms, distance } = newForm
        const currentMileage = parseFloat(mileage)
        const currentLitersOrKilograms = parseFloat(litersOrKilograms)
        const currentDistance = parseFloat(distance)
        let val
        if (currentMileage && !firstRecord) {
          val = currentMileage - parseFloat(fuelLastRecord?.mileage)
          newForm.distance = parseFloat(val)
          if (currentLitersOrKilograms) {
            val = (fuelType !== 'Кпг' && (currentMileage - parseFloat(fuelLastRecord?.mileage)) !== 0) ? (currentLitersOrKilograms / (currentMileage - parseFloat(fuelLastRecord?.mileage)) * 100).toFixed() : 0
            newForm.fuelConsumption = parseFloat(val)
          }
        }
        if (firstRecord && currentLitersOrKilograms && currentDistance) {
          val = (fuelType !== 'Кпг' && currentDistance !== 0) ? (currentLitersOrKilograms / currentDistance * 100).toFixed() : 0
          newForm.fuelConsumption = parseFloat(val)
        }
      }
    }
    setForm(newForm)
  }, [dataReady, form, setFirstRecord, setFuelLastRecord, cars, params.id, firstRecord, fuelLastRecord?.mileage, message, language, companyId])

  const handleCarChange = useCallback((e) => {
    const newCarValue = e.target.value

    setForm((prevForm) => {
      if (prevForm.car !== newCarValue) {
        const updatedForm = {
          ...prevForm,
          car: newCarValue
        }
        recalculate({ target: { name: 'car', value: newCarValue } })
        return updatedForm
      }
      return prevForm
    })
  }, [recalculate])

  //------- CHANGE HISTORY -------//

  const keyTranslation = useMemo(() => [
    { id: 'id', name: 'id' },
    { id: 'timestamp', name: 'timestamp' },
    { id: 'status', name: 'Статус' },
    { id: 'date', name: 'Дата' },
    { id: 'supplier', name: 'Поставщик/исполнитель' },
    { id: 'consider', name: 'Учитывать' },
    { id: 'fuelType', name: 'Вид топлива' },
    { id: 'payType', name: 'Форма оплаты' },
    { id: 'amount', name: 'Сумма' },
    { id: 'car', name: 'Автомобиль' },
    { id: 'mileage', name: 'Пробег' },
    { id: 'litersOrKilograms', name: 'Литры/Кг' },
    { id: 'fuelConsumption', name: 'Расход' },
    { id: 'distance', name: 'Расстояние' },
    { id: 'comment', name: 'Комментарий' },
  ], [])

  const getKeyTranslation = keyTranslation.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})

  const formatValue = (key, value) => {
    switch (key) {
      case 'status': {
        return value === 'active' ? 'активный' : 'помечен на удаление'
      }
      case 'supplier': {
        return Library.findNameByKey(suppliers, value)
      }
      case 'car': {
        return Library.findNameByKey(cars, value)
      }
      case 'date': {
        return Library.formatDate(value, 'DD.MM.yyyy')
      }
      case 'consider': {
        return value ? '☑' : ''
      }
      default: {
        return value
      }
    }
  }

  const renderChangeDetails = (change) => {
    if (!change.newData) {
      return ''
    }

    const parseJSON = (data) => {
      try {
        return JSON.parse(data.replace(/\n/g, '\\n'))
      } catch (e) {
        console.error("Error parsing JSON: ", e)
        return {}
      }
    }

    const isValueEmpty = (value) => {
      return value === '' || value === null || value === 0
    }

    const isValueChanged = (oldValue, newValue) => {
      return oldValue !== newValue
    }

    const parsedNewData = parseJSON(change.newData.replace(/\n/g, '\\n'))
    const parsedOldData = change.oldData ? parseJSON(change.oldData.replace(/\n/g, '\\n')) : {}

    return Object.keys(parsedNewData).map((key) => {
      let oldData = parsedOldData[key] ? formatValue(key, parsedOldData[key]) : ''
      let newData = parsedNewData[key] ? formatValue(key, parsedNewData[key]) : ''

      if ((isValueEmpty(oldData) && isValueEmpty(newData)) || !isValueChanged(oldData, newData)) {
        return null
      }

      return (
        <div key={key} style={{ marginBottom: '10px' }}>
          <span>{getKeyTranslation[key] || key}:</span>
          {'\u00A0'}
          <span>{oldData ? oldData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
          {'\u00A0\u2192\u00A0'}
          <span>{newData ? newData : <span style={{ color: '#c3c3c3' }}>{language === 'ru' ? 'Пустое значение' : 'Blank value'}</span>}</span>
        </div>
      )
    }).filter(Boolean)
  }

  return (
    <Wrapper ref={wrapperRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {isDataLoaded && dataReady &&
        <FormWrapper>
          <SectionWrapper $maxWidth={700}>
            <RowWrapper>
              {/* ------- DATE ------- */}
              <DateInput
                label='Дата'
                name='date'
                id='date'
                value={form.date ? moment(form.date).format('yyyy-MM-DD') : ''}
                onChange={recalculate}
              />
              {/* ------- CONSIDER ------- */}
              <Checkbox
                label='Учитывать'
                name='consider'
                id='consider'
                size='24px'
                checked={form.consider ? true : false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- SUPPLIER ------- */}
              < Select
                label={'Поставщик/исполнитель'}
                id='supplier'
                name='supplier'
                value={form.supplier}
                options={supplierSelectOptions}
                searchable={true}
                disabled={false}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- PAY TYPE ------- */}
              <Select
                label='Форма оплаты'
                name='payType'
                id='payType'
                value={form.payType}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
                options={payTypeSelectOptions}
              />
              {/* ------- AMOUNT ------- */}
              <NumberInput
                label='Сумма'
                name='amount'
                id='amount'
                value={form.amount === null ? '' : form.amount}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
                debounceDelay={500}
                readOnly={false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- FUEL TYPE ------- */}
              <Select
                label='Вид топлива'
                name='fuelType'
                id='fuelType'
                value={form.fuelType}
                onChange={recalculate}
                options={fuelTypeSelectOptions}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- CAR ------- */}
              <Select
                label='Автомобиль'
                id='car'
                name='car'
                value={form.car}
                options={getSelectOptions(cars)}
                searchable={true}
                disabled={false}
                onChange={handleCarChange}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- MILEAGE ------- */}
              <NumberInput
                label='Пробег'
                name='mileage'
                id='mileage'
                value={form.mileage === null ? '' : form.mileage}
                onChange={recalculate}
                debounceDelay={500}
                readOnly={false}
              />
              {/* ------- LITERS OR KILOGRAMS ------- */}
              <NumberInput
                label='Литры/Кг'
                name='litersOrKilograms'
                id='litersOrKilograms'
                value={form.litersOrKilograms === null ? '' : form.litersOrKilograms}
                onChange={recalculate}
                debounceDelay={500}
                readOnly={false}
              />
            </RowWrapper>
            <RowWrapper>
              {/* ------- FUEL CONSUMPTION ------- */}
              <NumberInput
                label='Расход'
                name='fuelConsumption'
                id='fuelConsumption'
                value={form.fuelConsumption === null ? '' : form.fuelConsumption}
                onChange={(e) => {
                  Library.changeHandler(e, form, setForm)
                }}
                debounceDelay={500}
                readOnly={true}
              />
              {/* ------- DISTANCE ------- */}
              <NumberInput
                label='Расстояние'
                name='distance'
                id='distance'
                value={form.distance === null ? '' : form.distance}
                onChange={recalculate}
                debounceDelay={500}
                readOnly={false}
              />
            </RowWrapper>
            {/* ------- COMMENT ------- */}
            <CommentWrapper>
              <RowWrapper>
                <Textarea
                  label='Комментарий'
                  name='comment'
                  id='comment'
                  value={form.comment}
                  debounceDelay={500}
                  onChange={(e) => {
                    Library.changeHandler(e, form, setForm)
                  }}
                />
              </RowWrapper>
            </CommentWrapper>
          </SectionWrapper>
          <ButtonsBlock
            handler={Library.handler}
            form={form}
            edit={params.id ? true : false}
            label={form.date ? moment(form.date).format('DD.MM.yyyy') : ''}
            message={message}
            fileFolder={pageName}
            path='data'
            request={pageName}
            changeHistoryTableName={`${companyId}_change_history`}
            changeHistoryId={form.id}
            renderChangeDetails={renderChangeDetails}
          />
        </FormWrapper>
      }
    </Wrapper>
  )
}
