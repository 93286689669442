import React from 'react'
import { useMessage } from '../../hooks/useMessage'
import styled, { css } from 'styled-components'
import Library from '../../Library'
import { useLanguage } from '../../context/LanguageContext'
import OpenIcon from './Icons/OpenIcon'
import DownloadIcon from './Icons/DownloadIcon'
import TrashIcon from './Icons/TrashIcon'
import { useSelector } from 'react-redux'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    border: none;
    padding: 15px 5px;
    text-align: left;
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    vertical-align: middle;
    @media (min-width: 1200px) {
      padding: 15px 20px;
    }
    @media (min-width: 1600px) {
      padding: 15px 35px;
    }
  }

  thead {
    position: sticky;
    top: 0;
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    z-index: 10;
    background: var(--background-color);
  }

  thead,
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--background-tertiary-color);
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: none;
  }

  @media (max-width: 768px) {
    font-size: 12px;

    th,
    td {
      padding: 8px 4px;
      white-space: normal;
      overflow-wrap: break-word;
      word-break: break-all;
    }
  }

  th:nth-child(1), td:nth-child(1) {
    width: 8%;
  }
  th:nth-child(2), td:nth-child(2) {
    width: 47%;
  }
  th:nth-child(3), td:nth-child(3) {
    width: 15%;
  }
  th:nth-child(4), td:nth-child(4) {
    width: 10%;
  }
  th:nth-child(5), td:nth-child(5) {
    width: 10%;
  }
  th:nth-child(6), td:nth-child(6) {
    width: 10%;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  color: ${({ disabled }) => (disabled ? 'var(--text-color-disabled)' : 'var(--text-color)')};

  svg {
    transition: transform 0.1s ease;
  }

  path {
    transition: stroke 0.1s ease;
    stroke: var(--text-color);
  }

  &:hover {
    svg {
      transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.1)')};
      
      ${({ disabled, $hoverColor }) =>
    (!disabled && $hoverColor) &&
    css`
          path {
            stroke: ${$hoverColor};
          }
        `}
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      path {
        stroke: #464455;
      }
    `}
`

const text = {
  table: {
    headers: {
      number: {
        en: '#',
        ru: '№'
      },
      fileName: {
        en: 'File Name',
        ru: 'Имя файла'
      },
      extension: {
        en: 'Extension',
        ru: 'Расширение'
      },
      open: {
        en: 'Open',
        ru: 'Открыть'
      },
      download: {
        en: 'Download',
        ru: 'Скачать'
      },
      delete: {
        en: 'Delete',
        ru: 'Удалить'
      }
    }
  }
}

export const FilesTable = (props) => {
  const { fetchFileList, fileList, fileFolder, subFolder } = props
  const { companyId } = useSelector(state => state.data)
  const { language } = useLanguage()
  const message = useMessage()

  const openFile = async (e, item) => {
    e.stopPropagation()
    const request = `${companyId}/open-file`
    const response = await fetch(`/api/file/${request}?file-folder=${fileFolder}&sub-folder=${subFolder}&file-name=${item}`, {
      headers: {
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      },
    })

    if (response.status === 200) {
      const blob = await response.blob()
      const newWindow = window.open()
      newWindow.document.title = 'File'
      newWindow.document.write(`<iframe src="${URL.createObjectURL(blob)}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
    }
  }

  const downloadFile = async (e, item) => {
    e.stopPropagation()
    const request = `${companyId}/download-file`
    const response = await fetch(`/api/file/${request}?file-folder=${fileFolder}&sub-folder=${subFolder}&file-name=${item}`, {
      headers: {
        headers: {
          'accept-language': language || 'ru'
        },
        withCredentials: true
      },
    })

    if (response.status === 200) {
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = item
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const deleteFile = async (e, item) => {
    e.stopPropagation()
    if (window.confirm('Удалить файл?')) {
      const request = `${companyId}/delete-file`
      const response = await fetch(`/api/file/${request}?file-folder=${fileFolder}&sub-folder=${subFolder}&file-name=${item}`, {
        headers: {
          headers: {
            'accept-language': language || 'ru'
          },
          withCredentials: true
        },
      })
      const json = await response.json()
      message(json?.message, 'success')
      fetchFileList()
    }
  }

  return (
    <Table>
      {/* -----HEADERS----- */}
      <thead>
        <tr>
          <th>{text.table.headers.number[language]}</th>
          <th>{text.table.headers.fileName[language]}</th>
          <th>{text.table.headers.extension[language]}</th>
          <th>{text.table.headers.open[language]}</th>
          <th>{text.table.headers.download[language]}</th>
          <th>{text.table.headers.delete[language]}</th>
        </tr>
      </thead>
      <tbody>
        {/* -----ROWS----- */}
        {fileList.map((item, i) => {
          const openAllowed = Library.browserFileExtensions.includes(item.split('.').pop())
          return (
            <tr key={`${item}-${i}`}>
              <td>{i + 1}</td>
              <td>{item ? item : '-'}</td>
              <td>{item.split('.').pop().toLowerCase()}</td>
              <td>
                {/* ------- OPEN FILE ------- */}
                {openAllowed &&
                  <ActionWrapper
                    onClick={(e) => {
                      openFile(e, item)
                    }}
                    disabled={!openAllowed}
                    color='var(--text-color)'
                    $hoverColor='var(--main-color)'
                  >
                    <OpenIcon size={30} />
                  </ActionWrapper>}
              </td>
              <td>
                {/* ------- DOWNLOAD FILE ------- */}
                <ActionWrapper
                  onClick={(e) => {
                    downloadFile(e, item)
                  }}
                  color='var(--text-color)'
                  $hoverColor='var(--main-color)'
                >
                  <DownloadIcon size={30} />
                </ActionWrapper>
              </td>
              <td>
                {/* ------- DELETE FILE ------- */}
                <ActionWrapper
                  onClick={(e) => {
                    deleteFile(e, item)
                  }}
                  color='var(--text-color)'
                  $hoverColor='red'
                >
                  <TrashIcon size={30} />
                </ActionWrapper>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}