import React from 'react'
import styled from 'styled-components'
import User from './components/User'
import { useAuth } from '../../hooks/useAuth'
import SideNavigation from './components/SideNavigation'
import Navigation from './components/Navigation'
import Brand from './components/Brand'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-rows: 36px 1fr;
  background-color: ${props => props.$backgroundColor};
  box-shadow: var(--box-shadow);
  height: 84px;
  z-index: 1000;
  background-color: var(--surface-color);
`

const TopWrapper = styled.div`
  grid-row: 1;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  grid-row: 2;
  overflow-x: auto;
  overflow-y: hidden;
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  
  @media (min-width: 768px) {
    justify-content: initial;
    gap: 5px;
  }
`

export const Header = () => {
  const { isAdmin } = useAuth()
  const displaySideNav = false

  return (
    <Wrapper>
      <TopWrapper>
        <LeftContainer>
          <Brand />
        </LeftContainer>
        <RightContainer>
          {displaySideNav && <SideNavigation />}
          <User isAdmin={isAdmin} />
        </RightContainer>
      </TopWrapper>
      <BottomWrapper>
        <Navigation />
      </BottomWrapper>
    </Wrapper>
  )
}
