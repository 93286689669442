import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  transition: all .2s ease-in-out;
  path {
    stroke: ${({ $color }) => ($color ? $color : 'var(--text-secondary-color)')};
  }
  &:hover {
    path {
     stroke: #FFF;
    }
  }
`

export default function MenuIcon({ color, width = '28', height = '28' }) {
  return (
    <Svg width={width} height={height} $color={color} viewBox='0 0 24 24' fill='none'>
      <path d='M4 6H20M4 12H20M4 18H20' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </Svg>
  )
}
