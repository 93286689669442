import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import DeleteUser from './DeleteUser'
import EditRoles from './EditRoles'
import ChangePassword from './ChangePassword'
import { useSelector } from 'react-redux'
import Button from '../../../components/Buttons/Button'
import AdminIcon from '../../../components/Icons/AdminIcon'
import UserIcon from '../../../components/Icons/UserIcon'
import ModalContext from '../../../context/ModalContext'
import styled from 'styled-components'
import ToggleAccess from './ToggleAccess'
import Library from '../../../Library'
import { useAuth } from '../../../hooks/useAuth'
import EditableInput from '../../../components/Inputs/EditableInput'
import { useMessage } from '../../../hooks/useMessage'
import PostService from '../../../API/PostService'
import { useLanguage } from '../../../context/LanguageContext'
import useTranslate from '../../../hooks/useTranslate'
import Toggle2FA from './Toggle2FA'
import DisconnectIcon from '../../../components/Icons/DisconnectIcon'
import UnlinkTelegramId from './UnlinkTelegramId'

const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    border: none;
    padding: 15px 5px;
    text-align: left;
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    vertical-align: middle;
    @media (min-width: 1200px) {
      padding: 15px 10px;
    }
    @media (min-width: 1600px) {
      padding: 15px 15px;
    }
  }

  thead {
    color: var(--text-color);
    border-bottom: 1px solid var(--line-color);
    z-index: 10;
    background: var(--background-color);
  }

  thead,
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--background-tertiary-color);
  }

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: none;
  }
`

const UserIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
`

const OnlineStatus = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.$online ? 'radial-gradient(circle at 3px 3px, #00d5a6, #00a27e)' : 'radial-gradient(circle at 3px 3px, #ffb527, #a66d00)'};
  position: absolute;
  top: -7px;
  left: 0;
  transform: translateY(-50%);
`

export const UsersTable = ({ roles }) => {
  const mainUserId = useSelector(state => state.main.mainUserId)
  const { isAuthenticated } = useAuth()
  const { language } = useLanguage()
  const translate = useTranslate()
  const message = useMessage()
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)
  const [tableKey, setTableKey] = useState(Date.now())

  const { users, onlineUsers, loading, error } = useSelector(state => state.data)
  const [sortedUsers, setSortedUsers] = useState([])

  useEffect(() => {
    const sorted = users.sort((a, b) => {
      if (a.id === mainUserId) return -1
      if (b.id === mainUserId) return 1
      return 0
    })
    setSortedUsers(sorted)
  }, [users, mainUserId])

  const handleFieldChange = async (val, user, key) => {
    if (user[key] === val) return

    const form = { id: user.id, [key]: val }
    const response = await PostService.patch('auth', key, form)

    if (response.status !== 200 && response.status !== 201) {
      setTableKey(Date.now()) // Force rerender users table
    }

    Library.handleResponseStatus(response, message)
  }

  useEffect(() => {
    if (!language) return
    moment.locale(language)
  }, [language])

  return (
    <>
      {isAuthenticated && !loading && !error && sortedUsers && sortedUsers.length &&
        <Table key={tableKey}>
          <thead>
            <tr>
              <th>{translate('role')}</th>
              <th>E-mail</th>
              <th>{translate('username')}</th>
              <th>{translate('password')}</th>
              <th>{translate('roles')}</th>
              <th>2FA</th>
              <th>{translate('access')}</th>
              <th>{translate('telegram')}</th>
              <th>{translate('added')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map((user, i) => {
              const isOnline = onlineUsers[user.id] && onlineUsers[user.id].connections > 0
              const isAdmin = user && user.roles && JSON.parse(user.roles).some(role => role.value === 'admin')
              const isMainUser = mainUserId === user.id
              return (
                <tr key={`${user.email}-${i}`}>
                  {/* ------- ROLE ------- */}
                  <td title={isAdmin ? translate('admin') : translate('user')}>
                    <UserIconWrapper>
                      {isAdmin ? <AdminIcon color='#E57373' size={28} /> : <UserIcon color='#26A69A' size={28} />}
                      <OnlineStatus title={isOnline ? translate('online') : `${translate('lastActivity')}: ${moment(new Date(onlineUsers[user.id]?.lastActivity)).fromNow()}`} $online={isOnline} />
                    </UserIconWrapper>
                  </td>
                  {/* ------- E-mail ------- */}
                  <td title={user.email}>
                    <div style={{ minWidth: '250px' }} title={user.email}>
                      <EditableInput
                        value={user.email}
                        name={user.email}
                        onClick={(val) => { handleFieldChange(val, user, 'email') }}
                        disabled={isMainUser}
                      />
                    </div>
                  </td>
                  {/* ------- USERNAME ------- */}
                  <td>
                    <div style={{ minWidth: '250px' }} title={user.username}>
                      <EditableInput
                        value={user.username}
                        name={user.id}
                        onClick={(val) => { handleFieldChange(val, user, 'username') }}
                      />
                    </div>
                  </td>
                  {/* ------- CHANGE PASSWORD ------- */}
                  <td>
                    <Button
                      style={{ minWidth: '110px' }}
                      disabled={isMainUser}
                      title={`${translate('changeUserPassword')} ${user.email}`}
                      onClick={() => {
                        setModalContent(<ChangePassword currUser={user} />)
                        showModal()
                      }}
                    >
                      {translate('change')}
                    </Button>
                  </td>
                  {/* ------- EDIT ROLES ------- */}
                  <td>
                    {mainUserId !== user.id ?
                      <Button
                        disabled={isMainUser}
                        title={`${translate('editUserRoles')} ${user.email}`}
                        onClick={() => {
                          setModalContent(<EditRoles currUser={user} roles={roles} users={users} />)
                          setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
                          showModal()
                        }}
                      >
                        {translate('edit')}
                      </Button>
                      : '-'}
                  </td>
                  {/* ------- TOGGLE 2FA ------- */}
                  <td>
                    {mainUserId !== user.id ?
                      <Button
                        style={{ minWidth: '42px', maxWidth: '42px', backgroundColor: user.twoStepVerification ? null : 'var(--alert-button-bg)', padding: '0 12px' }}
                        disabled={isMainUser}
                        title={user.twoStepVerification ? `${translate('disableTwoStepVerification')} ${user.email}` : `${translate('enableTwoStepVerification')} ${user.email}`}
                        onClick={() => {
                          setModalContent(<Toggle2FA currUser={user} />)
                          showModal()
                        }}
                      >
                        {user.twoStepVerification ? <p>&#10003;</p> : <p>&#10005;</p>}
                      </Button>
                      : '-'}
                  </td>
                  {/* ------- TOGGLE ACCESS ------- */}
                  <td>
                    {mainUserId !== user.id ?
                      <Button
                        style={{ minWidth: '42px', maxWidth: '42px', backgroundColor: user.accessEnabled ? null : 'var(--alert-button-bg)', padding: '0 12px' }}
                        disabled={isMainUser}
                        title={user.accessEnabled ? `${translate('disableUserAccess')} ${user.email}` : `${translate('enableUserAccess')} ${user.email}`}
                        onClick={() => {
                          setModalContent(<ToggleAccess currUser={user} />)
                          showModal()
                        }}
                      >
                        {user.accessEnabled ? <p>&#10003;</p> : <p>&#10005;</p>}
                      </Button>
                      : '-'}
                  </td>
                  {/* ------- TELEGRAM ------- */}
                  <td>
                    {user.telegramId
                      ?
                      <Button
                        style={{ minWidth: '42px', maxWidth: '42px', padding: '0 10px' }}
                        title={`${translate('unlinkTelegramId')} ${user.email}`}
                        onClick={() => {
                          setModalContent(<UnlinkTelegramId currUser={user} />)
                          showModal()
                        }}
                      >
                        <DisconnectIcon />
                      </Button>
                      :
                      '-'}
                  </td>
                  {/* ------- ADDED ------- */}
                  <td>{moment(user.timestamp).format('DD.MM.yyyy HH:mm')}</td>
                  {/* ------- DELETE USER ------- */}
                  <td>
                    {mainUserId !== user.id &&
                      <Button
                        style={{ backgroundColor: '#E57373' }}
                        disabled={isMainUser}
                        title={`${translate('deleteUser')} ${user.email}`}
                        onClick={() => {
                          setModalContent(<DeleteUser currUser={user} />)
                          showModal()
                        }}
                      >
                        {translate('delete')}
                      </Button>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      }
    </>
  )
}
