import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import Library from '../../Library'
import { Filter } from './components/Filter'
import { useSelector } from 'react-redux'
import LinearLoader from '../../components/Loaders/LinearLoader'
import styled from 'styled-components'
import useWindowHeight from '../../hooks/useWindowHeight'
import { useLocation } from 'react-router-dom'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'

const Container = styled.div`
  position: fixed;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
`

const Wrapper = styled.div`
  margin: 0px 0px 0px 5px;
  min-width: 1370px;
  max-width: 1370px;
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const pageState = useSelector(state => state.page[pageName])
  const containerRef = useRef()
  useWindowHeight(containerRef, 85)
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const { cars, employees, customers, carriers, expenses, routes, loading, error } = useSelector(state => state.data)
  const drivers = useMemo(() => employees.filter(employee => employee.isDriver), [employees])

  const filteredRoutes = useMemo(() => {
    if (routes.length) {
      const { driver, startDate, endDate } = filterQuery

      const matchesDriver = route => !driver || route.driver === driver
      const matchesStartDate = route =>
        !startDate || moment(route.dateOfDeparture).isSameOrAfter(startDate, 'day')
      const matchesEndDate = route =>
        !endDate || moment(route.dateOfDeparture).isSameOrBefore(endDate, 'day')

      const filtered = routes.filter(
        route =>
          matchesDriver(route) &&
          matchesStartDate(route) &&
          matchesEndDate(route)
      )

      pageState.sort.key && Library.sort(filtered, pageState.sort)

      return filtered
    }
    return []
  }, [routes, filterQuery, pageState.sort])

  useEffect(() => {
    if (!isDataLoaded || loading) return
    setDataReady(true)
  }, [isDataLoaded, loading])

  const [routesReplaced, setRoutesReplaced] = useState([])
  useEffect(() => {
    if (!filteredRoutes || !filteredRoutes.length || !isDataLoaded || !dataReady || loading || error) {
      return
    }
    let replaced = []
    filteredRoutes.forEach((originalItem) => {
      let item = { ...originalItem }
      Object.keys(item).forEach((key) => {
        switch (key) {
          case 'driver': {
            item[key] = Library.findNameByKey(drivers, item.driver)
            break
          }
          case 'car': {
            item[key] = Library.findNameByKey(cars, item.car)
            break
          }
          case 'customer': {
            item[key] = Library.findNameByKey(customers, item.customer)
            break
          }
          case 'carrier': {
            item[key] = Library.findNameByKey(carriers, item.carrier)
            break
          }
          default: { }
        }
      })
      replaced.push(item)
    })
    setRoutesReplaced(replaced)
  }, [isDataLoaded, loading, error, dataReady, filteredRoutes, carriers, cars, customers, drivers, pageState.sort])

  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', width: 70, type: 'increment' },
    { id: 'dateOfDeparture', name: 'Дата выезда', dataType: 'date', type: 'date', width: 105 },
    { id: 'arrivalDate', name: 'Дата приезда', dataType: 'date', type: 'date', width: 105 },
    { id: 'accountNumber', name: 'Номер заказа', width: 70 },
    { id: 'customer', name: 'Заказчик', width: 150 },
    { id: 'driver', name: 'Водитель', width: 150 },
    { id: 'car', name: 'Автомобиль', width: 150 },
    { id: 'loadingAddress', name: 'Адрес погрузки', width: 200 },
    { id: 'unloadingAddress', name: 'Адрес выгрузки', width: 200 },
    { id: 'driverSalary', name: 'Зарплата водителя', dataType: 'number', width: 70 },
    { id: 'fine', name: 'Штраф', width: 100 },
    { id: 'comment', name: 'Комментарий', width: 150 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  return (
    <Container ref={containerRef}>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {dataReady && isDataLoaded &&
        <Wrapper>
          <Filter
            routes={routes}
            filteredRoutes={filteredRoutes || []}
            drivers={drivers}
            expenses={expenses}
            filterQuery={filterQuery}
            setFilterQuery={setFilterQuery}
            pageName={pageName}
          />

          {filteredRoutes?.length ?
            <VirtualizedList
              ref={virtualizedListRefs}
              offsetHeight={180}
              overflowX={false}
              headers={headers}
              pageName={pageName}
              items={routesReplaced}
              filterQuery={filterQuery}
              navigateTo='routes'
              contextMenu={false}
            />
            :
            <div style={{ marginLeft: '10px', color: 'var(--text-color)' }}>-</div>
          }
        </Wrapper>
      }
    </Container >
  )
}
