import React from 'react'

export default function BarChartIcon({ color = '#FFFFFF', width = '20', height = '20', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" {...props}>
      <defs>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0.000000, 1.000000)" fill={color}>
          <path d="M16,13.031 L0.984,13.031 L0.984,0.016 L0.027,0.016 L0,13.95 L0.027,13.95 L0.027,13.979 L16,13.95 L16,13.031 Z" className="si-glyph-fill">

          </path>
          <path d="M4.958,7.021 L2.016,7.021 L2.016,11.985 L4.958,11.985 L4.958,7.021 L4.958,7.021 Z" className="si-glyph-fill">

          </path>
          <path d="M9.969,5.047 L7.016,5.047 L7.016,11.969 L9.969,11.969 L9.969,5.047 L9.969,5.047 Z" className="si-glyph-fill">

          </path>
          <path d="M14.953,3.031 L12,3.031 L12,11.978 L14.953,11.978 L14.953,3.031 L14.953,3.031 Z" className="si-glyph-fill">

          </path>
        </g>
      </g>
    </svg>
  )
}