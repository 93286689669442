const initialState = {
  isDataLoaded: false
}

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATA_LOADED':
      return {
        ...state,
        isDataLoaded: action.payload
      }

    case 'LOGOUT':
      return initialState
    default:
      return state
  }
}

export default loadingReducer