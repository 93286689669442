import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import axios from 'axios'
import { AddressPicker } from '../AddressPicker'
import ModalContext from '../../context/ModalContext'
import useDebounce from '../../hooks/useDebounce'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const LabelAfter = styled.span`
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
  }
`

const Label = styled.label`
  position: absolute;
  cursor: text;
  transform-origin: 0% 100%;
  text-align: initial;
  width: 100%;
  line-height: 1rem;
  transition: color 0.2s, font-size 0.2s, top 0.2s, padding-top 0.2s, transform 0.2s${props => props.hasValue ? ', none' : ''};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};

  ${({ $isFocused, $hasValue }) =>
    $isFocused || $hasValue
      ? css`
          top: -26px;
          font-size: .8rem;
          transform: translateY(12px);
        `
      : css`
          top: 15px;
          font-size: .9rem;
          transform: none;
        `}

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  witdh: 100%;
  height: 2.9rem;
  margin: 0 0 8px;
  transition: box-shadow .3s, border .3s;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  color: var(--input-text-color);
  cursor: text;

  ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
        `
      : $forceDarkMode
      && css`
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `}
  ${({ $textOverflow }) =>
    $textOverflow &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}
  ${({ $showClearButton }) =>
    $showClearButton &&
    css`
      &:after {
        content: '';
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        width: 24px;
        pointer-events: none;
      }
    `}
`

const ClearButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ClearButton = styled.button`
  -webkit-transition: color 150ms;
  transition: color 150ms;
  background: none;
  border: none;
  padding: 3px 0 0 0;
  font-size: 12px;
  cursor: pointer;

  svg {
    fill: var(--input-element-color);
    transition: fill 0.2s;
  }

  &:hover:not(:disabled) svg {
    fill: var(--input-focused-color);
    filter: brightness(0.8);
    transition: fill 0.2s, filter 0.2s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-disabled);
      cursor: default;
    `
  }
`

const SetAddressButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const SetAddressButton = styled.button`
  transition: color 150ms;
  background: none;
  border: none;
  padding: 3px 0 0 0;
  font-size: 12px;
  color: ${({ $forceDarkMode }) => ($forceDarkMode ? '#FFFFFF' : 'var(--input-element-color)')};
  cursor: pointer;

  svg {
    fill: ${({ $forceDarkMode }) => ($forceDarkMode ? '#FFFFFF' : 'var(--input-element-color)')};
    transition: fill 0.2s;
  }

  &:hover:not(:disabled) svg {
    fill: var(--input-focused-color);
    filter: brightness(0.8);
    transition: fill 0.2s, filter 0.2s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
    color: var(--color-disabled);
    cursor: default;
  `}
`

const MapSvg = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 1024 1024"><path d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z" /><path d="M512 448a64 64 0 1 0 0-128 64 64 0 0 0 0 128zm0 64a128 128 0 1 1 0-256 128 128 0 0 1 0 256zm345.6 192L960 960H672v-64H352v64H64l102.4-256h691.2zm-68.928 0H235.328l-76.8 192h706.944l-76.8-192z" /></svg>
  )
}

const Suggestions = styled.ul`
  padding-left: 0;
  color: var(--input-text-color);
  background: var(--background-color);
  border: 1px solid var(--line-color);
  margin: 0;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  z-index: 9999;
  max-height: ${({ $optionsHeight }) => ($optionsHeight)}px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  & li.selected {
    background-color: #e0e0e0;
  }
  ${({ $isSuggestionsOpen, $position }) => `
    top: ${$position === 'up' ? '2px' : '47px'};
    visibility: ${$isSuggestionsOpen ? 'visible' : 'hidden'};
    opacity: ${$isSuggestionsOpen ? '1' : '0'};
    transform: ${$position === 'up' ? ($isSuggestionsOpen ? 'translateY(-100%)' : 'scaleY(1)') : ($isSuggestionsOpen ? 'scaleY(1)' : 'scaleY(1)')};
  `};
`

const Suggestion = styled.li`
  cursor: pointer;
  font-size: 16px;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
  &:hover {
    background-color: var(--background-tertiary-color);
  }
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--background-tertiary-color)' : 'transparent')};
`

const SuggestionText = styled.div`
  flex-grow: 1;
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-right: 2px;
  color: hsl(0, 0%, 80%);
  font-size: 4px;
  line-height: 1;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
}`

const GeocodeStatusWrapper = styled.div`
  position: absolute;
  top: -9px;
  right: 0;
  margin-right: 2px;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  color: ${({ $isFocused, $forceDarkMode, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : $forceDarkMode ? '#FFFFFF' : 'var(--input-label-color)'};
}`

export default function AddressInput(props) {
  const { label, type = 'text', style, name, id, form, setForm, borderOption = 1, optionsHeight = 200, forceDarkMode = false, textOverflow = true, disabled, readOnly, onChange, onClick, onBlur, onFocus, onKeyDown, isClearable = false, debounceDelay, autoComplete = 'off', ...rest } = props
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(form.mapAddress || '')
  const [hasValue, setHasValue] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false)
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1)
  const [prevInputValuesMap, setPrevInputValuesMap] = useState(new Map())
  const [showClearButton, setShowClearButton] = useState(false)
  const inputRef = useRef(null)
  const focusedInputNameRef = useRef(null)
  const { showModal, setModalContent, setModalPadding } = useContext(ModalContext)
  const suggestionsRef = useRef(null)
  const generateRefs = (length) => Array.from({ length }, () => React.createRef())
  const [suggestionRefs, setSuggestionRefs] = useState(generateRefs(suggestions.length))
  const [dropdownPosition, setDropdownPosition] = useState('down')

  const checkDropdownPosition = useCallback(() => {
    if (suggestionsRef.current) {
      const rect = suggestionsRef.current.getBoundingClientRect()
      const spaceToBottom = window.innerHeight - rect.bottom
      const spaceToTop = rect.top

      if (spaceToBottom < optionsHeight && spaceToTop > optionsHeight) {
        setDropdownPosition('up')
      } else {
        setDropdownPosition('down')
      }
    }
  }, [optionsHeight])

  const [address, setAddress] = useState(form.mapAddress)
  const [latitude, setLatitude] = useState(form.mapAddressLatitude)
  const [longitude, setLongitude] = useState(form.mapAddressLongitude)

  function getAddressFromResponse(responseItem) {
    const geoObject = responseItem.GeoObject
    const countryName = geoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails?.Country?.CountryName || ''
    const addressLine = geoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails?.Country?.AddressLine || ''

    const longitude = parseFloat(geoObject?.Point?.pos.split(" ")[0])
    const latitude = parseFloat(geoObject?.Point?.pos.split(" ")[1])

    const addressWithoutCountry = addressLine.replace(`${countryName}, `, '')
    return { address: addressWithoutCountry, latitude, longitude }
  }

  const [debouncedHandleChange, isDebouncing] = useDebounce(async (value) => {
    if (value.length > 2) {
      try {
        const response = await axios.get(
          `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${process.env.REACT_APP_YANDEX_MAP_API_KEY}&geocode=${value}`
        )
        const addresses = response.data.response.GeoObjectCollection.featureMember.map(getAddressFromResponse)
        const validAddresses = addresses.filter(address => address.address !== '')

        setSuggestions(validAddresses)
        setLatitude(validAddresses[0]?.latitude)
        setLongitude(validAddresses[0]?.longitude)
      } catch (error) {
        console.error(error)
      }
    }
  }, debounceDelay)

  useEffect(() => {
    setHasValue(!!form.mapAddress)
    setInputValue(form.mapAddress || '')
  }, [form.mapAddress])

  useEffect(() => {
    setInputValue(address || '')
  }, [address])

  useEffect(() => {
    setSuggestionRefs(generateRefs(suggestions.length))
  }, [suggestions])

  const inputHasValue = form.mapAddress !== null && form.mapAddress !== ''

  useEffect(() => {
    if (isDebouncing) {
      setShowClearButton(false)
    } else {
      setShowClearButton(inputValue !== '')
    }
  }, [isDebouncing, inputValue])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleClick = useCallback((e) => {
    const inputValue = e.target.value
    setInputValue(inputValue)
    if (!inputValue || suggestions.length === 0) {
      return
    }
    if (!isSuggestionsOpen) {
      checkDropdownPosition()
      setIsSuggestionsOpen(true)
    }
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick, suggestions, isSuggestionsOpen, checkDropdownPosition])

  const handleBlur = useCallback((e) => {
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
    if (suggestions.length && selectedSuggestionIndex === -1 && inputValue !== suggestions[0].address) {
      console.log(suggestions)
      setInputValue(suggestions[0].address)
      setAddress(suggestions[0].address)
    }
    setTimeout(() => {
      setIsFocused(false)
      setSuggestions([])
      setIsSuggestionsOpen(false)
    }, 150)
  }, [onBlur, inputValue, suggestions, selectedSuggestionIndex])

  const handleChange = useCallback((e) => {
    const value = e.target.value
    setInputValue(value)

    if (value === '') {
      setAddress('')
      setLatitude(null)
      setLongitude(null)
    }

    if (typeof onChange === 'function') {
      if (debounceDelay) {
        debouncedHandleChange(value)
      } else {
        onChange(e)
      }
    }
    if (!isSuggestionsOpen) {
      checkDropdownPosition()
      setIsSuggestionsOpen(true)
    }
  }, [onChange, debounceDelay, debouncedHandleChange, checkDropdownPosition, isSuggestionsOpen])

  const handleSuggestionClick = useCallback(
    (suggestion) => {
      setInputValue(suggestion.address)
      setHasValue(true)
      setSuggestions([])
      setSelectedSuggestionIndex(-1)

      setAddress(suggestion.address)
      setLatitude(suggestion.latitude)
      setLongitude(suggestion.longitude)

      const fakeEvent = {
        target: {
          name,
          type,
          value: suggestion.address,
        },
      }
      if (typeof onChange === 'function') {
        onChange(fakeEvent)
      }
    },
    [onChange, name, type]
  )

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    focusedInputNameRef.current = name
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus, name])

  const handleClearValue = useCallback((e) => {
    e.preventDefault()
    if (!readOnly) {
      const currentValue = inputRef.current.value
      setPrevInputValuesMap((prevState) => {
        const newMap = new Map(prevState)
        if (!newMap.has(name)) {
          newMap.set(name, [])
        }
        newMap.get(name).push(currentValue)
        return newMap
      })
      if (inputRef.current) {
        inputRef.current.value = ''
        setInputValue('')
        setAddress('')
        setLatitude(null)
        setLongitude(null)
        const event = new Event('input', { bubbles: true, cancelable: true })
        inputRef.current.dispatchEvent(event)
      }
      if (typeof onChange === 'function') {
        const fakeEvent = {
          target: {
            name,
            value: '',
            type: 'text',
          },
        }
        onChange(fakeEvent)
      }
    }
  }, [onChange, readOnly, name])

  const handleKeyDown = useCallback((e) => {
    if (isFocused && e.target === inputRef.current) {
      //=====DELETE=====//
      if (e.key === 'Delete' && !readOnly && inputRef.current.value !== '') {
        setInputValue('')
        if (typeof onChange === 'function') {
          const fakeEvent = {
            target: {
              name,
              value: '',
              type: 'text',
            },
          }
          onChange(fakeEvent)
        }
      }
      //=====ENTER=====//
      else if (e.key === 'Enter') {
        e.preventDefault()
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    }
  }, [onKeyDown, inputRef, isFocused, readOnly, onChange, name])

  useEffect(() => {
    setForm(prevState => ({
      ...prevState,
      mapAddress: address,
      mapAddressLatitude: latitude,
      mapAddressLongitude: longitude
    }))
  }, [address, latitude, longitude, setForm])

  useEffect(() => {
    const handleUndo = (e) => {
      if (!isFocused) {
        return
      }
      if (
        e.ctrlKey &&
        e.code === 'KeyZ' &&
        document.activeElement === inputRef.current &&
        prevInputValuesMap.has(focusedInputNameRef.current)
      ) {
        if (e.target === inputRef.current) {
          const inputPrevValues = prevInputValuesMap.get(focusedInputNameRef.current)
          const restoredValue = inputPrevValues.pop()
          if (restoredValue !== undefined) {
            setInputValue(restoredValue)
            setPrevInputValuesMap((prevState) => {
              const newMap = new Map(prevState)
              const prevInputValues = newMap.get(focusedInputNameRef.current)
              prevInputValues.pop()
              if (prevInputValues.length === 0) {
                newMap.delete(focusedInputNameRef.current)
              } else {
                newMap.set(focusedInputNameRef.current, prevInputValues)
              }
              return newMap
            })
            const fakeEvent = {
              target: {
                name: focusedInputNameRef.current,
                value: restoredValue,
                type: type || 'text',
              },
            }
            if (typeof onChange === 'function') {
              onChange(fakeEvent)
            }
          }
        }
      }
    }

    document.addEventListener('keydown', handleUndo)
    return () => {
      document.removeEventListener('keydown', handleUndo)
    }
  }, [prevInputValuesMap, isFocused, onChange, type])

  return (
    <Wrapper>
      <LabelAfter>
        <Label
          $isFocused={isFocused}
          $hasValue={hasValue || inputHasValue}
          $forceDarkMode={forceDarkMode}
          disabled={disabled}
          htmlFor={id}
        >
          {label}
        </Label>
      </LabelAfter>
      {isDebouncing ?
        <LoadingIndicator>
          <span></span>
          <span></span>
          <span></span>
        </LoadingIndicator>
        :
        <GeocodeStatusWrapper
          $isFocused={isFocused}
          $forceDarkMode={forceDarkMode}
          disabled={disabled}
        >
          <span>{`${latitude ? latitude.toFixed(6) : '0.00'}, ${longitude ? longitude.toFixed(6) : '0.00'}`}</span>
        </GeocodeStatusWrapper>}
      <InputWrapper
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        disabled={disabled}
      >
        <Input
          style={style}
          ref={inputRef}
          type={type}
          value={inputValue}
          name={name}
          id={id}
          autoComplete={autoComplete}
          $isFocused={isFocused}
          $forceDarkMode={forceDarkMode}
          $borderHeight={borderHeight}
          $focusedBoxShadow={focusedBoxShadow}
          $textOverflow={textOverflow}
          disabled={false}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...rest}
        />
        <Suggestions $isSuggestionsOpen={isSuggestionsOpen} $position={dropdownPosition} $optionsHeight={optionsHeight} ref={suggestionsRef}>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              ref={suggestionRefs[index]}
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              onMouseDown={(e) => e.preventDefault()}
              $isSelected={selectedSuggestionIndex === index}
            >
              <SuggestionText>
                {suggestion.address}
              </SuggestionText>
            </Suggestion>
          ))}
        </Suggestions>
        {isClearable && showClearButton &&
          <ClearButtonWrapper>
            <ClearButton
              onClick={handleClearValue}
              $forceDarkMode={forceDarkMode}
              $isFocused={isFocused}
            >
              <svg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'><path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path></svg>
            </ClearButton>
          </ClearButtonWrapper>
        }
        <SetAddressButtonWrapper>
          <SetAddressButton
            onClick={(e) => {
              e.preventDefault()
              setModalContent(<AddressPicker
                address={address}
                setAddress={setAddress}
                latitude={latitude}
                setLatitude={setLatitude}
                longitude={longitude}
                setLongitude={setLongitude}
              />)
              setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
              showModal()
            }}
            $forceDarkMode={forceDarkMode}
            $isFocused={isFocused}
          >
            <MapSvg />
          </SetAddressButton>
        </SetAddressButtonWrapper>
      </InputWrapper>
    </Wrapper>
  )
}
