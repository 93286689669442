import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

const useRouteParams = () => {
  const location = useLocation()

  const paramsId = useMemo(() => {
    // Regular expression to match a UUID
    const regex = /\/routes\/edit\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/
    const match = location.pathname.match(regex)
    return match ? match[1] : null
  }, [location.pathname])

  return paramsId
}

export default useRouteParams
