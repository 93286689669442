import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const LabelAfter = styled.span`
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: 0.2s opacity ease-out, 0.2s color ease-out;
  }
`

const Label = styled.label`
  position: absolute;
  cursor: text;
  transform-origin: 0% 100%;
  text-align: initial;
  width: 100%;
  line-height: 1rem;
  transition: color 0.2s, font-size 0.2s, top 0.2s, padding-top 0.2s, transform 0.2s${props => props.hasValue ? ', none' : ''};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};

  ${({ $isFocused, $hasValue, readOnly }) =>
    $isFocused || $hasValue || readOnly
      ? css`
          top: -26px;
          font-size: .8rem;
          transform: translateY(12px);
        `
      : css`
          top: 15px;
          font-size: .9rem;
          transform: none;
        `}

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  height: 2.9rem;
  margin: 0 0 8px;
  transition: box-shadow .3s, border .3s;
  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--input-text-color);
  }

  ${({ $forceDarkMode }) =>
    $forceDarkMode
    && css`
        html[data-theme='light'] & {
              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
              transition: background-color 5000s ease-in-out 0s;
              -webkit-text-fill-color: var(--text-inverted-color);
            }
          }
        `
  }
  
  ${({ disabled }) =>
    disabled
    && css`
        border-bottom: 1px dotted var(--border-color-disabled) !important;
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: var(--text-color-disabled) !important;
        }
        `
  }
`

const Input = styled.input`
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  color: var(--input-text-color);
  cursor: text;

  ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
        `
      : $forceDarkMode
      && css`
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `}

  ${({ $textOverflow }) =>
    $textOverflow &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  ${({ $showClearButton }) =>
    $showClearButton &&
    css`
      &:after {
        content: '';
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        width: 24px;
        pointer-events: none;
      }
    `}
    ${({ readOnly, disabled }) =>
    readOnly && !disabled &&
    css`
    background-color: var(--input-read-only-bg);
  `}
`

const ClearButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ClearButton = styled.button`
  -webkit-transition: color 150ms;
  transition: color 150ms;
  background: none;
  border: none;
  padding: 3px 0 0 0;
  font-size: 12px;
  cursor: pointer;

  svg {
    fill: var(--input-element-color);
    transition: fill 0.2s;
  }

  &:hover:not(:disabled) svg {
    fill: var(--input-focused-color);
    filter: brightness(0.8);
    transition: fill 0.2s, filter 0.2s;
  }

  ${({ $forceDarkMode }) =>
  $forceDarkMode &&
  css`
    html[data-theme='light'] & {
      &:hover:not(:disabled) svg {
        fill: var(--input-element-focused-color);
      }
      svg {
        fill: var(--input-element-color-variant);
      }
    }
  `
}

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-disabled);
      cursor: default;
    `
  }
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const ShowPasswordButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const ShowPasswordButton = styled.button`
  display: flex;
  align-items: center;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  background: none;
  border: none;
  padding: 3px 0 0 0;
  font-size: 12px;
  cursor: pointer;

  svg {
    fill: var(--input-element-color);
    transition: fill 0.2s;
  }

  &:hover:not(:disabled) svg {
    fill: var(--input-focused-color);
  }

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        &:hover:not(:disabled) svg {
          fill: var(--input-element-focused-color);
        }
        svg {
          fill: var(--input-element-color-variant);
        }
      }
    `
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-disabled);
      cursor: default;
    `
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-right: 2px;
  color: hsl(0, 0%, 80%);
  font-size: 4px;
  line-height: 1;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
`

const EyeDisabled = () => {
  return (
    <svg
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      width='22'
      height='22'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>eye-disabled-other</title>
      <g>
        <path
          d='M67.76,353.2,98,323a251.36,251.36,0,0,1-52.64-67C88.41,176.77,168.21,128,256,128a234.94,234.94,0,0,1,34.42,2.54l36.11-36.11A277.28,277.28,0,0,0,256,85.33c-107.6,0-204.85,61.78-253.81,161.25a21.33,21.33,0,0,0,0,18.83A295.48,295.48,0,0,0,67.76,353.2Z'
        />
        <path
          d='M410.1,132.07l95.65-95.65A21.33,21.33,0,1,0,475.58,6.25L6.25,475.58a21.33,21.33,0,1,0,30.17,30.17l104.2-104.2A276.58,276.58,0,0,0,256,426.67c107.6,0,204.85-61.78,253.81-161.25a21.33,21.33,0,0,0,0-18.83C486.31,198.83,451.67,159.78,410.1,132.07ZM320,256a64,64,0,0,1-91.57,57.74l85.31-85.31A63.55,63.55,0,0,1,320,256ZM256,384a233.77,233.77,0,0,1-82.74-15.09l24-24A106.61,106.61,0,0,0,345,197.21L379.19,163a247.63,247.63,0,0,1,87.46,93C423.59,335.23,343.79,384,256,384Z'
        />
        <path
          d='M149.33,256a107.13,107.13,0,0,0,1,14.62L270.62,150.34A106.67,106.67,0,0,0,149.33,256Z'
        />
      </g>
    </svg>
  )
}

const EyeEnabled = () => {
  return (
    <svg
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      width='22'
      height='22'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <title>eye-other</title>
      <path
        d='M256,426.67c-107.6,0-204.86-61.78-253.81-161.25a21.33,21.33,0,0,1,0-18.83C51.14,147.11,148.4,85.33,256,85.33s204.86,61.78,253.81,161.25a21.33,21.33,0,0,1,0,18.83C460.86,364.89,363.6,426.67,256,426.67ZM45.35,256C88.41,335.23,168.21,384,256,384s167.59-48.77,210.65-128C423.59,176.77,343.79,128,256,128S88.41,176.77,45.35,256Z'
      />
      <path
        d='M256,362.67A106.67,106.67,0,1,1,362.67,256,106.79,106.79,0,0,1,256,362.67ZM256,192a64,64,0,1,0,64,64A64.07,64.07,0,0,0,256,192Z'
      />
    </svg>
  )
}

export default function TextInput(props) {
  const { label, type = 'text', name, id, value, style, borderOption = 1, textOverflow = true, disabled, readOnly, onChange, onClick, onBlur, onFocus, onKeyDown, debounceDelay, isClearable = false, forceDarkMode = false, autoComplete = 'off', ...rest } = props
  const [debouncedHandleChange, isDebouncing] = useDebounce(onChange, debounceDelay)
  const [showClearButton, setShowClearButton] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(value || '')
  const [hasValue, setHasValue] = useState(false)
  const [prevInputValuesMap, setPrevInputValuesMap] = useState(new Map())
  const inputRef = useRef(null)
  const focusedInputNameRef = useRef(null)
  const [passwordInputType, setPasswordInputType] = useState('password')

  const togglePasswordVisibility = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')
  }

  useEffect(() => {
    setHasValue(!!value)
    setInputValue(value || '')
  }, [value])

  const inputHasValue = value !== null && value !== ''

  useEffect(() => {
    if (isDebouncing) {
      setShowClearButton(false)
    } else {
      setShowClearButton(inputValue !== '')
    }
  }, [isDebouncing, inputValue])

  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState(true)
  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = true
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleClick = useCallback((e) => {
    if (typeof onClick === 'function') {
      onClick(e)
    }
  }, [onClick])

  const handleBlur = useCallback((e) => {
    if (inputRef.current.value) {
      setTimeout(() => {
        setIsFocused(false)
      }, debounceDelay)
    } else {
      setIsFocused(false)
    }
    if (typeof onBlur === 'function') {
      onBlur(e)
    }
  }, [onBlur, debounceDelay])

  const handleChange = useCallback(
    (e) => {
      setInputValue(e.target.value)

      if (typeof onChange === 'function') {
        if (debounceDelay) {
          debouncedHandleChange(e)
        } else {
          onChange(e)
        }
      }
    },
    [onChange, debounceDelay, debouncedHandleChange]
  )

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    focusedInputNameRef.current = name
    if (typeof onFocus === 'function') {
      onFocus(e)
    }
  }, [onFocus, name])

  const handleClearValue = useCallback((e) => {
    e.preventDefault()
    if (!readOnly) {
      const currentValue = inputRef.current.value
      setPrevInputValuesMap((prevState) => {
        const newMap = new Map(prevState)
        if (!newMap.has(name)) {
          newMap.set(name, [])
        }
        newMap.get(name).push(currentValue)
        return newMap
      })
      if (inputRef.current) {
        inputRef.current.value = ''
        setInputValue('')
        const event = new Event('input', { bubbles: true, cancelable: true })
        inputRef.current.dispatchEvent(event)
      }
      if (typeof onChange === 'function') {
        const fakeEvent = {
          target: {
            name,
            value: '',
            type: 'text',
          },
        }
        onChange(fakeEvent)
      }
    }
  }, [onChange, readOnly, name])

  const handleKeyDown = useCallback((e) => {
    if (isFocused && e.target === inputRef.current) {
      //=====DELETE=====//
      if (e.key === 'Delete' && !readOnly && inputRef.current.value !== '') {
        setPrevInputValuesMap((prevState) => {
          const newMap = new Map(prevState)
          if (!newMap.has(name)) {
            newMap.set(name, [])
          }
          newMap.get(name).push(inputRef.current.value)
          return newMap
        })
        setInputValue('')
        if (typeof onChange === 'function') {
          const fakeEvent = {
            target: {
              name,
              value: '',
              type: 'text',
            },
          }
          onChange(fakeEvent)
        }
      }
      //=====ENTER=====//
      else if (e.key === 'Enter') {
        e.preventDefault()
      }
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
      }
    }
  }, [onKeyDown, inputRef, isFocused, readOnly, onChange, name])

  useEffect(() => {
    const handleUndo = (e) => {
      if (!isFocused) {
        return
      }
      if (
        e.ctrlKey &&
        e.code === 'KeyZ' &&
        document.activeElement === inputRef.current &&
        prevInputValuesMap.has(focusedInputNameRef.current)
      ) {
        if (e.target === inputRef.current) {
          const inputPrevValues = prevInputValuesMap.get(focusedInputNameRef.current)
          const restoredValue = inputPrevValues.pop()
          if (restoredValue !== undefined) {
            setInputValue(restoredValue)
            setPrevInputValuesMap((prevState) => {
              const newMap = new Map(prevState)
              const prevInputValues = newMap.get(focusedInputNameRef.current)
              prevInputValues.pop()
              if (prevInputValues.length === 0) {
                newMap.delete(focusedInputNameRef.current)
              } else {
                newMap.set(focusedInputNameRef.current, prevInputValues)
              }
              return newMap
            })
            const fakeEvent = {
              target: {
                name: focusedInputNameRef.current,
                value: restoredValue,
                type: type || 'text',
              },
            }
            if (typeof onChange === 'function') {
              onChange(fakeEvent)
            }
          }
        }
      }
    }

    document.addEventListener('keydown', handleUndo)
    return () => {
      document.removeEventListener('keydown', handleUndo)
    }
  }, [prevInputValuesMap, isFocused, onChange, type])

  return (
    <Wrapper>
      <LabelAfter>
        <Label
          readOnly={readOnly}
          disabled={disabled}
          htmlFor={id}
          title={label}
          $forceDarkMode={forceDarkMode}
          $isFocused={isFocused}
          $hasValue={hasValue || inputHasValue}
        >
          {label}
        </Label>
      </LabelAfter>
      {isDebouncing &&
        <LoadingIndicator>
          <span></span>
          <span></span>
          <span></span>
        </LoadingIndicator>}
      <InputWrapper
        disabled={disabled}
        $isFocused={isFocused}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $forceDarkMode={forceDarkMode}
      >
        <Input
          style={style}
          ref={inputRef}
          type={type === 'password' ? passwordInputType : type}
          value={inputValue}
          name={name}
          id={id}
          autoComplete={autoComplete}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          $showClearButton={showClearButton}
          $borderHeight={borderHeight}
          $textOverflow={textOverflow}
          $forceDarkMode={forceDarkMode}
          $focusedBoxShadow={focusedBoxShadow}
          $isFocused={isFocused}
          {...rest}
        />
        {(isClearable && !disabled && !readOnly) && showClearButton &&
          <ClearButtonWrapper>
            <ClearButton
              onClick={handleClearValue}
              $isFocused={isFocused}
              $forceDarkMode={forceDarkMode}
            >
              <svg height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false'><path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path></svg>
            </ClearButton>
          </ClearButtonWrapper>
        }
        {type === 'password' && !disabled && !readOnly &&
          <ShowPasswordButtonWrapper>
            <ShowPasswordButton
              onClick={(e) => {
                e.preventDefault()
                togglePasswordVisibility()
              }}
              $isFocused={isFocused}
              $forceDarkMode={forceDarkMode}
            >
              {passwordInputType === 'password' ?
                <EyeDisabled />
                :
                <EyeEnabled />
              }
            </ShowPasswordButton>
          </ShowPasswordButtonWrapper>
        }
      </InputWrapper>
    </Wrapper>
  )
}