import React from 'react'
import styled from 'styled-components'
import { useLanguage } from '../context/LanguageContext'
import NotFoundIcon from '../components/Icons/NotFoundIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  height: calc(100vh - 128px);
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
`

const NotFoundIConWrapper = styled.div`
  svg {
    g {
      fill: var(--text-secondary-color);
    }
  }
`

const Title = styled.div`
  font-size: 22px;
  color: var(--text-secondary-color);
`

const SubTitle = styled.div`
  color: var(--text-secondary-color);
`

const text = {
  title: {
    en: '404: Not Found',
    ru: '404: Не найдено'
  },
  subTitle: {
    en: 'Oops! It seems like you\'re lost.',
    ru: 'Ой! Кажется, вы заблудились.'
  }
}

export const Error = () => {
  const { language } = useLanguage()

  return (
    <Wrapper>
      <NotFoundIConWrapper>
        <NotFoundIcon size={105} />
      </NotFoundIConWrapper>
      <Title>{text.title[language]}</Title>
      <SubTitle>{text.subTitle[language]}</SubTitle>
    </Wrapper>
  )
}
