import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useLoadUserSettings = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const data = JSON.parse(localStorage.getItem('userData'))

      if (data && data.userSettings) {
        dispatch({ type: 'SET_USER_SETTINGS', payload: data.userSettings })
      }
    } catch (error) {
      console.error('Failed to parse user data from localStorage:', error)
    }
  }, [dispatch])
}
