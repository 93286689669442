const initialState = {
  routes: {
    filterQuery: {
      search: '',
      status: '',
      overdueDebt: false,
      paymentStatus: '',
      paymentType: '',
      transportCategory: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'dateOfDeparture',
      direction: true,
      dataType: 'date'
    },
    activeRowId: '',
  },
  expenses: {
    filterQuery: {
      search: '',
      status: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: ''
  },
  refueling: {
    filterQuery: {
      search: '',
      status: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: ''
  },
  inventory: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  stock: {
    filterQuery: {
      search: '',
      status: '',
      direction: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: ''
  },
  maintenance: {
    filterQuery: {
      search: '',
      status: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: ''
  },
  salary: {
    filterQuery: {
      driver: '',
      startDate: '',
      endDate: '',
    },
    sort: {
      key: 'dateOfDeparture',
      direction: true,
      dataType: 'date'
    },
    activeRowId: ''
  },
  profit: {
    filterQuery: {
      car: '',
      startDate: '',
      endDate: '',
    },
  },
  profitRoutes: {
    sort: {
      key: 'dateOfDeparture',
      direction: true,
      dataType: 'date'
    },
    activeRowId: '',
  },
  profitRefueling: {
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: '',
  },
  profitExpenses: {
    sort: {
      key: 'date',
      direction: true,
      dataType: 'date'
    },
    activeRowId: '',
  },
  suppliers: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  carriers: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  customers: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  employees: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  cars: {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  'hired-cars': {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
  'hired-drivers': {
    filterQuery: {
      search: '',
    },
    sort: {
      key: 'name',
      direction: true,
      dataType: 'string'
    },
    activeRowId: ''
  },
}

export const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER_QUERY':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          filterQuery: {
            ...state[action.page].filterQuery,
            ...action.payload,
          },
        },
      }
    case 'GET_FILTER_QUERY':
      return state[action.page].filterQuery
    case 'SET_ACTIVE_ROW_ID':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          activeRowId: action.payload,
        },
      }
    case 'SET_SORT':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          sort: {
            key: action.payload.key,
            direction: action.payload.direction,
            dataType: action.payload.dataType,
          },
        },
      }
    case 'RESET_PAGE_STATE':
      return {
        ...state,
        [action.page]: { ...initialState[action.page] }
      }
    case 'RESET_ALL_PAGES_STATE':
      return { ...initialState }
    default:
      return state
  }
}