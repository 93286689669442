import React, { useContext, useState } from 'react'
import Button from '../Buttons/Button'
import UploadFiles from '../UploadFiles/UploadFiles'
import ConfirmationWindow from './ConfirmationWindow'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ModalContext from '../../context/ModalContext'
import EntryHistory from '../ChangeHistory/EntryHistory'
import { useLanguage } from '../../context/LanguageContext'
import { useSelector } from 'react-redux'
import { useAuth } from '../../hooks/useAuth'
import useTranslate from '../../hooks/useTranslate'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  height: 64px;
`

export default function ButtonsBlock(props) {
  const {
    handler,
    form,
    message,
    edit,
    label = '',
    fileFolder,
    path,
    request,
    changeHistoryTableName,
    changeHistoryId,
    renderChangeDetails,
  } = props

  const navigate = useNavigate()
  const { isAdmin, roles } = useAuth()
  const { language } = useLanguage()
  const translate = useTranslate()
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const { companyId } = useSelector(state => state.data)
  const { showModal, closeModal, setModalContent, setModalPadding } = useContext(ModalContext)

  const hasFilesRole = isAdmin || roles.some(role =>
    role.companyId === companyId && role.value === 'files'
  )

  return (
    <Wrapper>
      {/* ------------(BACK)------------ */}
      <Button
        style={{
          width: '145px',
          color: '#8e8e8e',
          border: 'solid #8e8e8e 1px',
          backgroundColor: '#FFFFFF',
        }}
        disabled={buttonsDisabled}
        onClick={() => { navigate(-1) }}
        waves={false}
      >
        {translate('back')}
      </Button>
      {/* ------------(SAVE)------------ */}
      <Button
        style={{ width: '145px' }}
        disabled={buttonsDisabled}
        onClick={(e) => {
          e.preventDefault()
          setButtonsDisabled(true)
          handler({
            e,
            path,
            request: `${companyId}/${request}`,
            form,
            message: message,
            edit,
            onSuccess: () => navigate(-1),
            onFail: () => {
              setTimeout(() => {
                setButtonsDisabled(false)
              }, 100)
            },
            language
          })
        }}
      >
        {translate('save')}
      </Button>
      {/* ------------(FILES)------------ */}
      {edit && fileFolder && hasFilesRole &&
        <Button
          style={{
            width: '145px',
            backgroundColor: '#90a4ae',
          }}
          disabled={buttonsDisabled}
          onClick={(e) => {
            e.preventDefault()
            setModalContent(<UploadFiles fileFolder={fileFolder} subFolder={form.id} closeModal={closeModal} />)
            setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
            showModal()
          }}
        >
          {translate('files')}
        </Button>
      }
      {/* ------------(CHANGE HISTORY)------------ */}
      {edit && changeHistoryTableName && changeHistoryId && renderChangeDetails &&
        <Button
          style={{
            width: '145px',
            backgroundColor: '#9e9e9e',
          }}
          disabled={buttonsDisabled}
          onClick={(e) => {
            e.preventDefault()
            setModalContent(
              <EntryHistory
                tableName={changeHistoryTableName}
                entryId={changeHistoryId}
                renderChangeDetails={renderChangeDetails}
              />)
            setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
            showModal()
          }}
        >
          {translate('history')}
        </Button>
      }
      {/* ------------(DELETE)------------ */}
      {edit &&
        <Button
          style={{
            width: '145px',
            backgroundColor: '#E57373'
          }}
          disabled={buttonsDisabled}
          onClick={() => {
            setModalContent(
              <ConfirmationWindow
                form={form}
                label={label}
                path={path}
                request={`${companyId}/${request}`}
                closeModal={closeModal}
              />
            )
            showModal()
          }}
        >
          {translate('delete')}
        </Button>
      }
    </Wrapper>
  )
}