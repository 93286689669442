import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import DateInput from '../../../components/Inputs/DateInput'
import styled from 'styled-components'
import TextInput from '../../../components/Inputs/TextInput'
import Select from '../../../components/Inputs/Select'
import Button from '../../../components/Buttons/Button'
import { useTheme } from '../../../context/ThemeContext'

const Wrapper = styled.div`
  margin: 15px 0px 0px 5px;
`

const InputsWrapper = styled.div`
  display: flex;
  margin: 25px 0 10px 0;
  height: 50px;
  gap: 10px;
  align-items: center;
  width: 1025px;
`

const PeriodInputWrapper = styled.div`
  min-width: 150px;
  max-width: 150px;
`

const CarInputWrapper = styled.div`
  min-width: 250px;
  max-width: 250px;
`

const ResultInputWrapper = styled.div`
  min-width: 130px;
  max-width: 130px;
`

export const Filter = (props) => {
  const { routes, expenses, refueling, cars, filterQuery, setFilterQuery, profit, setRoutesTotal, setSalaryTotal, setFuelTotal, setExpensesTotal, pageName, findNameByKey } = props
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [startPeriod, setStartPeriod] = useState('')
  const [endPeriod, setEndPeriod] = useState('')

  const filterQueryHandler = useCallback((newFilterQuery) => {
    setFilterQuery(newFilterQuery)
    dispatch({ type: 'SET_FILTER_QUERY', page: pageName, payload: newFilterQuery })
  }, [dispatch, setFilterQuery, pageName])

  const [checkArr, setCheckArr] = useState({})
  const [carOptions, setCarOptions] = useState([])

  useEffect(() => {
    const startDate = filterQuery.startDate
    const endDate = filterQuery.endDate

    const check = (date) => {
      if (startDate && endDate) {
        return moment(date).isBetween(startDate, endDate, 'days', '[]')
      } else if (startDate && !endDate) {
        return moment(date).isSameOrAfter(startDate, 'day')
      } else if (!startDate && endDate) {
        return moment(date).isSameOrBefore(endDate, 'day')
      } else {
        return true
      }
    }

    let existingCars = []

    routes.forEach((item) => {
      if (check(item.dateOfDeparture) && item.car) {
        existingCars.push(item.car)
      }
    })

    refueling.forEach((item) => {
      if (check(item.date) && item.car) {
        existingCars.push(item.car)
      }
    })

    expenses.forEach((item) => {
      if (check(item.date) && item.bindingType === 'Автомобиль' && item.bind) {
        existingCars.push(item.bind)
      }
    })

    let arr = [
      {
        value: '',
        label: '-',
        defaultValue: true,
        disabled: false,
      },
    ]

    const uniqueCars = [...new Set(existingCars)]

    uniqueCars.forEach((item) => {
      const name = findNameByKey(cars, item)
      arr.push({ value: item, label: name })
    })

    if (arr.length) {
      setCarOptions(arr.sort((a, b) => a.label?.localeCompare(b.label)))
      if (filterQuery.car && !arr.some((car) => car.value === filterQuery.car)) {
      }
    } else {
      setCarOptions([])
    }
    setCheckArr(uniqueCars)
  }, [filterQuery.startDate, filterQuery.endDate, cars, expenses, filterQuery.car, findNameByKey, refueling, routes])


  useEffect(() => {
    let newStartPeriod = ''
    if (filterQuery.startDate) {
      const year = `${new Date(filterQuery.startDate).getFullYear()}`
      const month = `${new Date(filterQuery.startDate).getMonth() + 1}`
      newStartPeriod = `${year}-${month.length < 2 ? '0' + month : month}`
      setStartPeriod(newStartPeriod)
    } else {
      setStartPeriod('')
    }
    let newEndPeriod = ''
    if (filterQuery.endDate) {
      const year = `${new Date(filterQuery.endDate).getFullYear()}`
      const month = `${new Date(filterQuery.endDate).getMonth() + 1}`
      newEndPeriod = `${year}-${month.length < 2 ? '0' + month : month}`
      setEndPeriod(newEndPeriod)
    } else {
      setEndPeriod('')
    }
    if (filterQuery.car) {
      if (filterQuery.startDate || filterQuery.endDate) {
        if (checkArr.size) {
          if (checkArr.has(filterQuery.car)) {
          } else {
            filterQueryHandler({
              car: '',
              startDate: filterQuery.startDate,
              endDate: filterQuery.endDate,
            })
          }
        }
      }
    }
  }, [carOptions, checkArr, filterQuery.car, filterQuery.endDate, filterQuery.startDate, filterQueryHandler])

  return (
    <Wrapper>
      <InputsWrapper>
        {/* ------- START DATE ------- */}
        <PeriodInputWrapper>
          <DateInput
            label='Период'
            name='startDate'
            id='startDate'
            type='month'
            value={startPeriod}
            onChange={(e) => {
              const str = e.target.value
              const startDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)) - 1, 1) : ''
              filterQueryHandler({
                ...filterQuery, startDate
              })
            }}
          />
        </PeriodInputWrapper>
        {/* ------- END DATE ------- */}
        <PeriodInputWrapper>
          <DateInput
            label='Период'
            name='endDate'
            id='endDate'
            type='month'
            value={endPeriod}
            onChange={(e) => {
              const str = e.target.value
              const endDate = str ? new Date(str.slice(0, 4), parseFloat(str.slice(-2)), 0) : ''
              filterQueryHandler({
                ...filterQuery, endDate
              })
            }}
          />
        </PeriodInputWrapper>
        {/* ------- CAR ------- */}
        <CarInputWrapper>
          < Select
            label='Автомобиль'
            id='car'
            name='car'
            value={filterQuery.car}
            options={carOptions}
            searchable={true}
            onChange={(e) => {
              filterQueryHandler({
                ...filterQuery,
                car: e.target.value
              })
            }}
          />
        </CarInputWrapper>
        {/* ------- PROFIT ------- */}
        <ResultInputWrapper>
          <TextInput
            style={{ color: theme === 'light' ? 'red' : 'var(--red-lighten-color)' }}
            label='Прибыль'
            name='profit'
            id='profit'
            value={profit ? profit.toLocaleString() + ' р.' : '0'}
            color={profit < 0 ? 'red' : 'green'}
            readOnly={true}
          />
        </ResultInputWrapper>
        {/* ------- CLEAR BUTTON ------- */}
        {(filterQuery.startDate || filterQuery.endDate || filterQuery.car) &&
          <Button
            style={{ backgroundColor: 'var(--alert-button-bg)', minWidth: '120px' }}
            onClick={(e) => {
              e.preventDefault()
              setRoutesTotal(0)
              setFuelTotal(0)
              setExpensesTotal(0)
              setSalaryTotal(0)
              filterQueryHandler({
                startDate: '',
                endDate: '',
                car: '',
              })
            }}
          >
            Очистить
          </Button>}
      </InputsWrapper>
    </Wrapper>
  )
}
