import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ModalContext from '../../context/ModalContext'
import { useMessage } from '../../hooks/useMessage'
import { useSocketContext } from '../../context/SocketContext'
import useTranslate from '../../hooks/useTranslate'
import Button from '../Buttons/Button'
import useModalContentSize from '../../hooks/useModalContentSize'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  max-width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
`

const LabelWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 10px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 18px;
  color: var(--text-secondary-color);
`

const MessageWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  max-width: 1000px;
  border: 1px solid var(--line-color);
  position: relative;
  overflow: auto;
  flex: 1;
`

const Textarea = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  position: relative;
  font-size: 1rem;
  padding: 1rem;
  line-height: 1.5;
  transition: box-shadow .3s, border .3s;
  width: 100%;
  box-shadow: none;
  box-sizing: border-box;
  outline: none;
  background-color: transparent;
  color: var(--text-color);
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.width + 'px',
  },
}))`
  max-width: 1000px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
  flex-shrink: 0;
`

export default function AdminBroadcast() {
  const inputRef = useRef(null)
  const socket = useSocketContext()
  const message = useMessage()
  const translate = useTranslate()
  const { modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()
  const { closeModal } = useContext(ModalContext)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [text, setText] = useState('')

  useEffect(() => {
    adjustHeight()
  }, [text])

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'
      const scrollHeight = inputRef.current.scrollHeight
      inputRef.current.style.height = (scrollHeight + 2) + 'px'
    }
  }

  const sendMessage = () => {
    if (!text) return message(translate('enterMessage'), 'warn')
    setButtonsDisabled(true)
    socket.emit('admin_message', text)
    setText('')
    message(translate('sent'), 'success')
    setTimeout(() => {
      closeModal()
      setButtonsDisabled(false)
    }, 300)
  }

  return (
    <Wrapper $width={modalWrapperWidth} $height={modalWrapperHeight}>
      <LabelWrapper>
        {`${translate('message')}:`}
      </LabelWrapper>
      <MessageWrapper $width={modalContentWidth}>
        <Textarea
          ref={inputRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </MessageWrapper>
      <FooterWrapper width={modalContentWidth}>
        <Button
          style={{
            color: '#8e8e8e',
            backgroundColor: '#FFFFFF',
            border: 'solid #8e8e8e 1px',
            width: '130px',
          }}
          onClick={closeModal}
          waves={false}
          disabled={buttonsDisabled}
        >
          {translate('cancel')}
        </Button>
        <Button
          style={{ width: '130px' }}
          title={translate('send')}
          onClick={sendMessage}
          disabled={buttonsDisabled}
        >
          {translate('send')}
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}