import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Import Quill editor styles
import './style.css'
import styled from 'styled-components'
import Button from '../Buttons/Button'
import { useAuth } from '../../hooks/useAuth'
import { useMessage } from '../../hooks/useMessage'
import { useLanguage } from '../../context/LanguageContext'
import useTranslate from '../../hooks/useTranslate'
import { useFetching } from '../../hooks/useFetching'
import PostService from '../../API/PostService'
import useSingleAndDoubleClick from '../../hooks/useSingleAndDoubleClick'

const Wrapper = styled.div`
  min-height: ${({ height }) => height}px;
  max-height: ${({ height }) => height}px;
  overflow-y: auto;
`

const LabelWrapper = styled.div`
  padding: 5px;
`

const Label = styled.span`
  color: var(--main-color);
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
`

export default function InfoMessage() {
  const { roles } = useAuth()
  const message = useMessage()
  const { language } = useLanguage()
  const translate = useTranslate()
  const wrapperRef = useRef(null)
  const editorRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
  const [content, setContent] = useState('')
  const [canEdit, setCanEdit] = useState(false)
  const [editorHeight, setEditorHeight] = useState(0)

  const [fetchInfoMessage, , infoMessageError, infoMessageLoaded] = useFetching(
    async () => {
      const response = await PostService.get('data', 'info-message', {}, language)
      if (response && response.errors) {
        message(response.message, 'error')
        return
      }
      if (response.data && response.data.content) {
        setContent(response.data.content)
      }
      infoMessageError && console.log(infoMessageError)
    }
  )

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],         // outdent/indent

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                        // remove formatting button

      ['link', 'image', 'video'],                       // link and image, video
    ],
  }

  const toggleEditing = () => {
    if (canEdit) {
      setIsEditing(!isEditing)
    }
  }

  const saveContent = async (e) => {
    try {
      setSaveButtonDisabled(true)

      const strippedContent = content.replace(/<[^>]*>/g, '').trim()
      const finalContent = strippedContent ? content : ''

      const data = {
        content: finalContent,
      }

      const response = await PostService.put('data', 'info-message', data, language)
      if (response.status === 201) {
        console.log('Message sent successfully')
        message(response.data.message, 'success')
      } else {
        console.log('Error occurred while saving the message')
        message('Ошибка при сохранении сообщения', 'error')
      }
    } catch (error) {
      console.error('An error occurred:', error)
      message('Произошла ошибка', 'error')
    } finally {
      setSaveButtonDisabled(false)
    }
  }

  const clickHandler = useSingleAndDoubleClick(() => { }, () => {
    if (isEditing) {
      return
    }
    toggleEditing()
  })

  useEffect(() => {
    fetchInfoMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCanEdit(roles.some(role => (role.value === 'admin' || role.value === 'welcome')))
  }, [roles])

  const setHeight = () => {
    const windowHeight = window.innerHeight
    const buttonHeight = 50

    if (wrapperRef.current) {
      wrapperRef.current.style.height = `${windowHeight - 84}px`
    }

    if (editorRef.current) {
      const quillInstance = editorRef.current.getEditor()
      const toolbar = quillInstance.getModule('toolbar')
      if (toolbar && toolbar.container) {
        const toolbarHeight = toolbar.container.offsetHeight
        setEditorHeight(windowHeight - buttonHeight - toolbarHeight - 90)
      }
    }
  }

  useEffect(() => {
    setHeight()
    window.addEventListener('resize', setHeight)
    return () => {
      window.removeEventListener('resize', setHeight)
    }
  }, [])

  useEffect(() => {
    setHeight()
  }, [isEditing, editorRef])

  const handleChange = (value) => {
    setContent(value)
  }

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor()
      const toolbar = quill.getModule('toolbar')
      toolbar.addHandler('image', customImageHandler.bind(quill))
    }
  }, [isEditing])

  const customImageHandler = function () {
    const value = prompt('Enter the image URL')
    if (value) {
      this.insertEmbed(this.getSelection().index, 'image', value)
    }
  }
  return (
    <Wrapper ref={wrapperRef} onClick={clickHandler}>
      {isEditing ? (
        <>
          <ReactQuill
            ref={editorRef}
            value={content}
            onChange={handleChange}
            style={{ height: `${editorHeight}px` }}
            modules={modules}
          />
          <ButtonWrapper>
            <Button
              style={{
                color: 'var(--cancel-button-text)',
                backgroundColor: 'var(--cancel-button-bg)',
                border: 'var(--cancel-button-border)',
                width: '130px',
              }}
              onClick={() => {
                toggleEditing()
              }}
            >
              {translate('cancel')}
            </Button>
            <Button
              style={{ minWidth: '130px' }}
              onClick={(e) => {
                saveContent(e)
                toggleEditing()
              }}
              disabled={saveButtonDisabled}
            >
              {translate('save')}
            </Button>
          </ButtonWrapper>
        </>
      ) : content && content.replace(/<[^>]+>/g, '').trim() ? (
        <div className='ql-snow'>
          <div className='ql-editor' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      ) : (
        canEdit && infoMessageLoaded && (
          <LabelWrapper>
            <Label>
              {translate('clickTwiceToAddText')}
            </Label>
          </LabelWrapper>
        )
      )}
    </Wrapper>
  )
}