import React, { useCallback } from 'react'
import styled from 'styled-components'

const SwitchWrapper = styled.label`
  display: grid;
  grid-template-columns: 45px auto;
  align-items: center;
  justify-items: start;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;
`

const SwitchInput = styled.input`
  position: absolute;
  opacity: 0;
`

const SwitchToggle = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 15px;
  background-color: ${({ $isChecked, disabled }) => (disabled ? 'rgba(0,0,0,0.38)' : $isChecked ? 'var(--input-focused-color)' : 'rgba(0,0,0,0.38)')};
  position: relative;
  margin: 0px 16px 0px 0px;
  transition: background 0.3s ease;

  &:before {
    content: "";
    position: absolute;
    top: -3px;
    left: ${({ $isChecked }) => ($isChecked ? '16px' : '0')};
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f1f1f1;
    html[data-theme='dark'] & {
      background-color: #f1f1f1;
    }
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: left 0.3s ease;
  }
`

const SwitchLabel = styled.span`
  color: ${({ disabled }) => (disabled ? '#bdbdbd' : 'var(--text-secondary-color)')};
  font-size: 16px;
  line-height: 24px;
`

export default function Switch(props) {
  const { label, id, name, checked, onChange, disabled } = props

  const handleChange = useCallback((event) => {
    if (typeof onChange === 'function') {
      onChange(event)
    }
  }, [onChange])

  return (
    <SwitchWrapper htmlFor={id} disabled={disabled}>
      <SwitchInput
        type='checkbox'
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <SwitchToggle $isChecked={checked} disabled={disabled} />
      <SwitchLabel disabled={disabled}>{label}</SwitchLabel>
    </SwitchWrapper>
  )
}
