import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useDebounce from '../../hooks/useDebounce'

const NumberInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Label = styled.label`
  position: absolute;
  top: -15px;
  line-height: 1rem;
  font-size: 0.8rem;
  width: 100%;
  color: ${({ $isFocused, disabled }) =>
    disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-focused-color)' : 'var(--input-label-color)'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: initial;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};
  transform-origin: 0% 100%;
  transition: color 0.2s;

  ${({ $forceDarkMode }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        color: ${({ $isFocused, disabled }) =>
        disabled ? 'var(--text-color-disabled)' : $isFocused ? 'var(--input-element-focused-color)' : 'var(--text-inverted-color)'};
      }
    `}
`

const Input = styled.input`
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  height: 2.9rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px;
  padding: 0;
  transition: box-shadow .3s, border .3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $isFocused
      ? css`
          border-bottom: ${$borderHeight} solid var(--input-focused-color);
          ${$focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-focused-color);`}
      `
      : css`
          border-bottom: ${$borderHeight} solid var(--input-element-color);
          box-shadow: none;
      `}

  /* Override styles when $forceDarkMode is true and light theme is active */
  ${({ $forceDarkMode, $isFocused, $borderHeight, $focusedBoxShadow }) =>
    $forceDarkMode &&
    css`
      html[data-theme='light'] & {
        ${$isFocused ? `border-bottom: ${$borderHeight} solid var(--input-element-focused-color);` : `border-bottom: ${$borderHeight} solid var(--input-element-color-variant);`}
        ${$isFocused && $focusedBoxShadow && `box-shadow: 0 1px 0 0 var(--input-element-focused-color);`}
      }
    `}

    ${({ disabled, $forceDarkMode }) =>
    disabled
      ? css`
          color: var(--text-color-disabled);
          cursor: default;
          border-bottom: 1px dotted var(--text-color-disabled);
        `
      : $forceDarkMode
        ? css`
          color: #FFFFFF;
          html[data-theme='light'] & {
            color: var(--text-inverted-color) !important;
          }
        `
        : css`
          html[data-theme='dark'] & {
            color: #FFFFFF;
          }
        `
  }
  ${({ readOnly, disabled }) =>
    readOnly && !disabled &&
    css`
    background-color: var(--input-read-only-bg);
  `}
`

const dotAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const LoadingIndicator = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-right: 2px;
  color: hsl(0, 0%, 80%);
  font-size: 4px;
  line-height: 1;
  text-align: center;
  transition: color 150ms;
  vertical-align: middle;

  span {
    animation: ${dotAnimation} 1s ease-in-out infinite;
    background-color: currentColor;
    border-radius: 1em;
    display: inline-block;
    height: 1em;
    margin-left: 1em;
    vertical-align: top;
    width: 1em;
  }

  span:nth-child(1) {
    animation-delay: 0ms;
  }

  span:nth-child(2) {
    animation-delay: 160ms;
  }

  span:nth-child(3) {
    animation-delay: 320ms;
  }
}`

export default function NumberInput(props) {
  const { label = '', value = '', name, id, placeholder = '-', forceDarkMode = false, borderOption = 1, onChange, onClick, onKeyDown, readOnly, disabled, debounceDelay, ...rest } = props
  const [isFocused, setIsFocused] = useState(false)
  const [debouncedHandleChange, isDebouncing] = useDebounce(onChange, debounceDelay)
  const [borderHeight, setBorderHeight] = useState('1px')
  const [focusedBoxShadow, setFocusedBoxShadow] = useState('1px')
  const [inputValue, setInputValue] = useState(value)
  const inputRef = useRef(null)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    let newBorderHeight = '1px'
    let newFocusedBoxShadow = '1px'
    switch (borderOption) {
      case 2:
        newBorderHeight = '2px'
        newFocusedBoxShadow = false
        break
      case 3:
        newBorderHeight = '1px'
        newFocusedBoxShadow = false
        break
      default:
        break
    }

    setBorderHeight(newBorderHeight)
    setFocusedBoxShadow(newFocusedBoxShadow)
  }, [borderOption])

  const handleClick = useCallback((e) => {
    if (onClick) {
      onClick(e)
    }
  }, [onClick])

  const handleBlur = useCallback((e) => {
    setIsFocused(false)
    if (rest.onBlur) {
      rest.onBlur(e)
    }
  }, [rest])

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value)
    if (typeof onChange === 'function') {
      if (debounceDelay) {
        debouncedHandleChange(e)
      } else {
        onChange(e)
      }
    }
  }, [onChange, debouncedHandleChange, debounceDelay])

  const handleFocus = useCallback((e) => {
    setIsFocused(true)
    if (inputRef.current && !readOnly && !disabled && inputRef.current.value === '0') {
      inputRef.current.select()
    }
    if (rest.onFocus) {
      rest.onFocus(e)
    }
  }, [rest, readOnly, disabled, inputRef])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Delete' && !readOnly && inputRef.current.value) {
      setInputValue('')
      if (typeof onChange === 'function') {
        const fakeEvent = {
          target: {
            name,
            value: null,
            type: 'number',
          },
        }
        onChange(fakeEvent)
      }
    }
  }, [readOnly, onChange, name])

  return (
    <NumberInputWrapper>
      <Label
        disabled={disabled}
        title={label}
        $isFocused={isFocused}
        $forceDarkMode={forceDarkMode}
      >
        {label}
      </Label>
      {isDebouncing &&
        <LoadingIndicator>
          <span></span>
          <span></span>
          <span></span>
        </LoadingIndicator>}
      <Input
        ref={inputRef}
        id={id}
        name={name}
        placeholder={isFocused && !disabled && !readOnly ? '' : placeholder}
        type='number'
        value={inputValue}
        readOnly={readOnly}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        $forceDarkMode={forceDarkMode}
        $borderHeight={borderHeight}
        $focusedBoxShadow={focusedBoxShadow}
        $isFocused={isFocused}
      />
    </NumberInputWrapper>
  )
}

