import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import ModalContext from '../../../context/ModalContext'
import Button from '../../../components/Buttons/Button'
import useModalContentSize from '../../../hooks/useModalContentSize'
import TableComponent from '../../../components/Tables/TableComponent'

const Wrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
    maxHeight: props.$height + 'px',
  },
}))`
${(props) =>
    props.$isLoading &&
    css`
  height: ${props => props.$height + 'px'};
`}
  position: relative;
  display: flex;
  flex-direction: column;
`

const FooterWrapper = styled.div.attrs(props => ({
  style: {
    width: props.$width + 'px',
  },
}))`
  display: flex;
  margin: 15px 0px 0px 0px;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
`

export const StockBalance = ({ tableData }) => {
  const { closeModal } = useContext(ModalContext)
  const { isSmallScreen, modalWrapperWidth, modalWrapperHeight, modalContentWidth } = useModalContentSize()

  //=== PREPARE TABLE DATA ===//
  const [table, setTable] = useState({})
  const [tableReady, setTableReady] = useState(false)

  useEffect(() => {
    let obj = {}
    tableData.forEach((item) => {
      if (Object.keys(obj).includes(item.inventory)) {
        if (item.direction === 'incoming') {
          obj[item.inventory] = { amount: obj[item.inventory].amount + item.amount }
        } else {
          obj[item.inventory] = { amount: obj[item.inventory].amount - item.amount }
        }
      } else {
        if (item.direction === 'incoming') {
          obj[item.inventory] = { amount: item.amount }
        } else {
          obj[item.inventory] = { amount: 0 - item.amount }
        }
      }
    })

    let sortedTable = Object.keys(obj).map((inventory) => ({ inventory, ...obj[inventory] }))
    sortedTable.sort((a, b) => a.inventory.localeCompare(b.inventory))

    setTable(sortedTable)
    setTableReady(true)
  }, [tableData])

  return (
    <Wrapper $isLoading={!tableReady} $height={modalWrapperHeight} $width={modalWrapperWidth}>
      <TableComponent columnWidths={['10%', '70%', '20%']} tableWidth={modalContentWidth} isLoading={!tableReady}>
        {/* -----HEADERS----- */}
        <thead>
          <tr>
            <th key={'№'}>№</th>
            <th key={'Наименование'}>Наименование</th>
            <th key={'Остаток'}>Остаток</th>
          </tr>
        </thead>
        <tbody>
          {/* -----ROWS----- */}
          {!table || Object.keys(table).length === 0 ? (
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          ) : (
            table.map((item, i) => (
              <tr key={item.inventory}>
                <td>{(i + 1).toLocaleString()}</td>
                <td>{item.inventory}</td>
                <td>{item.amount.toLocaleString()}</td>
              </tr>
            ))
          )}
        </tbody>
      </TableComponent>
      {/* ------------(CLOSE BUTTON)------------ */}
      <FooterWrapper $isSmallScreen={isSmallScreen} $width={modalContentWidth}>
        <Button
          style={{ minWidth: '130px' }}
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
          waves={true}
        >
          Закрыть
        </Button>
      </FooterWrapper>
    </Wrapper>
  )
}